import { Alert, Avatar, Button, Row, Space, Steps, Switch, Table, Tag, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import relativeTime from 'dayjs/plugin/relativeTime';
import YAML from 'json-to-pretty-yaml';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';
import { useAuth } from 'react-oidc-context';

import CUSTOMER_LOGOS from '../../customer-logos.json';

import { ChevronDown, ChevronRight, CircleCheck, CircleX, Hourglass } from 'lucide-react';
import DataService from '../../data.service';
import {
	FacebookIcon,
	FemaleOwnedIcon,
	HomeBasedIcon,
	InstagramIcon,
	LinkedinIcon,
	LocationPinIcon,
	MinorityOwnedIcon,
	PhoneIcon,
	TwitterIcon,
	UrlIcon,
	YearsIcon,
	YoutubeIcon,
} from '../Icons/Lib';

const { Title, Paragraph } = Typography;

dayjs.extend(relativeTime);
dayjs.extend(calendar);

const STAGES = [
	{ title: 'Review', description: 'by customer', color: 'orange' },
	{ title: 'Review', description: 'by SizeUp', color: 'blue' },
	{ title: 'Confirmation', description: 'by Customer', color: 'purple' },
	{ title: 'Finished', description: '', color: 'green' },
];

interface BusinessModel {
	id: number;
	name: string;
	lat: number;
	lng: number;
	street: string;
	city: string;
	state: string;
	zip: string;
	phone: string;
	url: string;
	urlFacebook: string;
	urlInstagram: string;
	urlLinkedin: string;
	urlTwitter: string;
	urlYoutube: string;
	isFirm: boolean;
	isHomeBased: boolean;
	isOwnedByEthnicMinority: boolean;
	ethnicMinorityOwner: string | null;
	isOwnedByFemale: boolean;
	isOwnedByForeign: boolean;
	isPublic: boolean;
	yearStarted: number;
	industrySEOKey: string;
	seoKey: string;
	distanceToLocation: number | null;
	address: string;
	phoneFormatted: string;
	yearsInBusiness: number;
}

interface RequestModel {
	id: string;
	lat: string;
	lng: string;
	email: string;
	companyName: string;
	physicalAddress: string;
	physicalAddressStreet?: string;
	physicalAddressCity: string;
	physicalAddressState: string;
	physicalAddressZip: string;
	phone: string;
	contactEmail: string;
	websiteUrl: string;
	facebookUrl: string;
	instagramUrl: string;
	linkedInUrl: string;
	twitterUrl: string;
	youTubeUrl: string;
	otherInfo: string;
	requestType: string;
	industrySEOKey: string;
	seoKey: string;
	yearStarted?: string;
	isFirm?: boolean;
	isHomeBased?: boolean;
	isOwnedByEthnicMinority?: boolean;
	ethnicMinorityOwner?: string;
	isOwnedByFemale?: boolean;
	isOwnedByForeign?: boolean;
	isPublic?: boolean;
	distanceToLocation?: number | null;
	address?: string;
	phoneFormatted?: string;
	yearsInBusiness?: string;
}

const expandIcon = ({ expanded, onExpand, record }) =>
	expanded ? (
		<ChevronDown size={16} onClick={(e) => onExpand(record, e)} />
	) : (
		<ChevronRight size={16} onClick={(e) => onExpand(record, e)} />
	);

function oneLineDiff(oldValue, newValue) {
	if (oldValue === newValue) {
		return oldValue;
	}

	return (
		<React.Fragment>
			{oldValue ? <del>{oldValue}</del> : null}
			{oldValue && newValue ? <br /> : null}
			{newValue ? <ins>{newValue}</ins> : null}
		</React.Fragment>
	);
}

function normalizeModel(model: RequestModel): BusinessModel {
	return {
		id: model.id ? Number(model.id) : 0,
		name: model.companyName,
		lat: model.lat ? Number.parseFloat(model.lat) : 0,
		lng: model.lng ? Number.parseFloat(model.lng) : 0,
		street: model.physicalAddressStreet ? model.physicalAddressStreet : '',
		city: model.physicalAddressCity,
		state: model.physicalAddressState,
		zip: model.physicalAddressZip,
		phone: model.phone,
		url: model.websiteUrl,
		urlFacebook: model.facebookUrl,
		urlInstagram: model.instagramUrl,
		urlLinkedin: model.linkedInUrl,
		urlTwitter: model.twitterUrl,
		urlYoutube: model.youTubeUrl,
		isFirm: model.isFirm ? model.isFirm : false,
		isHomeBased: model.isHomeBased ? model.isHomeBased : false,
		isOwnedByEthnicMinority: model.isOwnedByEthnicMinority ? model.isOwnedByEthnicMinority : false,
		ethnicMinorityOwner: model.ethnicMinorityOwner ? model.ethnicMinorityOwner : null,
		isOwnedByFemale: model.isOwnedByFemale ? model.isOwnedByFemale : false,
		isOwnedByForeign: model.isOwnedByForeign ? model.isOwnedByForeign : false,
		isPublic: model.isPublic ? model.isPublic : false,
		yearStarted: model.yearStarted ? Number(model.yearStarted) : 0,
		industrySEOKey: model.industrySEOKey ? model.industrySEOKey : '',
		seoKey: model.seoKey,
		distanceToLocation: model.distanceToLocation ? model.distanceToLocation : null,
		address: model.physicalAddress ? model.physicalAddress : '',
		phoneFormatted: model.phoneFormatted ? model.phoneFormatted : '',
		yearsInBusiness: model.yearsInBusiness ? Number(model.yearsInBusiness) : 0,
	};
}

function BusinessCard({ oldValue, newValue, extra = null }: { oldValue: any; newValue: any; extra?: React.ReactNode }) {
	return (
		<div className="business-card">
			{newValue.id ? <div className="business-card__id">#{newValue.id}</div> : null}
			<div>{extra}</div>
			<div className="business-card__header">
				<div className="business-card__title">{oneLineDiff(oldValue?.name, newValue.name)}</div>
				<pre>{oneLineDiff(oldValue?.seoKey, newValue.seoKey)}</pre>
			</div>
			<div className="business-card__body">
				<div className="business-card__row">
					<LocationPinIcon className="business-card__icon" />
					<pre style={{ textAlign: 'left' }}>
						{oneLineDiff(oldValue?.address, newValue.address)}
						<br />
						Street: {oneLineDiff(oldValue?.street, newValue.street)}
						<br />
						City: {oneLineDiff(oldValue?.city, newValue.city)}
						<br />
						State: {oneLineDiff(oldValue?.state, newValue.state)}
						<br />
						Zip: {oneLineDiff(oldValue?.zip, newValue.zip)}
						<br />
						GPS:{' '}
						{oneLineDiff(
							oldValue?.lat && oldValue?.lng ? `${oldValue?.lat}, ${oldValue?.lng}` : '',
							newValue?.lat && newValue?.lng ? `${newValue.lat}, ${newValue.lng}` : '',
						)}
					</pre>
				</div>
				<div className="business-card__row">
					<PhoneIcon className="business-card__icon" />
					{oneLineDiff(oldValue?.phone, newValue.phone)}(
					{oneLineDiff(oldValue?.phoneFormatted, newValue.phoneFormatted)})
				</div>
				{oldValue?.url || newValue.url ? (
					<div className="business-card__row">
						<UrlIcon className="business-card__icon" />
						{oneLineDiff(oldValue?.url, newValue.url)}
					</div>
				) : null}
				{oldValue?.yearsInBusiness || newValue.yearsInBusiness ? (
					<div className="business-card__row">
						<YearsIcon className="business-card__icon" />
						{oneLineDiff(oldValue?.yearsInBusiness, newValue.yearsInBusiness)} years in business ( from{' '}
						{oneLineDiff(oldValue?.yearStarted, newValue.yearStarted)})
					</div>
				) : null}

				{oldValue?.isHomeBased || newValue.isHomeBased ? (
					<div className="business-card__row">
						<HomeBasedIcon className="business-card__icon" />{' '}
						{oneLineDiff(
							oldValue?.isHomeBased ? 'Home Based' : '',
							newValue.isHomeBased ? 'Home Based' : '',
						)}
					</div>
				) : null}

				{oldValue?.isOwnedByFemale || newValue.isOwnedByFemale ? (
					<div className="business-card__row">
						<FemaleOwnedIcon className="business-card__icon" />{' '}
						{oneLineDiff(
							oldValue?.isOwnedByFemale ? 'Female Owned' : '',
							newValue.isOwnedByFemale ? 'Female Owned' : '',
						)}
					</div>
				) : null}

				{oldValue?.isOwnedByEthnicMinority || newValue.isOwnedByEthnicMinority ? (
					<div className="business-card__row">
						<MinorityOwnedIcon className="business-card__icon" />{' '}
						{oneLineDiff(
							oldValue?.isOwnedByEthnicMinority ? 'Minotiry Owned' : '',
							newValue.isOwnedByEthnicMinority ? 'Minotiry Owned' : '',
						)}
						{oneLineDiff(
							oldValue?.ethnicMinorityOwned ? 'Ethnic Minority' : '',
							newValue.ethnicMinorityOwned ? 'Ethnic Minority' : '',
						)}
					</div>
				) : null}

				<div className="business-card__social">
					{newValue.urlFacebook ? (
						<a
							className="business-card__socialitem"
							target="_blank"
							href={`https://${newValue.urlFacebook}`}
							rel="noreferrer"
							aria-label="Facebook"
						>
							<FacebookIcon className="business-card__socialicon" />
						</a>
					) : null}
					{newValue.urlTwitter ? (
						<a
							className="business-card__socialitem"
							target="_blank"
							href={`https://${newValue.urlTwitter}`}
							rel="noreferrer"
							aria-label="Twitter"
						>
							<TwitterIcon className="business-card__socialicon" />
						</a>
					) : null}
					{newValue.urlInstagram ? (
						<a
							className="business-card__socialitem"
							target="_blank"
							href={`https://${newValue.urlInstagram}`}
							rel="noreferrer"
							aria-label="Instagram"
						>
							<InstagramIcon className="business-card__socialicon" />
						</a>
					) : null}
					{newValue.urlLinkedin ? (
						<a
							className="business-card__socialitem"
							target="_blank"
							href={`https://${newValue.urlLinkedin}`}
							rel="noreferrer"
							aria-label="Linkedin"
						>
							<LinkedinIcon className="business-card__socialicon" />
						</a>
					) : null}
					{newValue.urlYoutube ? (
						<a
							className="business-card__socialitem"
							target="_blank"
							href={`https://${newValue.urlYoutube}`}
							rel="noreferrer"
							aria-label="Youtube"
						>
							<YoutubeIcon className="business-card__socialicon" />
						</a>
					) : null}
				</div>

				{newValue.urlFacebook && oldValue?.urlFacebook !== newValue.urlFacebook ? (
					<div className="business-card__row">
						<FacebookIcon className="business-card__icon" />
						{oneLineDiff(oldValue?.urlFacebook, newValue.urlFacebook)}
					</div>
				) : null}

				{newValue.urlTwitter && oldValue?.urlTwitter !== newValue.urlTwitter ? (
					<div className="business-card__row">
						<TwitterIcon className="business-card__icon" />
						{oneLineDiff(oldValue?.urlTwitter, newValue.urlTwitter)}
					</div>
				) : null}

				{newValue.urlInstagram && oldValue?.urlInstagram !== newValue.urlInstagram ? (
					<div className="business-card__row">
						<InstagramIcon className="business-card__icon" />
						{oneLineDiff(oldValue?.urlInstagram, newValue.urlInstagram)}
					</div>
				) : null}

				{newValue.urlLinkedin && oldValue?.urlLinkedin !== newValue.urlLinkedin ? (
					<div className="business-card__row">
						<LinkedinIcon className="business-card__icon" />
						{oneLineDiff(oldValue?.urlLinkedin, newValue.urlLinkedin)}
					</div>
				) : null}

				{newValue.urlYoutube && oldValue?.urlYoutube !== newValue.urlYoutube ? (
					<div className="business-card__row">
						<YoutubeIcon className="business-card__icon" />
						{oneLineDiff(oldValue?.urlYoutube, newValue.urlYoutube)}
					</div>
				) : null}
			</div>
		</div>
	);
}

function RequestDiff({ request }) {
	const auth = useAuth();
	const [oldModel, setOldModel] = useState<BusinessModel | null>(null);
	const [newModel, setNewModel] = useState<BusinessModel | null>(null);
	const [oldModelYaml, setOldModelYaml] = useState('');
	const [newModelYaml, setNewModelYaml] = useState('');
	const [loading, setLoading] = useState(false);
	const [displayMode, setDisplayMode] = useState('ui');
	const requestType = useMemo(() => {
		const parsedModel = request.jsonModel ? JSON.parse(request.jsonModel) : null;
		if (parsedModel) {
			return parsedModel.requestType;
		}
		return null;
	}, [request.jsonModel]);

	useEffect(() => {
		async function getDetails() {
			setLoading(true);
			const data = await DataService.businessRequests.getDetails({
				token: auth.user?.access_token,
				requestId: request.id,
			});

			setLoading(false);

			const jsonModel = data.jsonModel ? JSON.parse(data.jsonModel) : null;

			const normalizedModel = jsonModel ? normalizeModel(jsonModel) : null;
			const originalModel = data.originalModel ? JSON.parse(data.originalModel) : null;

			const jsonModelOrdered = normalizedModel
				? Object.fromEntries(Object.entries(normalizedModel).sort(([keyA], [keyB]) => keyA.localeCompare(keyB)))
				: null;

			const originalModelOrdered = originalModel
				? Object.fromEntries(Object.entries(originalModel).sort(([keyA], [keyB]) => keyA.localeCompare(keyB)))
				: null;

			setOldModel(originalModel);
			setNewModel(normalizedModel);
			setOldModelYaml(YAML.stringify(originalModelOrdered));
			setNewModelYaml(YAML.stringify(jsonModelOrdered));
		}
		getDetails();
	}, [auth, request.id]);

	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<React.Fragment>
			<Steps
				direction="horizontal"
				size="small"
				current={request.reviewStageId - 1}
				// labelPlacement="vertical"
				items={STAGES}
			/>

			{requestType === 'closed' ? (
				<React.Fragment>
					<Alert message="The business has been closed" type="info" showIcon style={{ margin: '1rem 0' }} />
					<BusinessCard oldValue={oldModel} newValue={{}} />
				</React.Fragment>
			) : newModel ? (
				<Row>
					<div style={{ marginTop: '1rem', textAlign: 'right' }}>
						<div style={{ display: 'block', justifyContent: 'flex-between' }}>
							<div
								style={{
									marginBottom: '8px',
								}}
							>
								{displayMode === 'ui' ? (
									<BusinessCard
										oldValue={oldModel}
										newValue={newModel}
										extra={
											<Switch
												checkedChildren="UI"
												unCheckedChildren="RAW"
												defaultChecked={displayMode === 'ui'}
												onChange={(checked) => {
													setDisplayMode(checked ? 'ui' : 'raw');
												}}
											/>
										}
									/>
								) : (
									<pre style={{ margin: 0 }}>
										<ReactDiffViewer
											oldValue={oldModelYaml}
											newValue={newModelYaml}
											leftTitle={
												<Switch
													checkedChildren="UI"
													unCheckedChildren="RAW"
													defaultChecked={displayMode === 'ui'}
													onChange={(checked) => {
														setDisplayMode(checked ? 'ui' : 'raw');
													}}
												/>
											}
											showDiffOnly={false}
											rightTitle="NEW"
											compareMethod={DiffMethod.WORDS}
										/>
									</pre>
								)}
							</div>
						</div>
					</div>
				</Row>
			) : null}
		</React.Fragment>
	);
}

interface BusinessRequestsTableProps {
	data?: BusinessRequest[];
	loading: boolean;
}

export default function BusinessRequestsTable({ data, loading }: BusinessRequestsTableProps) {
	const auth = useAuth();

	const userRole = useMemo(() => {
		if (auth.user?.profile?.role === 'sizeup_admin') {
			return 'admin';
		}
		if (auth.user?.profile?.role === 'customer_admin') {
			return 'customer';
		}
		return 'user';
	}, [auth.user?.profile?.role]);

	const acceptRequest = useCallback(
		(requestId) => {
			DataService.businessRequests
				.acceptRequest({
					requestId,
					token: auth.user?.access_token,
				})
				.then(() => {
					window.location.reload();
				});
		},
		[auth],
	);
	const rejectRequest = useCallback(
		(requestId) => {
			DataService.businessRequests
				.rejectRequest({
					requestId,
					token: auth.user?.access_token,
				})
				.then(() => window.location.reload());
		},
		[auth],
	);

	const requestDiffComponent = useCallback((record) => <RequestDiff request={record} />, []);

	const columns = useMemo(
		() => [
			{
				title: 'CUSTOMER',
				dataIndex: 'apiKey',
				key: 'apiKey',
				render: (text, record) => (
					<Space direction="horizontal" align="center">
						{record?.apiKey && record.customerName ? (
							<Avatar
								style={{ verticalAlign: 'middle' }}
								shape="square"
								size={40}
								src={
									CUSTOMER_LOGOS.includes(record.apiKey ?? 'n/a')
										? `/logos/${record.apiKey}.png`
										: `https://ui-avatars.com/api/?name=${encodeURIComponent(record.customerName)}&background=${
												/test|demo/i.test(record.customerName) ? 'ff9e16' : '1677ff'
											}&color=fff&size=80`
								}
							/>
						) : null}
						<div>
							<Title level={5} style={{ margin: 0 }}>
								{record.customerName}
							</Title>
							<Paragraph style={{ fontSize: '90%', marginBottom: '0' }} type="secondary" ellipsis>
								{record.apiKey}
							</Paragraph>
						</div>
					</Space>
				),
			},
			{
				title: 'SUBMITTER',
				dataIndex: 'submitterUserId',
				key: 'submitterUserId',
				render: (text, record) => (
					<Space direction="horizontal" align="center">
						<Title level={5} style={{ margin: 0 }}>
							{record.submitterName}
						</Title>
						<Paragraph style={{ fontSize: '90%', marginBottom: '0' }} type="secondary" ellipsis title="aaa">
							{record.submitterEmail}
							<Tooltip title={record.submitterUserId}>
								<span style={{ color: '#8c8c8c' }}>
									{record.submitterUserId ? `#${record.submitterUserId.slice(0, 8)}` : ''}
								</span>
							</Tooltip>
						</Paragraph>
					</Space>
				),
			},
			// {
			// 	title: 'EMAIL',
			// 	dataIndex: 'email',
			// 	key: 'email',
			// },
			// {
			// 	title: 'PRODUCT',
			// 	dataIndex: 'product',
			// 	key: 'product',
			// },
			{
				title: 'DATE, TIME',
				dataIndex: 'dateCreated',
				key: 'dateCreated',
				render: (text, record) => (
					<React.Fragment>
						{dayjs(record.dateCreated).calendar()}
						{record.userId && (
							<div style={{ color: '#8c8c8c', fontSize: '.8rem' }}>by user #{record.userId}</div>
						)}
						{record.dateCreated !== record.dateUpdated ? (
							<div
								style={{
									color: '#8c8c8c',
								}}
							>
								updated {dayjs(record.dateUpdated).fromNow(false)}
								<div style={{ fontSize: '.8rem' }}>
									{/* by {record.lastChangedby} */}
									{record.lastChangedby ? `by ${record.lastChangedby}` : ''}
								</div>
							</div>
						) : null}
					</React.Fragment>
				),
			},
			{
				title: 'STAGE',
				dataIndex: 'reviewStageDisplayName',
				key: 'reviewStageDisplayName',
				render: (text, record) => {
					const stage = STAGES[record.reviewStageId - 1];
					const { nextReviewStagePropagationDate } = record;
					const timeLeft = nextReviewStagePropagationDate
						? dayjs(nextReviewStagePropagationDate).fromNow()
						: '';
					return (
						<React.Fragment>
							<Tag color={stage.color} key={record.reviewStageId}>
								{stage.title}
								{stage.description && ` ${stage.description}`}
							</Tag>
							{timeLeft ? (
								<div style={{ color: '#8c8c8c', fontSize: '.8rem' }}>
									The next stage will be automatically propagated {timeLeft}
								</div>
							) : null}
						</React.Fragment>
					);
				},
			},
			{
				title: 'ACTIONS',
				key: 'action',
				render: (text, record) =>
					(userRole === 'admin' && record.reviewStageId === 2) ||
					(userRole === 'customer' && (record.reviewStageId === 1 || record.reviewStageId === 3)) ? (
						<Space direction="vertical">
							<Button
								type="text"
								onClick={() => acceptRequest(record.id)}
								icon={<CircleCheck size={16} />}
								style={{ color: '#3875f6' }}
							>
								ACCEPT
							</Button>
							<Button
								type="text"
								onClick={() => rejectRequest(record.id)}
								icon={<CircleX size={16} />}
								style={{ color: '#ec5e58' }}
							>
								REJECT
							</Button>
						</Space>
					) : record.reviewStageId < 4 ? (
						/* loader with waiting text */
						<Space direction="vertical">
							<Button type="text" disabled icon={<Hourglass size={16} />}>
								WAITING
							</Button>
						</Space>
					) : (
						<Space direction="vertical">
							{record.isAccepted ? (
								<Button
									type="text"
									disabled
									icon={<CircleCheck size={16} />}
									style={{
										color: '#5dbe9a',
									}}
								>
									ACCEPTED
								</Button>
							) : (
								<Button
									type="text"
									disabled
									icon={<CircleX size={16} />}
									style={{
										color: '#999999',
									}}
								>
									REJECTED
								</Button>
							)}
						</Space>
					),
			},
		],
		[acceptRequest, rejectRequest, userRole],
	);

	return (
		<div>
			<Table
				columns={columns}
				dataSource={data}
				loading={loading}
				rowKey="id"
				pagination={{
					pageSize: 50,
					showSizeChanger: false,
					hideOnSinglePage: true,
				}}
				expandable={{
					expandIcon,
					expandedRowRender: requestDiffComponent,
					rowExpandable: (record) => !!record.jsonModel,
					onExpand: (expanded, record) => {
						if (expanded) {
							console.log(JSON.parse(record.jsonModel));
						}
					},
				}}
			/>
		</div>
	);
}

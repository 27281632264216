interface Region {
	granularityId: number;
	name: string;
	seoKey: string;
}

const GRANULARITY = {
	2: 'city',
	3: 'county',
	6: 'state',
};

async function get({ areaname, granularity, token }): Promise<Region[]> {
	const placeType = GRANULARITY[granularity];

	if (!placeType) {
		throw new Error('Invalid granularity');
	}

	const params = new URLSearchParams({ term: areaname });
	const url = `https://api2.sizeup.com/api/servicearealookup/${placeType}/search/?${params.toString()}`;

	try {
		const response = await fetch(url, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		const data = await response.json();
		return data.map((item) => ({
			granularityId: granularity,
			name: item.longName,
			seoKey: item.seoKey,
		}));
	} catch (error) {
		console.error('Regions get error', error);
		throw error;
	}
}

const regionsService = {
	get,
};

export default regionsService;

import type React from 'react';

export function CorporationIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" {...props}>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
				d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
			/>
		</svg>
	);
}

export function FacebookIconColor(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" aria-label="Facebook" role="img" viewBox="0 0 512 512" {...props}>
			<rect width="512" height="512" rx="15%" fill="#1877f2" />
			<path
				d="M355.6 330l11.4-74h-71v-48c0-20.2 9.9-40 41.7-40H370v-63s-29.3-5-57.3-5c-58.5 0-96.7 35.4-96.7 99.6V256h-65v74h65v182h80V330h59.6z"
				fill="#fff"
			/>
		</svg>
	);
}
export function FacebookIconFlat(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
			<path fill="none" d="M0 0h24v24H0z" />
			<path d="M15.402 21v-6.966h2.333l.349-2.708h-2.682V9.598c0-.784.218-1.319 1.342-1.319h1.434V5.857a19.19 19.19 0 0 0-2.09-.107c-2.067 0-3.482 1.262-3.482 3.58v1.996h-2.338v2.708h2.338V21H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4.598z" />
		</svg>
	);
}

export function FacebookIcon(props: React.SVGProps<SVGSVGElement>) {
	return <FacebookIconFlat {...props} />;
}

export function FemaleOwnedIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" {...props}>
			<path d="M402 146a146 146 0 10-175 143v103h-62v58h62v62h58v-62h62v-58h-62V289c67-13 117-72 117-143zm-237 0a91 91 0 11182 0 91 91 0 01-182 0z" />
		</svg>
	);
}

export function FolderIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
			strokeWidth={1.5}
			stroke="currentColor"
			{...props}
		>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
			/>
		</svg>
	);
}

export function HomeIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
			<path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
			<path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
		</svg>
	);
}

export function HomeBasedIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
			<path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
		</svg>
	);
}

export function IndustriesIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" {...props}>
			<path fill="none" d="M0 0h24v24H0z" />
			<path d="M21 19h2v2H1v-2h2V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v15h4v-8h-2V9h3a1 1 0 0 1 1 1v9zM5 5v14h8V5H5zm2 6h4v2H7v-2zm0-4h4v2H7V7z" />
		</svg>
	);
}

export function InformationCircleIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" {...props}>
			<path d="M12 .96C5.9085.96.96 5.9085.96 12c0 6.0915 4.9485 11.04 11.04 11.04 6.0915 0 11.04-4.9485 11.04-11.04C23.04 5.9085 18.0915.96 12 .96zm0 .96c5.5727 0 10.08 4.5073 10.08 10.08S17.5727 22.08 12 22.08 1.92 17.5727 1.92 12 6.4273 1.92 12 1.92zm0 3.36a1.44 1.44 90 00-1.44 1.44A1.44 1.44 90 0012 8.16a1.44 1.44 90 001.44-1.44A1.44 1.44 90 0012 5.28zm-1.92 4.8v.96h.96v6.24h-.96v.96h3.84v-.96h-.96v-7.2h-2.88z" />
		</svg>
	);
}

export function InstagramIconColor(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" aria-label="Instagram" role="img" viewBox="0 0 512 512" {...props}>
			<rect width="512" height="512" rx="15%" id="b" />
			<use fill="url(#a)" xlinkHref="#b" />
			<use fill="url(#c)" xlinkHref="#b" />
			<radialGradient id="a" cx=".4" cy="1" r="1">
				<stop offset=".1" stopColor="#fd5" />
				<stop offset=".5" stopColor="#ff543e" />
				<stop offset="1" stopColor="#c837ab" />
			</radialGradient>
			<linearGradient id="c" x2=".2" y2="1">
				<stop offset=".1" stopColor="#3771c8" />
				<stop offset=".5" stopColor="#60f" stopOpacity="0" />
			</linearGradient>
			<g fill="none" stroke="#fff" strokeWidth="30">
				<rect width="308" height="308" x="102" y="102" rx="81" />
				<circle cx="256" cy="256" r="72" />
				<circle cx="347" cy="165" r="6" />
			</g>
		</svg>
	);
}
export function InstagramIconFlat(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
			<path fill="none" d="M0 0h24v24H0z" />
			<path d="M12 2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2zm0 5a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm6.5-.25a1.25 1.25 0 0 0-2.5 0 1.25 1.25 0 0 0 2.5 0zM12 9a3 3 0 1 1 0 6 3 3 0 0 1 0-6z" />
		</svg>
	);
}

export function InstagramIcon(props: React.SVGProps<SVGSVGElement>) {
	return <InstagramIconFlat {...props} />;
}

export function LinkedinIconColor(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			aria-label="LinkedIn"
			role="img"
			viewBox="0 0 512 512"
			fill="#fff"
			{...props}
		>
			<rect width="512" height="512" rx="15%" fill="#0077b5" />
			<circle cx="142" cy="138" r="37" />
			<path stroke="#fff" strokeWidth="66" d="M244 194v198M142 194v198" />
			<path d="M276 282c0-20 13-40 36-40 24 0 33 18 33 45v105h66V279c0-61-32-89-76-89-34 0-51 19-59 32" />
		</svg>
	);
}
export function LinkedinIconFlat(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
			<path fill="none" d="M0 0h24v24H0z" />
			<path d="M18.335 18.339H15.67v-4.177c0-.996-.02-2.278-1.39-2.278-1.389 0-1.601 1.084-1.601 2.205v4.25h-2.666V9.75h2.56v1.17h.035c.358-.674 1.228-1.387 2.528-1.387 2.7 0 3.2 1.778 3.2 4.091v4.715zM7.003 8.575a1.546 1.546 0 0 1-1.548-1.549 1.548 1.548 0 1 1 1.547 1.549zm1.336 9.764H5.666V9.75H8.34v8.589zM19.67 3H4.329C3.593 3 3 3.58 3 4.297v15.406C3 20.42 3.594 21 4.328 21h15.338C20.4 21 21 20.42 21 19.703V4.297C21 3.58 20.4 3 19.666 3h.003z" />
		</svg>
	);
}

export function LinkedinIcon(props: React.SVGProps<SVGSVGElement>) {
	return <LinkedinIconFlat {...props} />;
}

export function ListIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" {...props}>
			<path fill="none" d="M0 0h24v24H0z" />
			<path d="M8 4h13v2H8V4zM4.5 6.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 7a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 6.9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM8 11h13v2H8v-2zm0 7h13v2H8v-2z" />
		</svg>
	);
}

export function LocationPinIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
			<path fill="none" d="M0 0h24v24H0z" />
			<path d="M12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zm4.95-7.778a7 7 0 1 0-9.9 0L12 20.9l4.95-4.95zM12 13a2 2 0 1 1 0-4 2 2 0 0 1 0 4z" />
		</svg>
	);
}

export function MapViewIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" {...props}>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
				d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
			/>
		</svg>
	);
}

export function MaximizeIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
			<g>
				<path d="m21 9a1 1 0 0 1 -1-1v-4h-4a1 1 0 0 1 0-2h4a2 2 0 0 1 2 2v4a1 1 0 0 1 -1 1z" />
				<path d="m20 22h-4a1 1 0 0 1 0-2h4v-4a1 1 0 0 1 2 0v4a2 2 0 0 1 -2 2z" />
				<path d="m8 22h-4a2 2 0 0 1 -2-2v-4a1 1 0 0 1 2 0v4h4a1 1 0 0 1 0 2z" />
				<path d="m3 9a1 1 0 0 1 -1-1v-4a2 2 0 0 1 2-2h4a1 1 0 0 1 0 2h-4v4a1 1 0 0 1 -1 1z" />
			</g>
		</svg>
	);
}

export function MinimizeIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
			<g>
				<path d="m12 3v8a1 1 0 0 1 -1 1h-8a1 1 0 0 1 0-2h7v-7a1 1 0 0 1 2 0zm-1 17h-8a1 1 0 0 0 0 2h7v7a1 1 0 0 0 2 0v-8a1 1 0 0 0 -1-1zm10-8h8a1 1 0 0 0 0-2h-7v-7a1 1 0 0 0 -2 0v8a1 1 0 0 0 1 1zm8 8h-8a1 1 0 0 0 -1 1v8a1 1 0 0 0 2 0v-7h7a1 1 0 0 0 0-2z" />
			</g>
		</svg>
	);
}

export function MinorityOwnedIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" {...props}>
			<circle cx="256" cy="64" r="64" />
			<path d="M352 302l-15-113c-1-17-15-29-31-29h-18c-4 0-8 2-11 5l-21 20-21-20c-3-3-7-5-11-5h-18c-16 0-30 12-31 28l-15 114a16 16 0 0016 18h17l14 163c1 17 15 29 31 29h36c16 0 30-12 31-29l14-163h17a16 16 0 0016-18z" />
			<circle cx="416" cy="64" r="64" />
			<path d="M96 0a64 64 0 100 128A64 64 0 0096 0zM512 302l-15-113c-1-17-15-29-31-29h-18c-4 0-8 2-11 5l-21 20-21-20c-3-3-7-5-11-5h-18l-5 1c4 7 7 15 8 24l15 113c1 14-3 27-12 38-4 5-10 8-16 11l11 104c1 17 15 29 32 29h35c16 0 30-12 31-29l14-131h17a16 16 0 0016-18zM140 336c-9-11-13-24-12-38l15-114c1-8 4-16 8-23l-5-1h-18c-4 0-8 2-11 5l-21 20-21-20c-3-3-7-5-11-5H46c-16 0-30 12-31 28L0 302a16 16 0 0016 18h17l14 131c1 17 15 29 31 29h36c16 0 30-12 31-29l11-104c-6-3-12-6-16-11z" />
		</svg>
	);
}

export function IndustryIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg fill="currentColor" viewBox="0 0 24 24" {...props}>
			<path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
		</svg>
	);
}

export function NextPage(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
			{...props}
		>
			<polyline points="9 18 15 12 9 6" />
		</svg>
	);
}

export function OwnershipIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" {...props}>
			<path d="M226 174c36 0 67-26 73-60h92v30h30v-30h30v60h30V84H299a75 75 0 10-73 90zm0-120a45 45 0 110 90 45 45 0 010-90zm275 193a75 75 0 00-106 0l-10 10c-8-14-23-23-40-23h-53a91 91 0 00-138 5l-75 75-15-15-64 63 126 127 64-64-15-15 26-26h185l126-127zM126 446l-84-84 22-21 83 84zm247-92H189l-35 35-54-54 76-77s18-24 49-24c35 0 51 28 52 30h68a15 15 0 010 30h-90v30h90c17 0 32-10 40-24l32-32c13-14 34-17 51-9z" />
		</svg>
	);
}

export function ChevronDownIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" {...props}>
			<path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
		</svg>
	);
}

export function PhoneIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" {...props}>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
				d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
			/>
		</svg>
	);
}

export function PrevPage(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
			{...props}
		>
			<polyline points="15 18 9 12 15 6" />
		</svg>
	);
}

export function ProfileIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
			<path
				fillRule="evenodd"
				d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
				clipRule="evenodd"
			/>
		</svg>
	);
}

export function SignInIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
			<path
				fillRule="evenodd"
				d="M 15.5 20.25 A 1.5 1.5 180 0 0 17 18.75 v -13.5 a 1.5 1.5 180 0 0 -1.5 -1.5 h -6 a 1.5 1.5 180 0 0 -1.5 1.5 V 9 a 0.75 0.75 180 0 1 -1.5 0 v -3.75 a 3 3 180 0 1 3 -3 h 6 a 3 3 180 0 1 3 3 V 18.75 a 3 3 180 0 1 -3 3 h -6 a 3 3 180 0 1 -3 -3 V 15 A 0.75 0.75 180 0 1 8 15 V 18.75 a 1.5 1.5 180 0 0 1.5 1.5 h 6 z m -5.03 -4.72 a 0.75 0.75 180 0 1 0 -1.06 l 1.72 -1.72 h -10.94 a 0.75 0.75 180 0 1 0 -1.5 H 12.19 l -1.72 -1.72 a 0.75 0.75 180 1 1 1.06 -1.06 l 3 3 a 0.75 0.75 180 0 1 0 1.06 l -3 3 a 0.75 0.75 180 0 1 -1.06 0 z"
				clipRule="evenodd"
			/>
		</svg>
	);
}

export function SignOutIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
			<path
				fillRule="evenodd"
				d="M7.5 3.75A1.5 1.5 0 006 5.25v13.5a1.5 1.5 0 001.5 1.5h6a1.5 1.5 0 001.5-1.5V15a.75.75 0 011.5 0v3.75a3 3 0 01-3 3h-6a3 3 0 01-3-3V5.25a3 3 0 013-3h6a3 3 0 013 3V9A.75.75 0 0115 9V5.25a1.5 1.5 0 00-1.5-1.5h-6zm10.72 4.72a.75.75 0 011.06 0l3 3a.75.75 0 010 1.06l-3 3a.75.75 0 11-1.06-1.06l1.72-1.72H9a.75.75 0 010-1.5h10.94l-1.72-1.72a.75.75 0 010-1.06z"
				clipRule="evenodd"
			/>
		</svg>
	);
}

export function TwitterIconColor(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" aria-label="Twitter" role="img" viewBox="0 0 512 512" {...props}>
			<rect width="512" height="512" rx="15%" fill="#1da1f2" />
			<path
				fill="#fff"
				d="M437 152a72 72 0 01-40 12a72 72 0 0032-40a72 72 0 01-45 17a72 72 0 00-122 65a200 200 0 01-145-74a72 72 0 0022 94a72 72 0 01-32-7a72 72 0 0056 69a72 72 0 01-32 1a72 72 0 0067 50a200 200 0 01-105 29a200 200 0 00309-179a200 200 0 0035-37"
			/>
		</svg>
	);
}
export function TwitterIconFlat(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
			<path fill="none" d="M0 0h24v24H0z" />
			<path d="M22.162 5.656a8.384 8.384 0 0 1-2.402.658A4.196 4.196 0 0 0 21.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 0 0-7.126 3.814 11.874 11.874 0 0 1-8.62-4.37 4.168 4.168 0 0 0-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 0 1-1.894-.523v.052a4.185 4.185 0 0 0 3.355 4.101 4.21 4.21 0 0 1-1.89.072A4.185 4.185 0 0 0 7.97 16.65a8.394 8.394 0 0 1-6.191 1.732 11.83 11.83 0 0 0 6.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.496 8.496 0 0 0 2.087-2.165z" />
		</svg>
	);
}

export function TwitterIcon(props: any) {
	return <TwitterIconFlat {...props} />;
}

export function UrlIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" {...props}>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
				d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
			/>
		</svg>
	);
}

export function XIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg width="15" height="15" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z"
				fill="currentColor"
				fillRule="evenodd"
				clipRule="evenodd"
			/>
		</svg>
	);
}

export function YearsIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
			<path d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z" />
		</svg>
	);
}

export function YoutubeIconColor(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			aria-label="YouTube"
			role="img"
			viewBox="0 0 512 512"
			fill="#ed1d24"
			{...props}
		>
			<rect width="512" height="512" rx="15%" />
			<path
				d="m427 169c-4-15-17-27-32-31-34-9-239-10-278 0-15 4-28 16-32 31-9 38-10 135 0 174 4 15 17 27 32 31 36 10 241 10 278 0 15-4 28-16 32-31 9-36 9-137 0-174"
				fill="#fff"
			/>
			<path d="m220 203v106l93-53" />
		</svg>
	);
}
export function YoutubeIconFlat(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
			<path fill="none" d="M0 0h24v24H0z" />
			<path d="M21.543 6.498C22 8.28 22 12 22 12s0 3.72-.457 5.502c-.254.985-.997 1.76-1.938 2.022C17.896 20 12 20 12 20s-5.893 0-7.605-.476c-.945-.266-1.687-1.04-1.938-2.022C2 15.72 2 12 2 12s0-3.72.457-5.502c.254-.985.997-1.76 1.938-2.022C6.107 4 12 4 12 4s5.896 0 7.605.476c.945.266 1.687 1.04 1.938 2.022zM10 15.5l6-3.5-6-3.5v7z" />
		</svg>
	);
}

export function YoutubeIcon(props: any) {
	return <YoutubeIconFlat {...props} />;
}

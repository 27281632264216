import {
	OpenAPI,
	type QuestionModel,
	type ResponseModel,
	type SurveyDto,
	type SurveyModel,
	SurveyService,
} from '../api';

async function getAll({ token }): Promise<SurveyModel[]> {
	OpenAPI.TOKEN = token;
	return SurveyService.getSurveys();
}

async function get(surveyId: number): Promise<SurveyModel> {
	return SurveyService.getSurvey({ surveyId });
}

async function add(data: SurveyDto, { token }): Promise<SurveyModel> {
	OpenAPI.TOKEN = token;
	return SurveyService.createSurvey({ requestBody: data });
}

async function update(data: SurveyDto, { token }): Promise<SurveyModel> {
	OpenAPI.TOKEN = token;
	return SurveyService.updateSurvey({ requestBody: data });
}

async function remove(id: number, { token }): Promise<unknown> {
	OpenAPI.TOKEN = token;
	return SurveyService.removeSurvey({ id });
}

async function addQuestion(data: QuestionModel, { token }): Promise<QuestionModel> {
	OpenAPI.TOKEN = token;
	return SurveyService.createSurveyQuestion({ requestBody: data });
}

async function updateQuestion(data: QuestionModel, { token }): Promise<QuestionModel> {
	OpenAPI.TOKEN = token;
	return SurveyService.updateSurveyQuestion({ requestBody: data });
}

async function removeQuestion(questionId: number, { token }): Promise<unknown> {
	OpenAPI.TOKEN = token;
	return SurveyService.removeSurveyQuestion({ questionId });
}

async function getResults(
	{
		dateFrom,
		dateTo,
		allowedApiKeys,
		customerIds,
		surveyIds,
		authOnly,
	}: {
		dateFrom: string;
		dateTo: string;
		allowedApiKeys: string[];
		customerIds: number[];
		surveyIds: number[];
		authOnly?: boolean;
	},
	{ token },
): Promise<ResponseModel[]> {
	OpenAPI.TOKEN = token;
	return SurveyService.getResponses({
		allowedApiKeys,
		dateFrom,
		dateTo,
		customerIds,
		surveyIds,
		authenticatedUsersResponses: typeof authOnly !== 'undefined' ? authOnly : undefined,
	});
}

const surveysService = {
	getAll,
	get,
	add,
	update,
	remove,
	questions: {
		add: addQuestion,
		update: updateQuestion,
		remove: removeQuestion,
	},
	getResults,
};

export default surveysService;

import { Avatar, Button, Input, Radio, Row, Space, Table, Tooltip, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useAuth } from 'react-oidc-context';
import { Link, NavLink, useNavigate } from 'react-router';

import { CustomerMinimizedSettingsModel } from '../../api';
import productAdvertising from '../../assets/icons/product-advertising.png';
import productBusiness from '../../assets/icons/product-business.png';
import productCompetition from '../../assets/icons/product-competition.png';
import productDemographics from '../../assets/icons/product-demographics.png';
import productPDF from '../../assets/icons/product-pdf.png';
import productPulse from '../../assets/icons/product-pulse.png';
import productSBA from '../../assets/icons/product-sba.png';
import productShopLocal from '../../assets/icons/product-shoplocal.png';
import productSurveys from '../../assets/icons/product-surveys.png';
import PageHeader from '../../components/layout/PageHeader';
import DataService, { type CustomerMinimizedSettingsModelPlus } from '../../data.service';
import CreateForm from '../Customer/Create';

import { FilePlus, LinkIcon, PencilLine } from 'lucide-react';
import CUSTOMER_LOGOS from '../../customer-logos.json';

interface CustomerSettingsModelLight {
	apiKey: string;
	cName: string;
	dateCreated: string;
	dateCreatedISOString: string;
	dateUpdated: string;
	dateUpdatedISOString: string;
	displayName: string;
	id: number;
	isActive: boolean;
	isDeleted: boolean;
	modules: Record<string, boolean>;
	name: string;
	reports: Record<string, boolean>;
	roles: Record<string, boolean>;
}

// Images

const { Title, Paragraph } = Typography;

dayjs.extend(relativeTime);
dayjs.extend(calendar);

export function Customers() {
	const auth = useAuth();
	const [data, setData] = useState<CustomerMinimizedSettingsModelPlus[] | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	const [createForm, setCreateForm] = useState(false);

	const [status, setStatus] = useState(localStorage.getItem('usersTableStatus') || 'all');

	const navigate = useNavigate();

	const [searchText, setSearchText] = useState<string | null>('');

	const columns: ColumnsType<CustomerMinimizedSettingsModelPlus> = [
		{
			title: 'CUSTOMER',
			dataIndex: 'name',
			key: 'name',
			ellipsis: true,
			sorter: (a, b) => (a.name ?? '').localeCompare(b.name ?? ''),
			render: (text, record) => (
				<Space direction="horizontal" align="center">
					{record?.apiKey && record.name ? (
						<Avatar
							style={{ verticalAlign: 'middle' }}
							shape="square"
							size={40}
							src={
								CUSTOMER_LOGOS.includes(record.apiKey ?? 'n/a')
									? `/logos/${record.apiKey}.png`
									: `https://ui-avatars.com/api/?name=${record.name}&background=${/test|demo/i.test(record.name) ? 'ff9e16' : '1677ff'}&color=fff&size=80`
							}
						/>
					) : null}
					<div>
						<Title level={5} style={{ margin: 0, maxWidth: '500px', whiteSpace: 'wrap' }}>
							{text}
						</Title>
						<Paragraph style={{ fontSize: '90%', marginBottom: '0' }} copyable type="secondary" code>
							{record.apiKey}
						</Paragraph>
					</div>
				</Space>
			),
		},
		{
			// title: ({ sortOrder, sortColumn }) => {
			// 	return <div>{sortOrder ? 'CREATED' : 'UPDATED'}</div>;
			// },
			title: 'CREATED',
			key: 'dateCreated',
			dataIndex: 'dateCreated',
			responsive: ['lg'],
			sorter: (a, b) => dayjs(a.dateCreated).unix() - dayjs(b.dateCreated).unix(),
			render: (text, record) => (
				<React.Fragment>
					{dayjs(record.dateCreated).calendar()}
					<div
						style={{
							color: '#8c8c8c',
						}}
					>
						updated {dayjs(record.dateUpdated).fromNow(false)}
					</div>
				</React.Fragment>
			),
		},
		{
			title: 'PRODUCTS',
			dataIndex: 'roles',
			key: 'roles',
			// width: 200,
			responsive: ['md'],
			render: (text, record) => (
				<div className="avatar-group mt-2" style={{ minWidth: '220px' }}>
					<Tooltip placement="bottom" title="Dashboard">
						<img
							className={`tootip-img ${!record.roles.MyBusinessModule && 'inactive'}`}
							src={productBusiness}
							alt="Business"
						/>
					</Tooltip>

					<Tooltip placement="bottom" title="Competition">
						<img
							className={`tootip-img ${!record.roles.CompetitionModule && 'inactive'}`}
							src={productCompetition}
							alt="Competition"
						/>
					</Tooltip>

					<Tooltip placement="bottom" title="Advertising">
						<img
							className={`tootip-img ${!record.roles.AdvertisingModule && 'inactive'}`}
							src={productAdvertising}
							alt="Advertising"
						/>
					</Tooltip>

					<Tooltip placement="bottom" title="Demographics">
						<img
							className={`tootip-img ${!record.roles.Demographics && 'inactive'}`}
							src={productDemographics}
							alt="Demographics"
						/>
					</Tooltip>

					<Tooltip placement="bottom" title="ShopLocal">
						<img
							className={`tootip-img ${!record.roles.ShopLocal && 'inactive'}`}
							src={productShopLocal}
							alt="ShopLocal"
						/>
					</Tooltip>

					<Tooltip placement="bottom" title="SB Advisor">
						<img
							className={`tootip-img ${!record.roles.Entrepreneur && 'inactive'}`}
							src={productSBA}
							alt="SBA"
						/>
					</Tooltip>

					<Tooltip placement="bottom" title="Pulse">
						<img
							className={`tootip-img ${!record.roles.LIPD && 'inactive'}`}
							src={productPulse}
							alt="Pulse"
						/>
					</Tooltip>

					<Tooltip placement="bottom" title="Surveys">
						<img
							className={`tootip-img ${!record.roles.Surveys && 'inactive'}`}
							src={productSurveys}
							alt="Surveys"
						/>
					</Tooltip>

					<Tooltip placement="bottom" title="PDF">
						<img className={`tootip-img ${!record.roles.PDF && 'inactive'}`} src={productPDF} alt="PDF" />
					</Tooltip>
				</div>
			),
		},
		{
			title: 'STATUS',
			key: 'isActive',
			dataIndex: 'isActive',
			responsive: ['lg'],
			render: (text, record) => (
				<Space direction="horizontal">
					<Link
						to={`https://${record.apiKey}.instance.sizeup.com/`}
						target="_blank"
						referrerPolicy="no-referrer"
					>
						<Button type="primary" disabled={!record.isActive || record.isDeleted}>
							{record.isActive ? 'ACTIVE' : record.isDeleted ? 'DELETED' : 'INACTIVE'}
						</Button>
					</Link>
					{record.isActive && record.cName ? (
						<Link to={`https://${record.cName}`} target="_blank" referrerPolicy="no-referrer">
							<Button type="primary" icon={<LinkIcon size={16} />} />
						</Link>
					) : null}
				</Space>
			),
		},
		{
			title: '',
			key: 'actions',
			render: (text, record) => (
				<NavLink to={`/edit/${record.id}`}>
					<Button type="link" className="darkbtn" icon={<PencilLine size={16} />}>
						EDIT
					</Button>
				</NavLink>
			),
		},
	];

	const onCreate = (values) => {
		setCreateForm(false);

		const toastId = toast.loading('Creating Customer...');

		DataService.customers
			.add(values, { token: auth.user?.access_token })
			.then((data) => {
				toast.success('Customer Created', { id: toastId });
				navigate(`/edit/${data.id}`);
			})
			.catch(() => {
				toast.error('Error creating customer', { id: toastId });
			});
	};

	useEffect(() => {
		setIsLoading(true);
		DataService.customers
			.getAll({ token: auth.user?.access_token })
			.then((data) => {
				setData(data);
				setIsLoading(false);
			})
			.catch((error) => {
				setError(error);
				setIsLoading(false);
				if (error === 401) {
					toast.error('Your session has expired. Please log in again.');
					auth.removeUser();
					navigate('/');
				}
			});
	}, [auth, navigate]);

	const filteredData = useMemo(() => {
		if (!data) return [];

		return data
			.filter((d) => status === 'all' || d.isActive === (status === 'active'))
			.filter(
				(d) =>
					searchText === '' ||
					searchText === null ||
					(d.apiKey ?? '').toLowerCase().includes(searchText.toLowerCase()) ||
					(d.name ?? '').toLowerCase().includes(searchText.toLowerCase()) ||
					(d.cName ?? '').toLowerCase().includes(searchText.toLowerCase()) ||
					(d.displayName ?? '').toLowerCase().includes(searchText.toLowerCase()),
			);
	}, [data, status, searchText]);

	return (
		<div className="tabled">
			<PageHeader
				title="Instances"
				extra={
					<Space>
						<Input.Search
							placeholder="Type key or name here"
							onChange={(e) => setSearchText(e.target.value)}
							onSearch={(value) => setSearchText(value)}
						/>
						{auth.user?.profile?.role === 'sizeup_admin' ? (
							<Button
								icon={<FilePlus size={16} />}
								onClick={() => setCreateForm(true)}
								type="primary"
								// shape="round"
							>
								Add Client
							</Button>
						) : null}
					</Space>
				}
			/>
			<Row justify="end" style={{ margin: '0 16px 8px 16px' }}>
				<Space direction="horizontal" align="center">
					<Radio.Group
						onChange={(event) => {
							setStatus(event.target.value);
							localStorage.setItem('usersTableStatus', event.target.value);
						}}
						value={status}
						buttonStyle="solid"
					>
						<Radio.Button value="all">All</Radio.Button>
						<Radio.Button value="active">ACTIVE</Radio.Button>
						<Radio.Button value="inactive">INACTIVE</Radio.Button>
					</Radio.Group>
				</Space>
			</Row>
			<CreateForm
				onCreate={onCreate}
				onCancel={() => {
					setCreateForm(false);
				}}
				open={createForm}
			/>
			<div className="table-responsive">
				<Table
					columns={columns}
					dataSource={filteredData}
					pagination={{
						pageSize: 20,
						showSizeChanger: false,
						hideOnSinglePage: true,
						showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
					}}
					className="ant-border-space"
					loading={isLoading}
					rowKey="id"
					tableLayout="auto"
				/>
			</div>
		</div>
	);
}

import { Button, Col, Form, Input, InputNumber, Modal, Row, Select, Space } from 'antd';
import { CircleMinus, CirclePlus } from 'lucide-react';
import React from 'react';

const { Option } = Select;

const QUESTION_TYPES = [
	'No Answers', // 0000 = 0
	'Image', // 0001 = 1
	'Text', // 0010 = 2
	'Short Text', // 0011 = 3
	'Yes/No', // 0100 = 4
	'Yes/No + Yes Text', // 0101 = 5
	'Yes/No + No Text', // 0110 = 6
	'Yes/No + Always Text', // 0111 = 7
	null,
	'Date Select', // 1001 = 9
	'Time Select', // 1010 = 10
	'Year Select', // 1011 = 11
	'Email', // 1100 = 12
	'Phone', // 1101 = 13
	'Number', // 1110 = 14
	null,
	'Custom Options', // 10000 = 16
	'Custom Options + Text', // 10001 = 17
	'Custom Options + Multiple', // 10010 = 18
];

interface EditSurveyProps {
	survey: any;
	onEdit: (survey: any) => void;
	onCancel: () => void;
}

export default function EditSurvey({ survey, onEdit, onCancel }: EditSurveyProps) {
	const [form] = Form.useForm();
	const formRef = React.useRef(null);

	// const moveUp = useCallback(
	// 	(index) => {
	// 		console.log('index -> ', index);
	// 		const fields = form.getFieldsValue();
	// 		form.setFieldsValue({
	// 			questions: fields.questions.map((question, i) => {
	// 				return {
	// 					...question,
	// 					order: index === i ? i - 1 : i === index - 1 ? i + 1 : i,
	// 				};
	// 			}),
	// 		});
	// 	},
	// 	[survey]
	// );

	// const moveDown = useCallback(
	// 	(index) => {
	// 		const fields = form.getFieldsValue();
	// 		form.setFieldsValue({
	// 			questions: fields.questions.map((question, i) => {
	// 				return {
	// 					...question,
	// 					order: index === i ? i + 1 : i === index + 1 ? i - 1 : i,
	// 				};
	// 			}),
	// 		});
	// 	},
	// 	[survey]
	// );

	form.setFieldsValue(survey);

	return (
		<Modal
			open={!!survey}
			title="Edit the survey"
			okText="Edit"
			cancelText="Cancel"
			onCancel={onCancel}
			onOk={() => {
				form.validateFields()
					.then((values) => {
						form.resetFields();
						onEdit(values);
					})
					.catch((info) => {
						console.log('Validate Failed:', info);
					});
			}}
		>
			<Form form={form} layout="vertical" style={{ maxWidth: 600 }} ref={formRef}>
				<Form.Item name="id" style={{ display: 'none' }}>
					<Input />
				</Form.Item>
				<Form.Item name="name">
					<Input placeholder="Name" />
				</Form.Item>
				<Form.Item name="description">
					<Input placeholder="Description" />
				</Form.Item>
				<Form.Item name="tenantId">
					<Input placeholder="Tenant ID" readOnly />
				</Form.Item>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<Form.List name="questions">
						{(questions, { add, remove }) => (
							<React.Fragment>
								{questions.map((field: any, index) => (
									<div
										key={field.key}
										style={{
											marginTop: '.5rem',
											order: field.order ? field.order : 0,
										}}
									>
										<Form.Item
											{...field}
											key={field.id}
											label={
												<Space>
													<div>Question</div>

													<Button
														icon={<CircleMinus size={16} />}
														size="small"
														type="primary"
														danger
														onClick={() => remove(field.name)}
													/>

													{/* <Button
                                                    type="primary"
                                                    size="small"
                                                    icon={<ArrowUpOutlined />}
                                                    onClick={() => moveUp(index)}
                                                />
                                                <Button
                                                    type="primary"
                                                    size="small"
                                                    icon={<ArrowDownOutlined />}
                                                    onClick={() => moveDown(index)}
                                                /> */}
												</Space>
											}
											name={[field.name, 'text']}
											rules={[{ required: true, message: 'Missing question title' }]}
										>
											<Input />
										</Form.Item>

										<Form.Item {...field} label="Type" name={[field.name, 'type']}>
											<Select
												style={{ width: 220 }}
												onChange={(newValue) => {
													if (newValue >= 16) {
														form.setFieldValue(
															['questions', field.name, 'options'],
															survey.questions[index]?.options || ['One', 'Two', 'Three'],
														);
													} else {
														form.setFieldValue(['questions', field.name, 'options'], []);
													}
												}}
											>
												{QUESTION_TYPES.map((type, index) => {
													if (type) {
														return (
															<Option key={index.toString()} value={index}>
																{type}
															</Option>
														);
													}
													return null;
												})}
											</Select>
										</Form.Item>
										<Space align="baseline">
											<Form.Item {...field} label="Order" name={[field.name, 'order']}>
												<InputNumber />
											</Form.Item>
										</Space>
										<Form.List name={[field.name, 'options']}>
											{(fields, { add, remove }) => (
												<React.Fragment>
													{fields.map((field, index) => (
														<Row align="top" key={field.key}>
															<Col flex="auto">
																<Form.Item {...field}>
																	<Input />
																</Form.Item>
															</Col>
															<Col flex="20px" style={{ marginLeft: '.5rem' }}>
																<CircleMinus size={16} onClick={() => remove(index)} />
															</Col>
														</Row>
													))}

													<Button
														type="dashed"
														onClick={() => add()}
														block
														icon={<CirclePlus size={16} />}
													>
														Add an option
													</Button>
												</React.Fragment>
											)}
										</Form.List>
									</div>
								))}

								<Form.Item>
									<Button
										type="dashed"
										onClick={() =>
											add({
												order: questions.length,
											})
										}
										block
										icon={<CirclePlus size={16} />}
									>
										Add a question
									</Button>
								</Form.Item>
							</React.Fragment>
						)}
					</Form.List>
				</div>
			</Form>
		</Modal>
	);
}

import { Button, Popconfirm, Spin, Table } from 'antd';
import { PencilLine, Trash } from 'lucide-react';
import React, { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import SVG from 'react-inlinesvg';
import { useAuth } from 'react-oidc-context';

import type { IconResourceModel, TagModel } from '../../api';
import DataService from '../../data.service';
import TagCreate from './Tags-Create';
import TagEdit from './Tags-Edit';

interface TagsTableProps {
	data?: unknown[];
	icons: IconResourceModel[];
	onEdit(...args: unknown[]): unknown;
	onRemove(...args: unknown[]): unknown;
	loading: boolean;
}

function TagsTable({ data, icons, onEdit, onRemove, loading }: TagsTableProps) {
	const columns = useMemo(
		() => [
			{
				title: 'Id',
				dataIndex: 'id',
				key: 'id',
			},
			{
				title: 'Icon',
				dataIndex: 'iconId',
				key: 'iconId',
				render: (text, record) => {
					const icon = icons.find((i) => i.id === record.iconId);
					if (icon?.svgContent) {
						return <SVG src={icon.svgContent} style={{ width: '40px', height: '40px' }} />;
					}
					if (icons?.length) {
						return null;
					}
					return <Spin />;
				},
			},
			{
				title: 'Name',
				dataIndex: 'name',
				key: 'name',
			},
			{
				title: 'Actions',
				dataIndex: 'id',
				key: 'actions',
				render: (text, record) => (
					<div>
						<Button onClick={() => onEdit(record)} icon={<PencilLine size={16} />} type="link" />
						<Popconfirm title="Sure to delete?" onConfirm={() => onRemove(record)}>
							<Button icon={<Trash size={16} />} type="link" danger />
						</Popconfirm>
					</div>
				),
			},
		],
		[onRemove, onEdit, icons],
	);

	return (
		<div>
			<Table columns={columns} dataSource={data} loading={loading} rowKey="id" pagination={false} />
		</div>
	);
}

interface TagsProps {
	data?: unknown[];
	icons: IconResourceModel[];
	setData(...args: unknown[]): unknown;
	isLoading: boolean;
	setIsLoading(...args: unknown[]): unknown;
	createMode?: boolean;
	setCreateMode(...args: unknown[]): unknown;
}

// import tempbase from './tempbase.json';

export function Tags({ data, icons, setData, isLoading, setIsLoading, createMode = false, setCreateMode }: TagsProps) {
	const auth = useAuth();

	const [editForm, setEditForm] = useState(false);

	const [selectedRow, setSelectedRow] = useState<TagModel | null>(null);

	const onCreate = (values) => {
		console.log('create tag', values);
		const toastId = toast.loading('Creating tag...');
		DataService.entrepreneur.tags
			.add(values, {
				token: auth.user?.access_token,
			})
			.then((item) => {
				setData((data) => [...data, item]);
				toast.success('Tag created', { id: toastId });
			})
			.catch((err) => {
				toast.error(err?.message, { id: toastId });
			});
		setCreateMode(false);
	};

	const onUpdate = (values) => {
		console.info('Update Part', values);
		const toastId = toast.loading('Updating Part...');
		DataService.entrepreneur.tags
			.update(values, {
				token: auth.user?.access_token,
			})
			.then((item) => {
				setIsLoading(false);
				setData((data) => {
					const index = data.findIndex((x) => x.id === item.id);
					data[index] = { ...item };
					return [...data];
				});
				toast.success('Part Updated', { id: toastId });
			})
			.catch((err) => {
				toast.error(err?.message, { id: toastId });
			});
		setEditForm(false);
		setSelectedRow(null);
	};

	const onRemove = ({ id }) => {
		const toastId = toast.loading('Removing tag...');
		DataService.entrepreneur.tags
			.remove(id, {
				token: auth.user?.access_token,
			})
			.then(() => {
				setData((data) => {
					const index = data.findIndex((x) => x.id === id);
					data.splice(index, 1);
					return [...data];
				});
				toast.success('Tag removed', { id: toastId });
			})
			.catch((err) => {
				toast.error(err?.message, { id: toastId });
			});
	};

	return (
		<div>
			<TagCreate
				open={createMode}
				icons={icons}
				onCreate={onCreate}
				onCancel={() => {
					setCreateMode(false);
				}}
			/>
			{selectedRow ? (
				<TagEdit
					open={editForm}
					onUpdate={onUpdate}
					onCancel={() => {
						setEditForm(false);
						setSelectedRow(null);
					}}
					data={selectedRow}
					icons={icons}
				/>
			) : null}
			<TagsTable
				data={data}
				icons={icons}
				onEdit={(row: TagModel) => {
					setEditForm(true);
					setSelectedRow(row);
				}}
				onRemove={onRemove}
				loading={isLoading}
				// rowKey="id"
			/>
		</div>
	);
}

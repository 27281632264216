import { Form, Input, Modal, Select, Space, Switch } from 'antd';
import React, { useEffect } from 'react';
import SVG from 'react-inlinesvg';

import type { IconResourceModel, PageModel, PartModel, TagModel, TopicModel } from '../../api';
import RichEditor from '../../components/RichEditor';

interface TopicsEditProps {
	data: TopicModel;
	open: boolean;
	onUpdate(...args: unknown[]): unknown;
	onCancel(...args: unknown[]): unknown;
	icons: IconResourceModel[];
	parts: PartModel[];
	pages: PageModel[];
	tags: TagModel[];
}

export default function TopicsEdit({ data, open, onUpdate, onCancel, icons, parts, pages, tags }: TopicsEditProps) {
	const [form] = Form.useForm();

	useEffect(() => {
		form.setFieldsValue(data);
	}, [form, data]);

	return (
		<Modal
			open={open}
			title="Edit Topic"
			okText="Edit"
			cancelText="Cancel"
			onCancel={onCancel}
			onOk={() => {
				form.validateFields()
					.then((values) => {
						form.resetFields();
						onUpdate({
							...data,
							...values,
						});
					})
					.catch((info) => {
						console.log('Validate Failed:', info);
					});
			}}
		>
			<Form form={form} layout="vertical" name="e_edit_form">
				<Form.Item
					name="title"
					label="Title"
					rules={[
						{
							required: true,
							message: 'Please input the title',
						},
					]}
				>
					<Input placeholder="Enter a Title" />
				</Form.Item>
				<Form.Item name="iconId" label="Icon">
					<Select
						placeholder="Select a icon"
						allowClear
						showSearch
						optionFilterProp="title"
						filterOption={(input, option: any) => option.title.toLowerCase().includes(input.toLowerCase())}
					>
						{icons.map((icon) => (
							<Select.Option key={icon.id} value={icon.id} title={icon.title}>
								<Space align="baseline">
									{icon.svgContent && (
										<SVG
											src={icon.svgContent}
											style={{
												width: '20px',
												height: '20px',
												verticalAlign: 'middle',
											}}
										/>
									)}
									{icon.title}
								</Space>
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item name="partId" label="Part">
					<Select
						placeholder="Select a part"
						allowClear
						showSearch
						optionFilterProp="name"
						filterOption={(input, option: any) => option.name.toLowerCase().includes(input.toLowerCase())}
					>
						{parts?.map((part) => (
							<Select.Option key={part.id} value={part.id} name={part.name}>
								{part.name}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item name="description" label="Description">
					<RichEditor />
				</Form.Item>
				<Form.Item name="hasProduct" label="Has Product" valuePropName="checked">
					<Switch />
				</Form.Item>
				<Form.Item name="pages" label="Pages">
					<Select
						mode="multiple"
						allowClear
						fieldNames={{ label: 'name', value: 'id' }}
						placeholder="Select pages"
						filterOption={(input, option: any) => option.name.toLowerCase().includes(input.toLowerCase())}
						options={pages}
					/>
				</Form.Item>
				<Form.Item name="tags" label="Tags">
					<Select
						mode="tags"
						allowClear
						fieldNames={{ label: 'name', value: 'id' }}
						placeholder="Select tags"
						filterOption={(input, option: any) => option.name.toLowerCase().includes(input.toLowerCase())}
						options={tags}
					/>
				</Form.Item>
				<Form.Item name="displayOrder" label="Display Order">
					<Input type="number" />
				</Form.Item>
			</Form>
		</Modal>
	);
}

import { Select, type SelectProps, Spin, Tag } from 'antd';
import type React from 'react';
import { useMemo, useRef, useState } from 'react';
import { debounce } from 'remeda';

import { useAuth } from 'react-oidc-context';
import DataService from '../../data.service';

function tagRender(props: {
	label: React.ReactNode;
	value: any;
	disabled: boolean;
	onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	closable: boolean;
}) {
	const { label, closable, onClose } = props;
	const onPreventMouseDown = (event) => {
		event.preventDefault();
		event.stopPropagation();
	};

	return (
		<Tag
			color="#08D"
			onMouseDown={onPreventMouseDown}
			closable={closable}
			onClose={onClose}
			style={{ marginRight: 3 }}
		>
			{label}
		</Tag>
	);
}

interface DebounceSelectProps {
	fetchOptions: (value: string, granularity: number, token?: string) => Promise<any[]>;
	debounceTimeout?: number;
	granularity: number;
	token?: string;
}

function DebounceSelect({
	fetchOptions,
	debounceTimeout = 800,
	granularity,
	token,
	...props
}: DebounceSelectProps & SelectProps<any>) {
	const [fetching, setFetching] = useState(false);
	const [options, setOptions] = useState<any[]>([]);
	const fetchRef = useRef(0);
	const debounceFetcher = useMemo(() => {
		const loadOptions = (value) => {
			if (value === '') {
				return;
			}

			fetchRef.current += 1;
			const fetchId = fetchRef.current;
			setOptions([]);
			setFetching(true);
			fetchOptions(value, granularity, token).then((newOptions) => {
				if (fetchId !== fetchRef.current) {
					// for fetch callback order
					return;
				}

				setOptions(newOptions);
				setFetching(false);
			});
		};

		return debounce(loadOptions, { waitMs: debounceTimeout });
	}, [fetchOptions, granularity, debounceTimeout]);
	return (
		<Select
			labelInValue
			filterOption={false}
			onSearch={debounceFetcher.call}
			tagRender={tagRender}
			notFoundContent={fetching ? <Spin size="small" /> : null}
			{...props}
			options={options}
		/>
	);
} // Usage of DebounceSelect

async function fetchAreaList(areaname, granularity, token) {
	const areas = await DataService.regions.get({ areaname, granularity, token });
	return areas.map((area) => ({
		label: area.name,
		value: area.seoKey,
	}));
}

function CitySelect(props: {
	value?: {
		name?: string;
		seoKey?: string;
		geographicLocation?: string;
	}[];
	onChange?(...args: unknown[]): unknown;
}) {
	const auth = useAuth();
	const [value, setValue] = useState(props.value ? [{ label: props.value, value: props.value }] : []);

	const isMaxValues = useMemo(() => value.length >= 1, [value]);

	return (
		<DebounceSelect
			mode="multiple"
			value={value}
			placeholder="Select city areas"
			fetchOptions={fetchAreaList}
			granularity={2}
			token={auth.user?.access_token}
			labelInValue
			onChange={(value) => {
				if (!value || value.length === 0) {
					setValue([]);
					props.onChange?.(null);
				} else {
					setValue([
						{
							label: value[0].value,
							value: value[0].value,
						},
					]);
					props.onChange?.(value[0].value);
				}
			}}
			style={{
				width: '100%',
			}}
			{...(isMaxValues && {
				open: false,
			})}
		/>
	);
}

export default CitySelect;

import { Layout } from 'antd';
import type React from 'react';

interface PageHeaderProps {
	title: string;
	subTitle?: string;
	extra?: React.ReactNode;
}

export default function PageHeader(props: PageHeaderProps) {
	return (
		<Layout.Header className="cst-page-header">
			<div className="cst-page-header-heading">
				<div className="cst-page-header-heading-left">
					<span className="cst-page-header-heading-title">{props.title}</span>
					<span className="cst-page-header-heading-sub-title">{props.subTitle}</span>
				</div>
				<div className="cst-page-header-heading-extra ">{props.extra}</div>
			</div>
		</Layout.Header>
	);
}

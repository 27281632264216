import { Form, Input, InputNumber, Modal, Slider, Switch } from 'antd';
import React from 'react';

interface RoleCreateProps {
	open: boolean;
	onCreate(...args: unknown[]): unknown;
	onCancel(...args: unknown[]): unknown;
	displayOrder?: number;
}

export default function RoleCreate({ open, onCreate, onCancel, displayOrder = 100 }: RoleCreateProps) {
	const [form] = Form.useForm();
	return (
		<Modal
			open={open}
			title="Create a new role"
			okText="Create"
			cancelText="Cancel"
			onCancel={onCancel}
			onOk={() => {
				form.validateFields()
					.then((values) => {
						form.resetFields();
						onCreate(values);
					})
					.catch((info) => {
						console.log('Validate Failed:', info);
					});
			}}
		>
			<Form
				form={form}
				layout="vertical"
				name="form_in_modal"
				initialValues={{
					description: '',
					displayOrder,
				}}
			>
				<Form.Item
					name="name"
					label="Name"
					rules={[
						{
							required: true,
							message: 'Please input the name',
						},
					]}
				>
					<Input placeholder="Enter role name" />
				</Form.Item>
				<Form.Item name="displayName" label="Display Name">
					<Input />
				</Form.Item>
				<Form.Item name="description" label="Description">
					<Input />
				</Form.Item>
				<Form.Item name="defaultValue" label="Default Value">
					<Switch />
				</Form.Item>
				<Form.Item name="displayOrder" label="Display Order">
					<InputNumber disabled />
				</Form.Item>
			</Form>
		</Modal>
	);
}

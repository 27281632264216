import { Button } from 'antd';
import { SquarePlus } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useAuth } from 'react-oidc-context';
import PageHeader from '../../components/layout/PageHeader';

import type { RoleModel, SettingModel } from '../../api';
import DataService from '../../data.service';
import SettingsCreate from './Create';
import SettingsEdit from './Edit';
import SettingsTable from './Table';

interface SettingModelPlus extends SettingModel {
	parentRole?: RoleModel;
}

export function Settings() {
	const auth = useAuth();
	const [createForm, setCreateForm] = useState(false);
	const [editForm, setEditForm] = useState(false);

	const [data, setData] = useState<SettingModelPlus[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	const [roles, setRoles] = useState<RoleModel[]>([]);

	const [selectedRow, setSelectedRow] = useState<SettingModelPlus | null>(null);

	const onCreate = (values) => {
		const toastId = toast.loading('Creating Setting...');
		DataService.settings
			.add(values, {
				token: auth.user?.access_token,
			})
			.then((setting) => {
				const settingExtended: SettingModelPlus = {
					...setting,
					parentRole: roles.find((r) => r.id === setting.parentRoleId),
				};
				setData((data) => [...data, settingExtended]);
				toast.success('Setting Created', { id: toastId });
			})
			.catch((error) => {
				console.error(error);
				toast.error(error?.message, { id: toastId });
			})
			.finally(() => {
				setIsLoading(false);
			});

		setCreateForm(false);
	};

	const onUpdate = (newItem) => {
		setEditForm(false);
		setSelectedRow(null);
		const toastId = toast.loading('Updating Setting...');

		DataService.settings
			.update(newItem, {
				token: auth.user?.access_token,
			})
			.then((setting) => {
				setData((data) => {
					const index = data.findIndex((x) => x.id === setting.id);
					data[index] = {
						...setting,
						parentRole: roles.find((r) => r.id === setting.parentRoleId),
					};
					return [...data];
				});
				toast.success('Setting Updated', { id: toastId });
			})
			.catch((error) => {
				setError(error);
				toast.error(error, { id: toastId });
			});
	};

	useEffect(() => {
		setIsLoading(true);

		Promise.all([
			DataService.settings.getAll({ token: auth.user?.access_token }),
			DataService.roles.getAll({ token: auth.user?.access_token }),
		])
			.then(([settings, roles]) => {
				setRoles(roles);
				setData(
					settings.map((setting) => ({
						...setting,
						parentRole: roles.find((r) => r.id === setting.parentRoleId),
					})),
				);
				setIsLoading(false);
			})
			.catch((error) => {
				console.log('error', error);
				setRoles([]);
				setData([]);
				setIsLoading(false);
				setError(error);
			});
	}, [auth.user?.access_token]);

	const onOrderChange = (newOrder) => {
		setIsLoading(true);
		DataService.settings
			.updateOrder(newOrder, {
				token: auth.user?.access_token,
			})
			.then(() => {
				setIsLoading(false);
			});
	};

	if (error === 401 || error === 403) {
		toast('Your session has expired. Please sign in again.');
		auth.removeUser();
		// setLocation('/');
	}

	return (
		<div>
			<PageHeader
				title="Settings"
				extra={[
					<Button key="1" icon={<SquarePlus size={16} />} onClick={() => setCreateForm(true)}>
						Create a New Setting
					</Button>,
				]}
			/>
			{error && <strong>Error: {error}</strong>}

			<SettingsCreate
				open={createForm}
				onCreate={onCreate}
				onCancel={() => {
					setCreateForm(false);
				}}
				roles={roles}
			/>

			<SettingsEdit
				open={editForm}
				onEdit={onUpdate}
				onCancel={() => {
					setSelectedRow(null);
					setEditForm(false);
				}}
				data={selectedRow}
				roles={roles}
			/>

			<SettingsTable
				data={data}
				loading={isLoading}
				onOrderUpdate={onOrderChange}
				onEdit={(row: SettingModelPlus) => {
					setSelectedRow(row);
					setEditForm(true);
				}}
				roles={roles}
			/>
		</div>
	);
}

import { Button, Col, Row, Space, Switch, Tabs, Typography } from 'antd';
import React from 'react';

import { ExternalLink, Eye } from 'lucide-react';
import type { CustomerSettingsModelPlus } from '../../types/CustomerSettingsModelPlus';
import SmartSetting from './SmartSetting';

const { Text } = Typography;

function getCode(apiKey) {
	return `<script src="https://insights.sizeup.com/bundle.js" data-key="${apiKey}"></script>`;
}

function getPopUpCode(apiKey) {
	return `<!-- Sizeup link widget begin -->
<link href="https://instance-beta.sizeup.com/widget.css" rel="stylesheet">
<script src="https://instance-beta.sizeup.com/widget.js" type="text/javascript" async></script>
<a href="" onclick="Sizeup.initPopupWidget({product: 'insights', key: '${apiKey}'});return false;">Open Business Insights</a>
<!-- Sizeup link widget end -->`;
}

interface CustomerWidgetProps {
	data: CustomerSettingsModelPlus;
	isAdmin?: boolean;
}

export default function CustomerWidget({ data, isAdmin }: CustomerWidgetProps) {
	return (
		<div>
			{isAdmin ? (
				<Row gutter={[8, 0]} wrap={false}>
					<Col flex="auto">
						<Text copyable={{ text: getCode(data.apiKey) }}>Integration Code: </Text>
					</Col>
					<Col>
						{data.apiKey ? (
							<Space.Compact>
								<Button
									type="link"
									icon={<Eye size={16} />}
									onClick={() =>
										window.Sizeup.initPopupWidget({ product: 'insights', key: data.apiKey || '' })
									}
								>
									Inline Preview
								</Button>
								<Button
									type="link"
									icon={<ExternalLink size={16} />}
									onClick={() =>
										window.open(
											`https://${data.apiKey}.instance.sizeup.com/insights.html`,
											'_blank',
										)
									}
								>
									Hosted Instance
								</Button>
								<Button
									type="link"
									icon={<ExternalLink size={16} />}
									onClick={() =>
										window.open(
											`https://insights.sizeup.com/preview.html?apiKey=${data.apiKey}`,
											'_blank',
										)
									}
								>
									External Preview
								</Button>
								<Button
									type="link"
									icon={<ExternalLink size={16} />}
									onClick={() =>
										window.open(
											`https://widget-beta.sizeup.com/preview.html?apiKey=${data.apiKey}`,
											'_blank',
										)
									}
								>
									(Beta)
								</Button>
							</Space.Compact>
						) : null}
					</Col>
				</Row>
			) : null}

			<Tabs
				tabPosition="right"
				size="small"
				items={[
					{
						key: '1',
						label: 'Inline',
						children: (
							<pre style={{ background: 'white', padding: '1rem', fontSize: 'smaller' }}>
								{getCode(data.apiKey)}
							</pre>
						),
					},
					{
						key: '2',
						label: 'PopUp',
						children: (
							<pre style={{ background: 'white', padding: '1rem', fontSize: 'smaller' }}>
								{getPopUpCode(data.apiKey)}
							</pre>
						),
					},
				]}
			/>
			{/* <Tabs.TabPane key="1" tab="Inline">
					<pre style={{ background: 'white', padding: '1rem', fontSize: 'smaller' }}>
						{getCode(data.apiKey)}
					</pre>
				</Tabs.TabPane>
				<Tabs.TabPane key="2" tab="PopUp">
					<pre style={{ background: 'white', padding: '1rem', fontSize: 'smaller' }}>
						{getPopUpCode(data.apiKey)}
					</pre>
				</Tabs.TabPane>
			</Tabs> */}

			{data.settings?.map((setting, index) =>
				setting.groupTag === 'Widget UI' ? (
					<SmartSetting setting={setting} index={index} key={setting.keyName} />
				) : null,
			)}

			{data.settings?.map((setting, index) =>
				setting.groupTag === 'Reports' ? (
					<SmartSetting setting={setting} index={index} key={setting.keyName} />
				) : null,
			)}
		</div>
	);
}

import { Form, Input, Modal } from 'antd';
import React, { useEffect } from 'react';

interface EditIconFormProps {
	data: any;
	open: boolean;
	onUpdate: (values: any) => void;
	onCancel: () => void;
}

export default function EditIconForm({ data, open, onUpdate, onCancel }: EditIconFormProps) {
	const [form] = Form.useForm();

	useEffect(() => {
		form.setFieldsValue(data);
	}, [form, data]);

	return (
		<Modal
			open={open}
			title="Edit Icon"
			okText="Edit"
			cancelText="Cancel"
			onCancel={onCancel}
			onOk={() => {
				form.validateFields()
					.then((values) => {
						form.resetFields();

						onUpdate({
							...data,
							...values,
						});
					})
					.catch((info) => {
						console.log('Validate Failed:', info);
					});
			}}
		>
			<Form form={form} layout="vertical" name="icon_edit_form">
				<Form.Item
					name="title"
					label="Title"
					rules={[
						{
							required: true,
							message: 'Please input the title',
						},
					]}
				>
					<Input placeholder="Enter icon title" />
				</Form.Item>
				<Form.Item name="svgContent" label="SVG">
					<Input.TextArea rows={10} />
				</Form.Item>
			</Form>
		</Modal>
	);
}

import { Select, type SelectProps, Spin } from 'antd';

import React, { useEffect, useMemo, useState } from 'react';

async function fetchIndustries() {
	return fetch('./industries.json')
		.then((response) => response.json())
		.then((industries) => industries.map(({ Name: label, SEOKey: value }) => ({ label, value })));
}

interface DebounceSelectProps {
	fetchOptions: () => Promise<any[]>;
}

function DebounceSelect({ fetchOptions, ...props }: DebounceSelectProps & SelectProps<any>) {
	const [fetching, setFetching] = useState(false);
	const [options, setOptions] = useState<any[]>([]);

	useEffect(() => {
		setOptions([]);
		setFetching(true);

		fetchOptions().then((newOptions) => {
			setOptions(newOptions);
			setFetching(false);
		});
	}, [fetchOptions]);

	return (
		<Select
			mode="multiple"
			labelInValue
			notFoundContent={fetching ? <Spin size="small" /> : null}
			{...props}
			options={options}
			filterOption={(input, option: any) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
		/>
	);
}

function IndustrySelect(props: { value?: string; onChange?(...args: unknown[]): unknown }) {
	const [value, setValue] = useState(props.value ? [{ label: props.value, value: props.value }] : []);

	const isMaxValues = useMemo(() => value.length >= 1, [value]);

	return (
		<DebounceSelect
			mode="multiple"
			value={value}
			onChange={(value) => {
				if (!value || value.length === 0) {
					setValue([]);
					props.onChange?.(null);
				} else {
					setValue([
						{
							label: value[0].value,
							value: value[0].value,
						},
					]);
					props.onChange?.(value[0].value);
				}
			}}
			placeholder="Select industries"
			fetchOptions={fetchIndustries}
			style={{
				width: '100%',
			}}
			{...(isMaxValues && {
				open: false,
			})}
		/>
	);
}

export default IndustrySelect;

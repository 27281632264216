import {
	BookOpen, // SB Advisor - represents knowledge base/documentation
	FileImage,
	FileText,
	FormInput, // Surveys - better represents poll creation
	GalleryHorizontalEnd, // Images - represents image gallery/collection
	Globe2, // Translations - modern globe icon for i18n
	ImageIcon,
	ImagesIcon,
	Library, // Icons - represents icon collection/database
	LineChart, // Analytics - specific for usage trends
	LogOut,
	MessageSquarePlus, // FDU Requests - represents user suggestions/feedback
	Package, // Instances - represents embed products/packages
	ShieldPlus, // Roles - represents role management
	SlidersHorizontal, // Settings - represents adjustable configurations
	Workflow, // Implementation - better represents project setup/configuration flow
} from 'lucide-react';

import { Avatar, Menu, type MenuProps } from 'antd';
import React from 'react';
import { useAuth } from 'react-oidc-context';
import { NavLink } from 'react-router';

function CSTMenu() {
	const auth = useAuth();
	const isBeta = window.location.hostname === 'hub-beta.sizeup.com' || window.location.hostname === 'localhost';

	const items: MenuProps['items'] = [
		auth?.user?.profile?.role === 'sizeup_admin' || auth?.user?.profile?.role === 'customer_admin'
			? {
					key: 'implementation',
					icon: <Workflow size={20} />,
					label: <NavLink to="implementation"> Implementation </NavLink>,
				}
			: null,
		auth?.user?.profile?.role === 'sizeup_admin'
			? {
					key: 'customers',
					icon: <Package size={20} />,
					label: <NavLink to="all"> Instances </NavLink>,
				}
			: null,
		auth?.user?.profile?.role === 'sizeup_admin' || auth?.user?.profile?.role === 'customer_admin'
			? {
					key: 'analytics',
					label: <NavLink to="analytics"> Analytics </NavLink>,
					icon: <LineChart size={20} />,
				}
			: null,
		auth?.user?.profile?.role === 'sizeup_admin' || auth?.user?.profile?.role === 'customer_admin'
			? {
					key: 'businessrequests',
					label: <NavLink to="businessrequests"> FDU Requests </NavLink>,
					icon: <MessageSquarePlus size={20} />,
				}
			: null,
		auth?.user?.profile?.role === 'sizeup_admin'
			? {
					key: 'surveys',
					label: <NavLink to="surveys"> Surveys </NavLink>,
					icon: <FormInput size={20} />,
				}
			: null,
		auth?.user?.profile?.role === 'sizeup_admin'
			? {
					type: 'group',
					label: 'Manage',
					children: [
						{
							key: 'icons',
							label: <NavLink to="manage/icons"> Icons </NavLink>,
							icon: <ImageIcon size={20} />,
						},
						{
							key: 'images',
							label: <NavLink to="manage/images"> Images </NavLink>,
							icon: <ImagesIcon size={20} />,
						},
						{
							key: 'roles',
							label: <NavLink to="manage/roles"> Roles </NavLink>,
							icon: <ShieldPlus size={20} />,
						},
						{
							key: 'settings',
							label: <NavLink to="manage/settings"> Settings </NavLink>,
							icon: <SlidersHorizontal size={20} />,
						},
						{
							key: 'entrepreneur',
							label: <NavLink to="manage/sbadvisor"> SBA Content </NavLink>,
							icon: <FileText size={20} />,
						},
						{
							key: 'translations',
							label: <NavLink to="manage/translations"> Translations </NavLink>,
							icon: <Globe2 size={20} />,
						},
					],
				}
			: null,
		{
			type: 'group',
			label: auth?.user?.profile?.name || 'Account',
			children: [
				{
					key: 'profile',
					label: <NavLink to="profile"> Profile </NavLink>,
					icon: (
						<Avatar
							src={
								auth?.user?.profile?.picture ||
								`https://ui-avatars.com/api/?name=${auth?.user?.profile?.name}`
							}
							alt="profile"
							size={32}
							shape="square"
						/>
					),
				},
				{
					key: 'logout',
					label: <NavLink to="logout"> Sign Out </NavLink>,
					icon: <LogOut size={20} />,
				},
			],
		},
	];

	return <Menu theme="light" mode="inline" items={items} defaultActiveFirst />;
}

export default CSTMenu;

import { Button, Col, Row, Select, type SelectProps, Space, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';

import DataService from '../../data.service';
import CitySelectMultiple from './CitySelectMultiple';
import SmartSetting from './SmartSetting';

import { ExternalLink, Eye } from 'lucide-react';
import type { ImageResourceModel } from '../../api';
import type { CustomerSettingsModelPlus } from '../../types/CustomerSettingsModelPlus';

const { Text } = Typography;

interface Image {
	creatorId: string;
	dateCreated: string;
	fileType: string;
	id: string;
	sourceUrl: string;
	thumbnailUrl: string;
	title: string;
}

function getCode(apiKey) {
	return `<script src="https://shoplocal.sizeup.com/bundle.js" data-key="${apiKey}"></script>`;
}

interface DebounceSelectProps {
	fetchOptions: () => Promise<any[]>;
}

function DebounceSelect({ fetchOptions, ...props }: DebounceSelectProps & SelectProps<any>) {
	const [fetching, setFetching] = useState(false);
	const [options, setOptions] = useState<any[]>([]);

	useEffect(() => {
		setOptions([]);
		setFetching(true);

		fetchOptions().then((newOptions) => {
			setOptions(newOptions);
			setFetching(false);
		});
	}, [fetchOptions]);

	return (
		<Select
			mode="multiple"
			labelInValue
			notFoundContent={fetching ? <Spin size="small" /> : null}
			{...props}
			options={options}
			filterOption={(input, option: any) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
		/>
	);
}

async function fetchIndustries() {
	return fetch('./industries.json')
		.then((response) => response.json())
		.then((industries) => industries.map(({ Name: label, SEOKey: value }) => ({ label, value })));
}

export default function ShopLocal({ data, isAdmin = false }: { data: CustomerSettingsModelPlus; isAdmin?: boolean }) {
	const auth = useAuth();

	const [images, setImages] = useState<ImageResourceModel[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		DataService.images
			.getAll({
				token: auth.user?.access_token,
			})
			.then((data) => {
				setImages(data);
				setIsLoading(false);
			});
	}, [auth.user?.access_token]);

	return (
		<div>
			{isAdmin ? (
				<Row gutter={[8, 0]} wrap={false}>
					<Col flex="auto">
						<Text copyable={{ text: getCode(data.apiKey) }}>Integration Code: </Text>
					</Col>
					<Col>
						{data.apiKey ? (
							<Space.Compact>
								<Button
									type="link"
									icon={<Eye size={16} />}
									onClick={() =>
										window.Sizeup.initPopupWidget({ product: 'shoplocal', key: data.apiKey || '' })
									}
								>
									Inline Preview
								</Button>
								<Button
									type="link"
									icon={<ExternalLink size={16} />}
									onClick={() =>
										window.open(
											`https://${data.apiKey}.instance.sizeup.com/shoplocal.html`,
											'_blank',
										)
									}
								>
									Hosted Instance
								</Button>
								<Button
									type="link"
									icon={<ExternalLink size={16} />}
									onClick={() =>
										window.open(
											`https://shoplocal.sizeup.com/preview.html?apiKey=${data.apiKey}`,
											'_blank',
										)
									}
								>
									External Preview
								</Button>
								<Button
									type="link"
									icon={<ExternalLink size={16} />}
									onClick={() =>
										window.open(
											`https://shoplocal-beta.sizeup.com/preview.html?apiKey=${data.apiKey}`,
											'_blank',
										)
									}
								>
									(Beta)
								</Button>
							</Space.Compact>
						) : null}
					</Col>
				</Row>
			) : null}
			{isAdmin ? (
				<pre style={{ background: 'white', padding: '1rem', fontSize: 'smaller' }}>{getCode(data.apiKey)}</pre>
			) : null}
			{data.settings?.map((setting, index) =>
				setting.groupTag === 'ShopLocal UI' ? (
					<SmartSetting
						setting={setting}
						index={index}
						key={setting.keyName}
						images={images}
						isLoading={isLoading}
						customElements={{
							'shoplocalHintIndustries[]': (
								<DebounceSelect
									mode="multiple"
									placeholder="Select industries"
									fetchOptions={fetchIndustries}
									style={{
										width: '100%',
									}}
								/>
							),
							'shoplocalAreas[]': <CitySelectMultiple />,
						}}
					/>
				) : null,
			)}
		</div>
	);
}

import {
	Bar,
	type BarConfig,
	Column,
	Gauge,
	type GaugeConfig,
	Line,
	type LineConfig,
	Pie,
	type PieConfig,
} from '@ant-design/plots';
import { Spin } from 'antd';
import React, { useMemo } from 'react';

function LineChart({
	color,
	data,
	loading,
	integersOnly = false,
}: {
	color: string;
	data: Array<{
		label: string;
		fullLabel: string;
		value: number;
	}>;
	loading: boolean;
	integersOnly?: boolean;
}) {
	const config: LineConfig = {
		data,
		height: 256,
		xField: 'label',
		yField: 'value',
		point: {
			// sizeField: 4,
			style: {
				fill: 'white',
				stroke: color,
			},
		},
		interaction: {
			tooltip: {
				marker: false,
			},
		},
		style: {
			lineWidth: 2,
			stroke: color,
			width: '100%',
			// stroke: color
		},
		axis: {
			// rotate xaxis 45 degrees
			x: { labelTransform: 'rotate(-45)' },
			y: {
				tickFilter: (v) => (integersOnly ? Number.isInteger(v) : true),
			},
		},
		tooltip: {
			title: false,
			items: [
				(d) => ({
					name: d.label,
					value: d.value ? d.value.toLocaleString() : 'N/A',
					marker: { style: { fill: color } },
					color,
				}),
			],
		},
	};

	return (
		<Spin spinning={loading} delay={500}>
			<Line {...config} />
		</Spin>
	);
}

function BarChart({
	color,
	title,
	data,
	loading,
}: {
	color: string;
	title: string;
	data: Array<{
		label: string;
		value: number;
	}>;
	loading: boolean;
}) {
	const config: BarConfig = {
		data,
		height: 256,
		autoFit: true,
		xField: 'label',
		yField: 'value',
		label: {
			style: {
				fill: '#FFFFFF',
				opacity: 0.6,
			},
		},
		axis: {
			// rotate xaxis 45 degrees
			x: { labelTransform: 'rotate(-45)' },
		},
		style: {
			inset: 1,
			radiusTopLeft: 5,
			radiusTopRight: 5,
			fill: color,
		},
		tooltip: {
			title: 'label',
			field: 'value',
			valueFormatter: '~s',
			items: [
				(d) => ({
					name: title,
					value: d.value ? d.value.toLocaleString() : 'N/A',
					marker: { style: { fill: color } },
				}),
			],
		},
	};

	return (
		<Spin spinning={loading} delay={500}>
			<Column {...config} />
		</Spin>
	);
}

function PieChart({
	color,
	data,
	loading,
	legend = true,
}: {
	color: string;
	data: Array<{
		label: string;
		value: number;
	}>;
	loading: boolean;
	legend?: boolean;
}) {
	const sum = data.reduce((acc, { value }) => acc + value, 0);
	const percentages = data.map(({ value }) => (sum ? value / sum : 0));
	const config: PieConfig = {
		data,
		colorField: 'label',
		angleField: 'value',
		innerRadius: 0.4,
		label: {
			text: (a, index, arr) => {
				const percentage = percentages[data.findIndex(({ label }) => label === a.label)];
				const relativePercentage = a.value / arr.reduce((acc, { value }) => acc + value, 0);
				return relativePercentage >= 0.04 ? `${Math.floor(percentage * 10000) / 100}%` : '';
			},
		},
		style: {
			inset: 1,
			radius: 5,
		},
		tooltip: {
			title: false,
			valueFormatter: '~s',
			items: [
				(d, index) => ({
					name: d.label,
					value: d.value ? d.value.toLocaleString() : 'N/A',
				}),
			],
		},
		legend: legend
			? {
					color: {
						position: 'right',
					},
				}
			: false,
	};

	return (
		<Spin spinning={loading} delay={500}>
			<Pie {...config} />
		</Spin>
	);
}

function StackedBarChart({
	color,
	title,
	data,
	loading,
}: {
	color: string;
	title: string;
	data: Array<{
		label: string;
		color: string;
		value: number;
	}>;
	loading: boolean;
}) {
	const config: BarConfig = {
		data,
		height: 400,
		autoFit: true,
		xField: 'label',
		yField: 'value',
		colorField: 'color',
		stack: true,
		legend: false,
	};

	return (
		<Spin spinning={loading} delay={500}>
			<Bar {...config} />
		</Spin>
	);
}

function GaugeChart({ value, loading }: { value: number; loading: boolean }) {
	const config: GaugeConfig = {
		width: 720,
		height: 720,
		autoFit: true,
		data: {
			target: 120,
			total: 400,
			name: 'score',
		},
		legend: false,
	};

	return (
		<Spin spinning={loading} delay={500}>
			<Gauge {...config} />
		</Spin>
	);
}

export { LineChart, BarChart, PieChart, GaugeChart, StackedBarChart };

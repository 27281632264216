import { mapValues } from 'remeda';

import {
	type CreateCustomerDto,
	type Customer,
	type CustomerMinimizedSettingsModel,
	type CustomerSettingsModel,
	CustomersService,
	type LookupModel,
	LookupService,
	OpenAPI,
} from '../api';

export interface CustomerMinimizedSettingsModelPlus
	extends Omit<CustomerMinimizedSettingsModel, 'modules' | 'reports' | 'roles'> {
	modules: Record<string, boolean | null>;
	reports: Record<string, boolean | null>;
	roles: Record<string, boolean>;
}

async function get(id, { token }): Promise<CustomerSettingsModel> {
	OpenAPI.TOKEN = token;
	return CustomersService.getCustomer({ id });
	// console.log('👥 Customers', response);
	// if (response.ok) {
	// 	return await response.json();
	// } else {
	// 	console.error('Customers getOne error', response);
	// 	return Promise.reject(response.status);
	// }
}

async function getList({ token }): Promise<Customer[]> {
	OpenAPI.TOKEN = token;
	return CustomersService.getCustomersBasicInfo();
	// console.log('👥 Customers', response);
	// if (response.ok) {
	// 	return await response.json();
	// } else {
	// 	console.error('Customers getOne error', response);
	// 	return Promise.reject(response.status);
	// }
}

async function add(data: CreateCustomerDto, { token }) {
	OpenAPI.TOKEN = token;
	return CustomersService.createCustomer({ requestBody: data });
}

async function update(data: CustomerSettingsModel, { token }) {
	OpenAPI.TOKEN = token;
	return CustomersService.updateCustomer({ requestBody: data });
}

async function remove(id: number, { token }) {
	OpenAPI.TOKEN = token;
	return CustomersService.removeCustomer({ id });
}

async function getCustomers({ token }): Promise<CustomerMinimizedSettingsModel[]> {
	OpenAPI.TOKEN = token;
	return CustomersService.getCustomers();
}

async function getLookup({ token }): Promise<LookupModel> {
	OpenAPI.TOKEN = token;
	return LookupService.getLookups();
}

async function getAll({ token }): Promise<CustomerMinimizedSettingsModelPlus[]> {
	const [customers, lookup] = await Promise.all([getCustomers({ token }), getLookup({ token })]);

	const { modulesPositionLookup, reportPositionLookup, rolePositionLookup } = lookup;
	console.log('👥 Customers', customers);

	return customers.map((customer) => ({
		...customer,
		modules: modulesPositionLookup
			? mapValues(modulesPositionLookup, (x) => Boolean(x !== null && customer.modules?.[x] === 'T'))
			: {},
		reports: reportPositionLookup
			? mapValues(reportPositionLookup, (x) => Boolean(x !== null && customer.reports?.[x] === 'T'))
			: {},
		roles: rolePositionLookup
			? mapValues(rolePositionLookup, (x) => Boolean(x !== null && customer.roles?.[x] === 'T'))
			: {},
	}));
}

const customersService = {
	get,
	getList,
	add,
	update,
	remove,
	getAll,
};

export default customersService;

import { Form, Input, Modal, Switch } from 'antd';
import React from 'react';

interface CreateFormProps {
	open: boolean;
	onCreate(...args: unknown[]): unknown;
	onCancel(...args: unknown[]): unknown;
}

function CreateForm({ open, onCreate, onCancel }: CreateFormProps) {
	const [form] = Form.useForm();
	return (
		<Modal
			open={open}
			title="Create a new client"
			okText="Create"
			cancelText="Cancel"
			onCancel={onCancel}
			onOk={() => {
				form.validateFields()
					.then((values) => {
						form.resetFields();
						onCreate(values);
					})
					.catch((info) => {
						console.log('Validate Failed:', info);
					});
			}}
		>
			<Form
				form={form}
				layout="vertical"
				name="form_in_modal"
				initialValues={{
					isActive: true,
				}}
			>
				<Form.Item
					name="name"
					label="Name"
					rules={[
						{
							required: true,
							message: 'Please input the name',
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item name="userName" label="UserName">
					<Input />
				</Form.Item>
				<Form.Item name="isActive" valuePropName="checked">
					<Switch checkedChildren="Active" unCheckedChildren="Inactive" />
				</Form.Item>
			</Form>
		</Modal>
	);
}

export default CreateForm;

import { Button, Col, Row, Switch, Tooltip } from 'antd';

import { FileSpreadsheet, Info, PencilLine } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router';
import PageHeader from '../../components/layout/PageHeader';

function getCode(apiKey, isBetaAPI = false, isBetaWEB = false) {
	const host = isBetaWEB ? 'next' : 'widget';
	return isBetaAPI
		? `<div class="sizeup-widget" data-prop-api-key="${apiKey}" data-prop-custom-api="https://api.beta.sizeup.com/"></div>\n<script src="https://${host}.sizeup.com/bundle.js"></script>`
		: `<script src="https://${host}.sizeup.com/bundle.js" data-key="${apiKey}"></script>`;
}

export function Preview() {
	const params = useParams();

	const [isBetaAPI, setIsBetaAPI] = useState(false);
	const [isBetaWEB, setIsBetaWEB] = useState(false);

	// const [isFramed, setIsFramed] = useState(false);
	useEffect(() => {
		// if (isFramed){
		//   return false;
		// }
		const script = document.createElement('script');

		script.src = `https://${isBetaWEB ? 'next' : 'widget'}.sizeup.com/bundle.js`;
		script.defer = true;
		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, [isBetaAPI, isBetaWEB]);

	// useEffect(() => {
	//   if (isFramed) {
	//     window.iFrameResize({}, "#sizeup-widget");
	//   }
	// }, [isFramed]);

	const [size] = useState('100%');

	// const sizes = [
	//   { label: "iPhone X", value: "375px" },
	//   { label: "iPad", value: "768px" },
	//   { label: "Desktop", value: "1280px" },
	//   { label: "FullScreen", value: "100%" },
	// ];

	const apiKey = params.id;

	return (
		<div style={{ textAlign: 'left' }}>
			<PageHeader
				title="Preview Instance"
				subTitle={apiKey}
				extra={[
					<NavLink to={`/preview/${apiKey}`} key={1}>
						<Button icon={<PencilLine />}>Edit</Button>
					</NavLink>,
				]}
			/>
			<div style={{ marginBottom: '.5rem', padding: '0 .5rem' }}>
				<Row justify="space-between" align="middle" wrap={false}>
					<Col style={{ width: 'calc(100% - 240px)' }}>
						{/* <Row> */}
						{/* <Text copyable={{ text: getCode(apiKey, isBetaAPI) }}>Integration Code: </Text> */}

						<pre>{getCode(apiKey, isBetaAPI, isBetaWEB)}</pre>

						{/* </Row> */}
					</Col>
					<Col offset={1}>
						<div>
							<Switch
								checked={isBetaAPI}
								onChange={() => {
									// if (!isBetaAPI) {
									//   setIsFramed(false);
									// }
									setIsBetaAPI(!isBetaAPI);
								}}
								style={{ marginRight: '.25rem' }}
							/>
							use Beta API{' '}
							<Tooltip title="Beta version of the data-set">
								<Info size={12} />
							</Tooltip>
						</div>
						<div>
							<Switch
								checked={isBetaWEB}
								onChange={() => {
									// if (!isBetaWEB) {
									//   setIsFramed(false);
									// }
									setIsBetaWEB(!isBetaWEB);
								}}
								style={{ marginRight: '.25rem' }}
							/>
							use Staging Version{' '}
							<Tooltip title="Latest Features">
								<Info size={12} />
							</Tooltip>
						</div>
						{/* <div>
              <Switch
                checked={isFramed}
                onChange={() => {
                  if (!isFramed) {
                    setIsBetaWEB(false);
                    setIsBetaAPI(false);
                  }
                  setIsFramed(!isFramed);
                }}
                style={{ marginRight: ".25rem" }}
              />
              use Framed Version{" "}
              <Tooltip title="Isolated Widget Version">
                <Info size={12} />
              </Tooltip>
            </div> */}
					</Col>
				</Row>
			</div>

			{isBetaWEB ? (
				isBetaAPI ? (
					<div
						className="sizeup-widget preview2"
						style={{ maxWidth: size, overflowX: 'scroll', margin: '0 auto' }}
						data-prop-api-key={apiKey}
						data-prop-custom-api="https://api.beta.sizeup.com/"
					/>
				) : (
					<section
						className="sizeup-widget preview3"
						style={{ maxWidth: size, overflowX: 'scroll', margin: '0 auto' }}
						data-prop-api-key={apiKey}
					/>
				)
			) : isBetaAPI ? (
				<article
					className="sizeup-widget preview4"
					style={{ maxWidth: size, overflowX: 'scroll', margin: '0 auto' }}
					data-prop-api-key={apiKey}
					data-prop-custom-api="https://api.beta.sizeup.com/"
				/>
			) : (
				<aside
					className="sizeup-widget preview5"
					style={{ maxWidth: size, overflowX: 'scroll', margin: '0 auto' }}
					data-prop-api-key={apiKey}
				/>
			)}
		</div>
	);
}

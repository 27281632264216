import { Select, type SelectProps, Spin } from 'antd';

import React, { useEffect, useMemo, useState } from 'react';
import Editor from 'react-simple-code-editor';

import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-markup';

function CodeField(props: {
	value?: string;
	onChange?(...args: unknown[]): unknown;
	disabled: boolean;
	placeholder: string;
}) {
	return (
		<Editor
			value={props.value ?? ''}
			onValueChange={(value) => props.onChange?.(value)}
			highlight={(code) => highlight(code, languages.markup)}
			padding={10}
			disabled={props.disabled}
			placeholder={props.placeholder}
			style={{
				fontFamily: 'monospace',
				fontSize: 12,
				border: '1px solid #ccc',
				borderRadius: 4,
				backgroundColor: props.disabled ? '#f9f9f9' : 'white',
				filter: props.disabled ? 'grayscale(1)' : 'none',
			}}
		/>
	);
}

export default CodeField;

import { Button } from 'antd';
import { LogIn, LogOut } from 'lucide-react';
import React, { useCallback } from 'react';
import toast from 'react-hot-toast';
import { useAuth } from 'react-oidc-context';

export function Auth() {
	const auth = useAuth();

	const login = useCallback(() => {
		const toastId = toast.loading('Authenticating...');
		auth.signinPopup()
			.then(() => {
				toast.success('You are authenticated', {
					id: toastId,
				});
			})
			.finally(() => {
				toast.dismiss(toastId);
			});
	}, [auth]);

	const logout = useCallback(() => {
		auth.signoutRedirect();
	}, [auth]);

	if (auth?.user) {
		return (
			<Button onClick={logout} icon={<LogOut size={20} />} type="primary">
				Sign Out
			</Button>
		);
	}

	return (
		<Button onClick={login} icon={<LogIn size={20} />} size="large" type="primary">
			Sign In
		</Button>
	);
}

import { Form, Input, Modal, Switch } from 'antd';
import React, { useEffect } from 'react';

import RichEditor from '../../components/RichEditor';

interface PagesEditProps {
	data?: object;
	open: boolean;
	onUpdate(...args: unknown[]): unknown;
	onCancel(...args: unknown[]): unknown;
}

export default function PagesEdit({ data, open, onUpdate, onCancel }: PagesEditProps) {
	const [form] = Form.useForm();

	useEffect(() => {
		form.setFieldsValue(data);
	}, [form, data]);

	return (
		<Modal
			open={open}
			title="Edit Page"
			okText="Edit"
			cancelText="Cancel"
			onCancel={onCancel}
			onOk={() => {
				form.validateFields()
					.then((values) => {
						form.resetFields();

						onUpdate({
							...data,
							...values,
						});
					})
					.catch((info) => {
						console.log('Validate Failed:', info);
					});
			}}
		>
			<Form form={form} layout="vertical" name="e_edit_form">
				<Form.Item
					name="name"
					label="Name"
					rules={[
						{
							required: true,
							message: 'Please input the name',
						},
					]}
				>
					<Input placeholder="Enter a Title" />
				</Form.Item>
				<Form.Item name="description" label="Description">
					<RichEditor />
				</Form.Item>
				<Form.Item name="hasProduct" label="Has Product" valuePropName="checked">
					<Switch />
				</Form.Item>
				<Form.Item name="displayOrder" label="Display Order">
					<Input type="number" />
				</Form.Item>
			</Form>
		</Modal>
	);
}

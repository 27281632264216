import { Form, Input, Modal } from 'antd';
import React from 'react';

interface IconCreateProps {
	open: boolean;
	onCreate(...args: unknown[]): unknown;
	onCancel(...args: unknown[]): unknown;
}

export default function IconCreate({ open, onCreate, onCancel }: IconCreateProps) {
	const [form] = Form.useForm();
	return (
		<Modal
			open={open}
			title="Create a new role"
			okText="Create"
			cancelText="Cancel"
			onCancel={onCancel}
			onOk={() => {
				form.validateFields()
					.then((values) => {
						form.resetFields();
						onCreate(values);
					})
					.catch((info) => {
						console.log('Validate Failed:', info);
					});
			}}
		>
			<Form
				form={form}
				layout="vertical"
				name="icon_create_form"
				initialValues={{
					title: '',
					svgContent: '',
				}}
			>
				<Form.Item
					name="title"
					label="Title"
					rules={[
						{
							required: true,
							message: 'Please input the title',
						},
					]}
				>
					<Input placeholder="Enter icon title" />
				</Form.Item>
				<Form.Item name="svgContent" label="SVG">
					<Input.TextArea rows={10} />
				</Form.Item>
			</Form>
		</Modal>
	);
}

import { Button, Col, Form, Input, Popconfirm, Radio, Row, Select, Switch, Typography } from 'antd';
import React, { Suspense, lazy } from 'react';
import { startCase } from '../../utils';

import { BookMinus, Trash, Trash2 } from 'lucide-react';
import type { CustomerSettingsModelPlus } from '../../types/CustomerSettingsModelPlus';
import AreasSelect from './AreasSelect';
import SmartSetting from './SmartSetting';
// import Map from './Map';
const CustomerMap = lazy(() => import('./CustomerMap'));

const { Paragraph } = Typography;

interface CustomerGlobalProps {
	data: CustomerSettingsModelPlus;
	isAdmin?: boolean;
	onDelete(...args: unknown[]): unknown;
}

export default function CustomerGlobal({ data, isAdmin, onDelete }: CustomerGlobalProps) {
	return (
		<div>
			<Row gutter={[8, 0]} wrap={false}>
				<Col flex="auto">
					<Form.Item label="ApiKey" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} labelAlign="left">
						<Paragraph copyable={{ text: data?.apiKey }}>{data?.apiKey}</Paragraph>
					</Form.Item>
				</Col>
				{isAdmin ? (
					<Col>
						<Form.Item name="isActive" valuePropName="checked">
							<Switch checkedChildren="Active" unCheckedChildren="Inactive" />
						</Form.Item>
					</Col>
				) : null}
				{isAdmin ? (
					<Col>
						<Popconfirm
							title="Are you sure delete this entry?"
							onConfirm={() => onDelete(data.id)}
							okText="Delete"
							cancelText="Cancel"
						>
							<Button type="primary" danger size="small" style={{ marginLeft: '5px', marginTop: '5px' }}>
								<BookMinus size={16} />
							</Button>
						</Popconfirm>
					</Col>
				) : null}
			</Row>

			<Form.Item
				label="Standardized Name"
				name="name"
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}
				labelAlign="left"
				rules={[
					{
						required: true,
						message: "Please input customer's standardized name!",
					},
				]}
			>
				<Input disabled={!isAdmin} />
			</Form.Item>

			<Form.Item
				label="Marketing Name"
				name="displayName"
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}
				labelAlign="left"
			>
				<Input disabled={!isAdmin} />
			</Form.Item>

			{data.settings?.map((setting, index) =>
				setting.groupTag === 'Language' ? (
					<Form.Item
						key={setting.keyName}
						name={['settings', index, 'value']}
						labelCol={{ span: 4 }}
						wrapperCol={{ span: 20 }}
						labelAlign="left"
						label={startCase(setting.displayName || '')}
						tooltip={setting.description}
					>
						<Radio.Group buttonStyle="solid">
							<Radio.Button value="false">Disabled</Radio.Button>
							<Radio.Button value="true">Active</Radio.Button>
						</Radio.Group>
					</Form.Item>
				) : null,
			)}

			<Form.Item
				label="Custom Domain"
				name="cName"
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}
				labelAlign="left"
			>
				<Input disabled={!isAdmin} />
			</Form.Item>

			<Form.Item
				label="Areas"
				name="serviceAreas"
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}
				labelAlign="left"
			>
				<AreasSelect disabled={!isAdmin} />
			</Form.Item>
			<div>
				{data.serviceAreaMBR ? (
					<Suspense fallback={<div>Loading...</div>}>
						<CustomerMap wkt={data.serviceAreaMBR} apiKey={data.apiKey} />
					</Suspense>
				) : null}
			</div>

			{data.settings?.map((setting, index) =>
				[
					'Language',
					'Widget UI',
					'Widget Data Placeholders',
					'ShopLocal UI',
					'Entrepreneur UI',
					'Pulse UI',
					'PDF',
					'Reports',
					'Meta',
					'Calculators',
					'DELETEME',
				].indexOf(setting.groupTag || '') === -1 ? (
					<SmartSetting
						setting={setting}
						index={index}
						key={setting.keyName}
						customElements={{
							'allowedLanguages[]': (
								<Select
									mode="tags"
									allowClear
									style={{ width: '100%' }}
									placeholder="Please select available languages"
								/>
							),
						}}
					/>
				) : null,
			)}

			{data.settings?.map((setting, index) =>
				setting.groupTag === 'Widget Data Placeholders' ? (
					<SmartSetting setting={setting} index={index} key={setting.keyName} />
				) : null,
			)}

			{data.settings?.map((setting, index) =>
				setting.groupTag === 'Calculators' ? (
					<SmartSetting setting={setting} index={index} key={setting.keyName} />
				) : null,
			)}
		</div>
	);
}

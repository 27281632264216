import { Form, Input, Modal, Switch } from 'antd';
import React from 'react';

import RichEditor from '../../components/RichEditor';

interface PagesCreateProps {
	open: boolean;
	onCreate(...args: unknown[]): unknown;
	onCancel(...args: unknown[]): unknown;
}

export default function PagesCreate({ open, onCreate, onCancel }: PagesCreateProps) {
	const [form] = Form.useForm();
	return (
		<Modal
			open={open}
			title="Create a new page"
			okText="Create"
			cancelText="Cancel"
			onCancel={onCancel}
			onOk={() => {
				form.validateFields()
					.then((values) => {
						form.resetFields();
						onCreate(values);
					})
					.catch((info) => {
						console.log('Validate Failed:', info);
					});
			}}
		>
			<Form
				form={form}
				layout="vertical"
				name="e_create_form"
				initialValues={{
					name: '',
					description: '',
					displayOrder: 100,
				}}
			>
				<Form.Item
					name="name"
					label="Name"
					rules={[
						{
							required: true,
							message: 'Please input the name',
						},
					]}
				>
					<Input placeholder="Enter a Page Title" />
				</Form.Item>

				<Form.Item name="description" label="Description">
					<RichEditor />
				</Form.Item>
				<Form.Item name="hasProduct" label="Has Product" valuePropName="checked">
					<Switch />
				</Form.Item>
				<Form.Item name="displayOrder" label="Display Order">
					<Input type="number" />
				</Form.Item>
			</Form>
		</Modal>
	);
}

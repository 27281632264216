import { Button, Popconfirm, Table, Typography } from 'antd';
import { PencilLine, Trash } from 'lucide-react';
import prettyBytes from 'pretty-bytes';
import React, { useMemo } from 'react';
import SVG from 'react-inlinesvg';

const { Paragraph } = Typography;

interface IconTableProps {
	data?: unknown[];
	onUpdate?(...args: unknown[]): unknown;
	onRemove(...args: unknown[]): unknown;
	onEdit(...args: unknown[]): unknown;
	loading: boolean;
}

export default function IconTable({ data, onEdit, onRemove, loading }: IconTableProps) {
	const columns = useMemo(
		() => [
			{
				title: 'Id',
				dataIndex: 'id',
				key: 'id',
				render: (text) => <Paragraph copyable>{text}</Paragraph>,
			},
			{
				title: 'Title',
				dataIndex: 'title',
				key: 'title',
			},
			{
				title: 'Icon',
				dataIndex: 'svgContent',
				key: 'svgContent',
				render: (text, record) => (
					<SVG src={record.svgContent} style={{ width: '64px', height: '64px', color: '#1677ff' }} />
				),
			},
			{
				title: 'Size',
				dataIndex: 'svgContent',
				key: 'svgContentLength',
				render: (text) => <Paragraph>{prettyBytes(text.length)}</Paragraph>,
			},
			{
				title: 'Actions',
				dataIndex: 'id',
				key: 'actions',
				render: (text, record) => (
					<div>
						<Button onClick={() => onEdit(record)} icon={<PencilLine size={16} />} type="link" />
						<Popconfirm title="Sure to delete?" onConfirm={() => onRemove(record)}>
							<Button icon={<Trash size={16} />} type="link" danger />
						</Popconfirm>
					</div>
				),
			},
		],
		[onEdit, onRemove],
	);

	return (
		<div>
			<Table columns={columns} dataSource={data} loading={loading} rowKey="id" pagination={false} />
		</div>
	);
}

import {
	Button,
	Card,
	Col,
	DatePicker,
	Flex,
	List,
	Row,
	Select,
	type SelectProps,
	Space,
	Switch,
	type TimeRangePickerProps,
	Typography,
} from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useParams } from 'react-router';

import { download, generateCsv, mkConfig } from 'export-to-csv';
import type { QuestionModel, ResponseModel, SurveyModel } from '../../api';
import DataService from '../../data.service';

import { FileSpreadsheet } from 'lucide-react';
import { filter } from 'remeda';
import customersService from '../../service/customers.service';
import { countElementAppearances } from '../../utils';

const { Text } = Typography;

const { RangePicker } = DatePicker;

interface QuestionModelPlus extends QuestionModel {
	answers: [string, number][];
}

interface SurveyModelPlus extends SurveyModel {
	questions: QuestionModelPlus[];
	responses: Array<{
		id?: number;
		customerId?: number;
		apiKey?: string | null;
		surveyId?: number;
		userId?: string | null;
		answers: Array<{
			question: QuestionModel | null;
			answer: string;
		}>;
	}>;
}

const disabledDate: RangePickerProps['disabledDate'] = (current) =>
	// allow dates from 1/1/202 till today
	current && (current > dayjs().endOf('day') || current < dayjs('2022-01-01').startOf('day'));

const qDates = [
	// prev quearter
	dayjs().subtract(4, 'quarter'),
	dayjs().subtract(3, 'quarter'),
	dayjs().subtract(2, 'quarter'),
	dayjs().subtract(1, 'quarter'),
];

const rangePresets: TimeRangePickerProps['presets'] = [
	{ label: 'Last 30 days', value: [dayjs().add(-30, 'd'), dayjs()] },
	{ label: 'This Week', value: [dayjs().startOf('week'), dayjs()] },
	{ label: 'Last Week', value: [dayjs().add(-1, 'week').startOf('week'), dayjs().add(-1, 'week').endOf('week')] },
	{ label: 'This Month', value: [dayjs().startOf('month'), dayjs()] },
	{
		label: 'Last Month',
		value: [dayjs().add(-1, 'month').startOf('month'), dayjs().add(-1, 'month').endOf('month')],
	},
	{
		label: `Q${qDates[0].quarter()} ${qDates[0].year()}`,
		value: [qDates[0].startOf('quarter'), qDates[0].endOf('quarter')],
	},
	{
		label: `Q${qDates[1].quarter()} ${qDates[1].year()}`,
		value: [qDates[1].startOf('quarter'), qDates[1].endOf('quarter')],
	},
	{
		label: `Q${qDates[2].quarter()} ${qDates[2].year()}`,
		value: [qDates[2].startOf('quarter'), qDates[2].endOf('quarter')],
	},
	{
		label: `Q${qDates[3].quarter()} ${qDates[3].year()}`,
		value: [qDates[3].startOf('quarter'), qDates[3].endOf('quarter')],
	},
	{ label: 'This Year', value: [dayjs().startOf('year'), dayjs()] },
];

const QUESTION_TYPES = [
	'No Answers', // 0000 = 0
	'Image', // 0001 = 1
	'Text', // 0010 = 2
	'Short Text', // 0011 = 3
	'Yes/No', // 0100 = 4
	'Yes/No + Yes Text', // 0101 = 5
	'Yes/No + No Text', // 0110 = 6
	'Yes/No + Always Text', // 0111 = 7
	null,
	'Date Select', // 1001 = 9
	'Time Select', // 1010 = 10
	'Year Select', // 1011 = 11
	'Email', // 1100 = 12
	'Phone', // 1101 = 13
	'Number', // 1110 = 14
	null,
	'Custom Options', // 10000 = 16
	'Custom Options + Text', // 10001 = 17
	'Custom Options + Multiple', // 10010 = 18
];

const sorters = {
	year: ([a1, r1], [a2, r2]) => a2 - a1,
	yesno: ([a1, r1], [a2, r2]) => {
		const a1Lower = a1.toLowerCase();
		const a2Lower = a2.toLowerCase();

		const a1Priority = a1Lower.startsWith('yes') ? 1 : a1Lower.startsWith('no') ? 2 : 3;
		const a2Priority = a2Lower.startsWith('yes') ? 1 : a2Lower.startsWith('no') ? 2 : 3;

		if (a1Priority === a2Priority) {
			return r2 - r1;
		}
		return a1Priority - a2Priority;
	},
	default: ([a1, r1], [a2, r2]) => r2 - r1,
};

function Results({ defaultApiKeys = [] }: { defaultApiKeys?: string[] }) {
	const auth = useAuth();
	const token = auth.user?.access_token;
	const params = useParams();

	const [data, setData] = useState<ResponseModel[] | null>(null);
	const [survey, setSurvey] = useState<SurveyModelPlus | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [showAggregated, setShowAggregated] = useState(true);

	console.log('survey', survey);

	const [options, setOptions] = React.useState<SelectProps['options']>([]);
	const [isSingleInstance, setIsSingleInstance] = React.useState(false);

	const [loadingOptions, setLoadingOptions] = React.useState<boolean>(false);
	const [startDate, setStartDate] = React.useState<dayjs.Dayjs>(dayjs().startOf('year'));
	const [endDate, setEndDate] = React.useState<dayjs.Dayjs>(dayjs());

	const [authFilter, setAuthFilter] = React.useState<boolean>(true);
	const [guestFilter, setGuestFilter] = React.useState<boolean>(true);

	const [tempKeys, setTempKeys] = React.useState(defaultApiKeys);
	const [tempIds, setTempIds] = React.useState<string[]>([]);

	const [apiKeys, setApiKeys] = React.useState(defaultApiKeys);
	const [customerIds, setCustomerIds] = React.useState<string[]>([]);

	const [open, setOpen] = useState(false);
	const onBlur = useCallback(() => {
		setCustomerIds(tempIds);
	}, [tempIds]);

	const handleChange = (value: string[]) => {
		setTempIds(value);
		if (!open) {
			setCustomerIds(value);
		}
	};

	const exportCSV = useCallback(() => {
		const csvConfig = mkConfig({
			useKeysAsHeaders: false,
			columnHeaders: [
				{ key: 'question', displayLabel: 'QUESTION' },
				{ key: 'answer', displayLabel: 'ANSWER' },
				{ key: 'responses', displayLabel: 'NUMBER OF RESPONSES' },
			],
			showColumnHeaders: true,
			title: `Survey: ${survey?.name} (${data?.length} responses)`,
			showTitle: true,
			filename: `survey-${params.id}_${dayjs(startDate).format('MMM_DD_YYYY')}-${dayjs(endDate).format('MMM_DD_YYYY')}.csv`,
		});
		const csvData: any = [];
		csvData.push({});
		survey?.questions.forEach((question, questionIndex) => {
			question.answers.forEach(([answer, numberOfResponses], index) => {
				csvData.push({
					question: index === 0 ? question.text : '',
					answer: answer.trim(),
					responses: numberOfResponses,
				});
			});
			if (question.answers.length > 0 && questionIndex < survey.questions.length - 1) {
				csvData.push({});
			}
		});
		const csv = generateCsv(csvConfig)(csvData);

		return download(csvConfig)(csv);
	}, [survey, startDate, endDate, data, params.id]);

	const exportResponsesCSV = useCallback(() => {
		const questionsFiltered = survey?.questions.filter((q) => q.answers.length > 0) || [];
		const csvConfig = mkConfig({
			useKeysAsHeaders: false,
			columnHeaders: [
				{
					key: 'id',
					displayLabel: 'RESPONSE ID',
				},
				{
					key: 'userId',
					displayLabel: 'USER ID',
				},
				{
					key: 'customerId',
					displayLabel: 'CUSTOMER ID',
				},
				{
					key: 'apiKey',
					displayLabel: 'API KEY',
				},
			].concat(questionsFiltered.map((q) => ({ key: `q${q.id}`, displayLabel: q.text }))),
			showColumnHeaders: true,
			title: `Survey: ${survey?.name} (${data?.length} responses)`,
			showTitle: true,
			filename: `survey-responses-${params.id}_${dayjs(startDate).format('MMM_DD_YYYY')}-${dayjs(endDate).format('MMM_DD_YYYY')}.csv`,
		});
		const csvData: any = [];
		csvData.push({});
		survey?.responses.forEach((response, responseIndex) => {
			const csvRow = {
				id: response.id?.toString() ?? '',
				userId: response.userId ?? '',
				customerId: response.customerId?.toString() ?? '',
				apiKey: response.apiKey ?? '',
			};
			for (const question of questionsFiltered) {
				csvRow[`q${question.id}`] = response.answers.find((a) => a.question?.id === question.id)?.answer ?? '-';
			}
			csvData.push(csvRow);
		});

		const csv = generateCsv(csvConfig)(csvData);

		return download(csvConfig)(csv);
	}, [survey, startDate, endDate, data, params.id]);

	useEffect(() => {
		setLoadingOptions(true);
		customersService
			.getList({ token: auth.user?.access_token })
			.then((response) => {
				setOptions(
					response
						.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))
						.map((x) => ({
							label: x.name,
							value: x.id?.toString(),
						})),
				);
				console.log('response', response);
				setIsSingleInstance(response.length === 1);
				setLoadingOptions(false);
			})
			.catch((error) => {
				console.error('Error fetching customer list', error);
				setLoadingOptions(false);
			});
	}, [auth.user?.access_token]);

	useEffect(() => {
		async function getResults(customerIds, surveyId, authOnly) {
			const surveyIds: number[] = [];
			if (surveyId && surveyId !== '0') {
				surveyIds.push(surveyId);
			}
			return DataService.surveys.getResults(
				{
					allowedApiKeys: [],
					surveyIds,
					customerIds,
					dateFrom: startDate?.format('YYYY-MM-DD'),
					dateTo: endDate?.format('YYYY-MM-DD'),
					authOnly,
				},
				{
					token,
				},
			);
		}

		async function getSurvey(surveyId) {
			return DataService.surveys.get(surveyId);
		}

		if (customerIds) {
			setIsLoading(true);
			let authOnly: boolean | undefined;
			if (authFilter && !guestFilter) {
				authOnly = true;
			}
			if (!authFilter && guestFilter) {
				authOnly = false;
			}
			Promise.all([getResults(customerIds, params.id, authOnly), getSurvey(params.id)])
				.then(([results, survey]) => {
					const filteredResults = results.filter((result) => result.surveyId === survey.id);
					setIsLoading(false);
					setData(filteredResults);
					setSurvey({
						...survey,
						questions: (survey.questions ?? [])
							.sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
							.map((question) => ({
								...question,
								text: question.text ?? '',
								answers: countElementAppearances(
									filteredResults
										.flatMap((r) => r.answers)
										.filter((answer) => answer?.questionId === question.id)
										.map((answer) => answer?.value ?? '')
										.concat(question.options || [])
										.filter(Boolean),
								)
									.sort(
										question.type === 11
											? sorters.year
											: [5, 6, 7].includes(question.type)
												? sorters.yesno
												: sorters.default,
									)
									.map(([answer, numberOfResponses]) => {
										if (question.options?.includes(answer)) {
											return [answer, numberOfResponses - 1];
										}
										return [answer, numberOfResponses || 0];
									}),
							})),
						responses: filteredResults
							.filter((result) => result.answers?.some((x) => x.value))
							.sort((a, b) => (b.id || 0) - (a.id || 0))
							.map((result) => ({
								userId: result.userId,
								customerId: result.customerId || 0,
								surveyId: result.surveyId || 0,
								apiKey: result.apiKey || '',
								id: result.id || 0,
								answers: (result.answers || [])
									.filter((answer) => answer.questionId && answer.questionId > 0)
									.map((answer) => ({
										question: survey.questions?.find((q) => q.id === answer.questionId) || null,
										answer: answer.value || '',
									})),
							})),
					});
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	}, [customerIds, token, params.id, startDate, endDate, authFilter, guestFilter]);

	return (
		<div style={{ margin: '1rem' }}>
			{/* <PageHeader title={`Survey #${params.id} Results`} /> */}
			<Row gutter={[16, 16]} style={{ marginBottom: '1rem' }} justify="space-between">
				<Col xs={20} sm={20} md={12} lg={12} xl={12}>
					<Flex justify="space-between" gap="1rem" align="center">
						<Select
							loading={loadingOptions}
							mode="multiple"
							style={{ width: '100%' }}
							disabled={isSingleInstance}
							allowClear
							placeholder={
								loadingOptions
									? 'Loading...'
									: isSingleInstance
										? options?.[0].label
										: 'Filter instances'
							}
							defaultValue={[]}
							onChange={handleChange}
							onBlur={onBlur}
							options={options}
							showSearch
							filterOption={(input, option) => {
								const label = option?.label || '';
								return label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
							}}
							onDropdownVisibleChange={(open) => {
								setOpen(open);
							}}
						/>
						<Space.Compact size="small" direction="vertical">
							<Switch
								checkedChildren="Users"
								unCheckedChildren="Users"
								size="small"
								checked={authFilter}
								disabled={authFilter && !guestFilter}
								onChange={(checked) => setAuthFilter(checked)}
							/>
							<Switch
								checkedChildren="Guest"
								unCheckedChildren="Guest"
								size="small"
								checked={guestFilter}
								disabled={guestFilter && !authFilter}
								onChange={(checked) => setGuestFilter(checked)}
								style={{ marginTop: '3px' }}
							/>
						</Space.Compact>
					</Flex>
				</Col>
				<Col>
					<RangePicker
						disabledDate={disabledDate}
						presets={rangePresets}
						format="MM-DD-YYYY"
						allowEmpty={[false, true]}
						defaultValue={[startDate, endDate]}
						onChange={(values) => {
							if (values) {
								setStartDate(values[0] as dayjs.Dayjs);
								setEndDate(values[1] as dayjs.Dayjs);
							}
						}}
					/>
				</Col>
			</Row>
			<div>
				<div>
					<Flex justify="space-between">
						<Typography.Title level={5}>
							Survey: {survey?.name}{' '}
							<Switch
								checkedChildren="Aggregated"
								unCheckedChildren="Individual"
								checked={showAggregated}
								disabled={isLoading}
								onChange={(checked) => setShowAggregated(checked)}
								style={{ marginLeft: '1rem' }}
							/>
						</Typography.Title>

						<Button
							icon={<FileSpreadsheet size={16} />}
							onClick={showAggregated ? exportResponsesCSV : exportCSV}
							loading={isLoading}
						>
							CSV
							{showAggregated ? ' Survey Report' : ' Responses'}
						</Button>
					</Flex>
				</div>
				{showAggregated ? (
					<List
						loading={isLoading}
						dataSource={survey?.questions}
						renderItem={(item: QuestionModelPlus) => (
							<List.Item
								key={item.id}
								style={{ alignItems: 'inherit' }}
								extra={
									<Card title="Answers" style={{ width: '100%', marginLeft: '.5rem' }}>
										<List
											size="small"
											bordered
											dataSource={item.answers || []}
											renderItem={([answer, numberOfResponses], index) => (
												<List.Item key={index} extra={numberOfResponses}>
													{answer ? <Text strong>{answer}</Text> : <i>Empty Response</i>}
												</List.Item>
											)}
										/>
									</Card>
								}
							>
								<Card
									// avatar={<Avatar src={item.avatar} />}
									title="Question"
									style={{ width: '100%' }}
								>
									<Typography.Title level={5}>{item.text}</Typography.Title>
									<kbd>{QUESTION_TYPES[item.type]}</kbd>
								</Card>
							</List.Item>
						)}
					/>
				) : (
					<List
						dataSource={survey?.responses || []}
						loading={isLoading}
						pagination={{ pageSize: 20, showSizeChanger: false }}
						renderItem={(item) => (
							<List.Item>
								<Card
									title={`Answer #${item.id} from ${item.userId ? `user ${item.userId}` : 'anonymous user'}`}
									style={{ width: '100%', marginLeft: '.5rem' }}
								>
									<List
										size="small"
										bordered
										dataSource={item.answers || []}
										renderItem={(answer, index) => (
											<List.Item key={index} extra={answer.answer ?? '␀'}>
												<Text strong>{answer.question?.text || '-'}</Text>
											</List.Item>
										)}
									/>
								</Card>
							</List.Item>
						)}
					/>
				)}
				{isLoading ? null : <div>Total Answers: {data?.length || 0}</div>}
			</div>
		</div>
	);
}

export default Results;

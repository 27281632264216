import { Button, Col, Row, Space, Spin, Transfer, Typography } from 'antd';
import type React from 'react';
import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';

import { ExternalLink, Eye } from 'lucide-react';
import type { ImageResourceModel, TopicModel } from '../../api';
import DataService, { type TopicsModelSimplified } from '../../data.service';
import type { CustomerSettingsModelPlus } from '../../types/CustomerSettingsModelPlus';
import SmartSetting from './SmartSetting';

const { Text } = Typography;

function getCode(apiKey) {
	return `<script src="https://sbadvisor.sizeup.com/bundle.js" data-key="${apiKey}"></script>`;
}

interface SBAdvisorProps {
	data: CustomerSettingsModelPlus;
	isAdmin?: boolean;
}

export default function SBAdvisor({ data, isAdmin }: SBAdvisorProps) {
	const auth = useAuth();

	const [topics, setTopics] = useState<TopicsModelSimplified[]>([]);
	const [images, setImages] = useState<ImageResourceModel[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const disabledTopics = data?.settings?.find((s) => s.keyName === 'entrepreneurDisabledTopics[]')?.value;
	const [targetKeys, setTargetKeys] = useState<React.Key[]>((disabledTopics as string[]) || []);
	const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);

	const handleChange = (nextTargetKeys) => {
		setTargetKeys(nextTargetKeys);
	};

	const handleSelectChange = (sourceSelectedKeys: React.Key[], targetSelectedKeys: React.Key[]) => {
		// this.setState({ selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys] });
		setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
	};

	useEffect(() => {
		setIsLoading(true);

		Promise.all([
			DataService.entrepreneur.topics.getAllLight({
				token: auth.user?.access_token,
			}),
			DataService.images.getAll({
				token: auth.user?.access_token,
			}),
		]).then(([topics, images]) => {
			setTopics(topics);
			setImages(images);
			setIsLoading(false);
		});
	}, [auth.user?.access_token]);

	return (
		<div>
			{isAdmin ? (
				<Row gutter={[8, 0]} wrap={false}>
					<Col flex="auto">
						<Text copyable={{ text: getCode(data.apiKey) }}>Integration Code: </Text>
					</Col>
					<Col>
						{data.apiKey ? (
							<Space.Compact>
								<Button
									type="link"
									icon={<Eye size={16} />}
									onClick={() =>
										window.Sizeup.initPopupWidget({ product: 'sbadvisor', key: data.apiKey || '' })
									}
								>
									Inline Preview
								</Button>
								<Button
									type="link"
									icon={<ExternalLink size={16} />}
									onClick={() =>
										window.open(
											`https://${data.apiKey}.instance.sizeup.com/sbadvisor.html`,
											'_blank',
										)
									}
								>
									Hosted Instance
								</Button>
								<Button
									type="link"
									icon={<ExternalLink size={16} />}
									onClick={() =>
										window.open(
											`https://sbadvisor.sizeup.com/preview.html?apiKey=${data.apiKey}`,
											'_blank',
										)
									}
								>
									External Preview
								</Button>
								<Button
									type="link"
									icon={<ExternalLink size={16} />}
									onClick={() =>
										window.open(
											`https://sbadvisor-beta.sizeup.com/preview.html?apiKey=${data.apiKey}`,
											'_blank',
										)
									}
								>
									(Beta)
								</Button>
							</Space.Compact>
						) : null}
					</Col>
				</Row>
			) : null}
			{isAdmin ? (
				<pre style={{ background: 'white', padding: '1rem', fontSize: 'smaller' }}>{getCode(data.apiKey)}</pre>
			) : null}

			{data.settings?.map((setting, index) =>
				setting.groupTag === 'Entrepreneur UI' ? (
					<SmartSetting
						setting={setting}
						index={index}
						key={setting.keyName}
						images={images}
						isLoading={isLoading}
						customElements={{
							'entrepreneurDisabledTopics[]': isLoading ? (
								<Spin />
							) : (
								<Transfer
									dataSource={topics}
									titles={['Enabled', 'Disabled']}
									targetKeys={targetKeys}
									selectedKeys={selectedKeys}
									onChange={handleChange}
									onSelectChange={handleSelectChange}
									rowKey={(record: any) => record.id}
									render={(item: any) => item.title}
									oneWay
									showSearch
									listStyle={{
										width: 350,
										height: 350,
									}}
								/>
							),
						}}
					/>
				) : null,
			)}
		</div>
	);
}

import { Alert, Button, Card, Col, Row, Space, Statistic, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { FileSpreadsheet, Info } from 'lucide-react';
import React, { useCallback, useMemo } from 'react';
import { startCase } from '../../utils';

import analyticsService from '../../service/analytics.service';
import { BarChart, LineChart, PieChart } from './AnalyticsCharts';
import useAnalytics from './useAnalytics';

function AnalyticsDevices({
	apiKeys,
	startDate,
	endDate,
	token = '',
}: { apiKeys: string[]; startDate: dayjs.Dayjs; endDate: dayjs.Dayjs; token?: string }) {
	const [osTypes, osTypesLoading, osTypesError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getOSTypes,
		token,
		(response) =>
			response.map((x) => ({
				label: x.id,
				fullLabel: startCase(x.id),
				value: x.value,
			})),
	);
	const [browserTypes, browserTypesLoading, browserTypesError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getBrowserTypes,
		token,
		(response) =>
			response.map((x) => ({
				label: x.id,
				fullLabel: startCase(x.id),
				value: x.value,
			})),
	);

	const [languages, languagesLoading, languagesError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getLanguages,
		token,
		(response) =>
			response.map((x) => ({
				label: startCase(x.id),
				fullLabel: startCase(x.id),
				value: x.value,
			})),
	);

	const [devices, devicesLoading, devicesError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getDeviceTypes,
		token,
		(response) => {
			return response.map((x) => ({
				label: startCase(x.id),
				fullLabel: startCase(x.id),
				value: x.value,
			}));
		},
	);

	const download = useCallback(
		(data, name) => {
			const csv = data.map((x) => `${x.label},${x.value}`);
			csv.unshift('Label,Value');
			const blob = new Blob([csv.join('\n')], { type: 'text/csv' });
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = `${name}_${dayjs(startDate).format('MMM_DD_YYYY')}-${dayjs(endDate).format('MMM_DD_YYYY')}.csv`;
			a.click();
		},
		[startDate, endDate],
	);

	if (osTypesError || browserTypesError || languagesError || devicesError) {
		return <Alert message="Error loading data" type="error" />;
	}

	return (
		<Row gutter={[16, 16]}>
			<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
				<Card
					title={
						<Space>
							OS Types
							<Tooltip title="The number you see is the number of  click and input events that a user does while in the software in a specific OS type. This includes events such as a Page change, map zoomed, competitors buyers tab load, advertising load, Pdf downloaded, etc.">
								<Info size={12} />
							</Tooltip>
						</Space>
					}
					extra={
						<Button icon={<FileSpreadsheet size={16} />} onClick={() => download(osTypes, 'OS Types')}>
							CSV
						</Button>
					}
					variant="borderless"
				>
					<PieChart data={osTypes as any} color="red" loading={osTypesLoading as boolean} />
				</Card>
			</Col>
			<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
				<Card
					title={
						<Space>
							Browser Types
							<Tooltip title="The number you see is the number of  click and input events that a user does while in the software in a specific browser type. This includes events such as a Page change, map zoomed, competitors buyers tab load, advertising load, Pdf downloaded, etc.">
								<Info size={12} />
							</Tooltip>
						</Space>
					}
					extra={
						<Button
							icon={<FileSpreadsheet size={16} />}
							onClick={() => download(browserTypes, 'Browser Types')}
						>
							CSV
						</Button>
					}
					variant="borderless"
				>
					<PieChart data={browserTypes as any} color="#e39361" loading={browserTypesLoading as boolean} />
				</Card>
			</Col>
			<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
				<Card
					title={
						<Space>
							Languages
							<Tooltip title="The number you see is the number of languages that a user has selected to view.">
								<Info size={12} />
							</Tooltip>
						</Space>
					}
					extra={
						<Button icon={<FileSpreadsheet size={16} />} onClick={() => download(languages, 'Languages')}>
							CSV
						</Button>
					}
					variant="borderless"
				>
					<PieChart data={languages as any} color="#e39361" loading={languagesLoading as boolean} />
				</Card>
			</Col>
			<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
				<Card
					title={
						<Space>
							Devices
							<Tooltip title="The type of device that a user is using to access the software.">
								<Info size={12} />
							</Tooltip>
						</Space>
					}
					extra={
						<Button icon={<FileSpreadsheet size={16} />} onClick={() => download(devices, 'Devices')}>
							CSV
						</Button>
					}
					variant="borderless"
				>
					<PieChart data={devices as any} color="#e39361" loading={devicesLoading as boolean} />
				</Card>
			</Col>
		</Row>
	);
}

export default AnalyticsDevices;

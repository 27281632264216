import { OpenAPI, type TagDto, type TagModel, TagsEAdminService } from '../api';

async function getAll({ token }): Promise<TagModel[]> {
	OpenAPI.TOKEN = token;
	return TagsEAdminService.getTags({ usedOnly: false });
}

async function add(data: TagDto, { token }): Promise<TagModel> {
	OpenAPI.TOKEN = token;
	return TagsEAdminService.createTag({ requestBody: data });
}

async function update(data: TagModel, { token }): Promise<TagModel> {
	OpenAPI.TOKEN = token;
	return TagsEAdminService.updateTag({ requestBody: data });
}

async function remove(tagId, { token }): Promise<unknown> {
	OpenAPI.TOKEN = token;
	return TagsEAdminService.removeTag({ tagId });
}

const eTagsService = {
	getAll,
	add,
	update,
	remove,
};

export default eTagsService;

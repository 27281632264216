import { map, pipe, prop, sortBy } from 'remeda';

import { OpenAPI, type TopicModel, TopicsEAdminService } from '../api';

export interface TopicsModelSimplified extends Omit<TopicModel, 'pages' | 'tags'> {
	pages: number[];
	tags: number[];
}

async function getAll({ token }): Promise<TopicsModelSimplified[]> {
	OpenAPI.TOKEN = token;
	const data: TopicModel[] = await TopicsEAdminService.getTopics();
	return pipe(
		data,
		map((x) => ({
			...x,
			displayOrder: x.displayOrder || 0,
			id: x.id || 0,
			pages: x.pages?.map((y) => y.id || 0) || [],
			tags: x.tags?.map((y) => y.id || 0) || [],
		})),
		sortBy(prop('displayOrder'), prop('id')),
	);
}

async function getAllLight({ token }): Promise<TopicsModelSimplified[]> {
	OpenAPI.TOKEN = token;
	const data = await TopicsEAdminService.getLight();

	return data.map((x) => ({
		...x,
		pages: x.pages?.map((y) => y.id || 0) || [],
		tags: x.tags?.map((y) => y.id || 0) || [],
	}));
}

async function add(data: TopicModel, { token }): Promise<TopicsModelSimplified> {
	OpenAPI.TOKEN = token;
	const response = await TopicsEAdminService.createTopic({ requestBody: data });

	return {
		...response,
		pages: response.pages?.map((y) => y.id || 0) || [],
		tags: response.tags?.map((y) => y.id || 0) || [],
	};
}

async function update(data: TopicModel, { token }): Promise<TopicsModelSimplified> {
	OpenAPI.TOKEN = token;
	const response = await TopicsEAdminService.updateTopic({ requestBody: data });

	return {
		...response,
		pages: response.pages?.map((y) => y.id || 0) || [],
		tags: response.tags?.map((y) => y.id || 0) || [],
	};
}

async function remove(topicId, { token }) {
	OpenAPI.TOKEN = token;
	return TopicsEAdminService.removeTopic({ topicId });
}

async function updateOrder(data, { token }) {
	OpenAPI.TOKEN = token;
	return TopicsEAdminService.sortTopics(data);
}

const eTopicsService = {
	getAll,
	getAllLight,
	add,
	update,
	remove,
	updateOrder,
};

export default eTopicsService;

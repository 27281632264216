import type dayjs from 'dayjs';
import React, { useEffect } from 'react';

const useAnalytics = <T>(
	apiKeys: string[],
	startDate: dayjs.Dayjs,
	endDate: dayjs.Dayjs,
	source,
	token,
	normalizer: (response: any) => T,
): [T, boolean, string] => {
	const [data, setData] = React.useState<T>([] as unknown as T);
	const [error, setError] = React.useState<string>('');
	const [loading, setLoading] = React.useState<boolean>(false);

	useEffect(() => {
		const controller = new AbortController();
		const { signal } = controller;

		const params: any = {
			dateFrom: startDate?.format('YYYY-MM-DD'),
			dateTo: endDate?.format('YYYY-MM-DD'),
			token,
			signal,
		};

		if (apiKeys) {
			params.apiKeys = apiKeys;
		}

		const getData = async () => {
			setLoading(true);
			setError('');

			try {
				const response = await source(params);
				setData(normalizer(response));
				setLoading(false);
			} catch (error: any) {
				if (error.name === 'AbortError') {
					return;
				}
				setData([] as unknown as T);
				setLoading(false);
				setError(error.message);
			}
		};

		getData();

		return () => controller.abort('Analytics request aborted');
	}, [apiKeys, startDate, endDate, source, token]);

	return [data, loading, error];
};

export default useAnalytics;

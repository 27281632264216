import { Button, Layout, Row } from 'antd';
import React, { useState } from 'react';
import { Outlet } from 'react-router';

import packageInfo from '../../../package.json';
import logo from '../../assets/images/logo-sizeup.svg';

import { ArrowLeftToLine, ArrowRightToLine } from 'lucide-react';
import CSTMenu from './CstMenu';
import CSTVersion from './CstVersion';

const { Content, Sider } = Layout;

function Main() {
	const [collapsed, setCollapsed] = useState(false);

	const customBackendAPI = localStorage.getItem('customApi');

	return (
		<Layout hasSider>
			<Sider
				trigger={null}
				collapsible
				collapsed={collapsed}
				theme="light"
				style={{
					minHeight: '100vh',
					minWidth: '300px',
				}}
			>
				<div className="cst-brand">
					{collapsed ? null : <img src={logo} alt="" />}
					<span>hub</span>
				</div>
				{customBackendAPI ? <div className="cst-custom-api">{customBackendAPI}</div> : null}
				<CSTMenu />
				<CSTVersion />
			</Sider>
			<Layout style={{ maxHeight: '100vh', overflow: 'scroll' }}>
				<Content>
					<Row justify="space-between">
						<Button
							type="text"
							icon={collapsed ? <ArrowRightToLine size={16} /> : <ArrowLeftToLine size={16} />}
							onClick={() => setCollapsed(!collapsed)}
							className="cst-menu-toggler"
							style={collapsed ? { display: 'block' } : {}}
						/>
					</Row>
					<Outlet />
				</Content>
			</Layout>
		</Layout>
	);
}

export default Main;

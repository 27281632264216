import { Select, type SelectProps, Spin, Tag } from 'antd';
import React, { useMemo, useRef, useState } from 'react';
import { debounce } from 'remeda';

import { useAuth } from 'react-oidc-context';
import DataService from '../../data.service';

type CustomTagProps = {
	label: React.ReactNode;
	value: any;
	disabled: boolean;
	onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	closable: boolean;
};

function tagRender(props: CustomTagProps) {
	const { label, closable, onClose } = props;
	const onPreventMouseDown = (event) => {
		event.preventDefault();
		event.stopPropagation();
	};

	return (
		<Tag
			color="#08D"
			onMouseDown={onPreventMouseDown}
			closable={closable}
			onClose={onClose}
			style={{ marginRight: 3 }}
		>
			{label}
		</Tag>
	);
}

interface DebounceSelectProps {
	fetchOptions: (value: string, granularity: number, token?: string) => Promise<any[]>;
	debounceTimeout?: number;
	granularity: number;
	token?: string;
}

function DebounceSelect({
	fetchOptions,
	debounceTimeout = 800,
	granularity,
	token,
	...props
}: DebounceSelectProps & SelectProps<any>) {
	const [fetching, setFetching] = useState(false);
	const [options, setOptions] = useState<any[]>([]);
	const fetchRef = useRef(0);
	const debounceFetcher = useMemo(() => {
		const loadOptions = (value) => {
			if (value === '') {
				return;
			}

			fetchRef.current += 1;
			const fetchId = fetchRef.current;
			setOptions([]);
			setFetching(true);
			fetchOptions(value, granularity, token).then((newOptions) => {
				if (fetchId !== fetchRef.current) {
					// for fetch callback order
					return;
				}

				setOptions(newOptions);
				setFetching(false);
			});
		};

		return debounce(loadOptions, { waitMs: debounceTimeout });
	}, [fetchOptions, granularity, debounceTimeout]);
	return (
		<Select
			labelInValue
			filterOption={false}
			onSearch={debounceFetcher.call}
			tagRender={tagRender}
			notFoundContent={fetching ? <Spin size="small" /> : null}
			{...props}
			options={options}
		/>
	);
} // Usage of DebounceSelect

async function fetchAreaList(areaname, granularity, token) {
	const areas = await DataService.regions.get({ areaname, granularity, token });
	return areas.map((area) => ({
		label: area.name,
		value: area.seoKey,
	}));
}

interface CitySelectMultipleProps {
	value?: {
		name?: string;
		seoKey?: string;
		geographicLocation?: string;
	}[];
	onChange?(...args: unknown[]): unknown;
}

function CitySelectMultiple(props: CitySelectMultipleProps) {
	const auth = useAuth();
	const [value, setValue] = React.useState(
		(props.value ?? []).map((x) => ({
			label: x.name,
			value: x.geographicLocation,
			seoKey: x.seoKey,
		})),
	);

	return (
		<DebounceSelect
			mode="multiple"
			value={value}
			placeholder="Select city areas"
			fetchOptions={fetchAreaList}
			granularity={2}
			token={auth.user?.access_token}
			labelInValue
			onChange={(newValue) => {
				setValue(newValue);
				props.onChange?.(
					newValue.map((x) => ({
						name: x.label,
						seoKey: x.value,
						geographicLocation: x.value,
					})),
				);
			}}
			style={{
				width: '100%',
			}}
		/>
	);
}

export default CitySelectMultiple;

import { type IconResourceDto, type IconResourceModel, OpenAPI, ResourcesService } from '../api';

async function getAll({ token }): Promise<IconResourceModel[]> {
	OpenAPI.TOKEN = token;
	return ResourcesService.getIcons();
}

async function add(data: IconResourceDto, { token }) {
	OpenAPI.TOKEN = token;
	return ResourcesService.createIcon({ requestBody: data });
}

async function update(data: IconResourceModel, { token }) {
	OpenAPI.TOKEN = token;
	if (!data.id) {
		throw new Error('Icon id is required');
	}
	return ResourcesService.updateIcon({
		iconId: data.id,
		requestBody: data,
	});
}

async function remove(iconId, { token }) {
	OpenAPI.TOKEN = token;
	return ResourcesService.removeIcon({ iconId });
}

const iconsService = {
	getAll,
	add,
	update,
	remove,
};

export default iconsService;

import dayjs from 'dayjs';
import { startCase } from '../../utils';

export function processTimeSeriesData(data, startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) {
	const labelFormat = dayjs(endDate).diff(startDate, 'month') > 10 ? 'MM-DD-YY' : 'MM-DD';
	const res: Array<{ label: string; fullLabel: string; value: number }> = [];

	const processedData = data.map((x) => ({
		label: dayjs(x.date).format(labelFormat),
		fullLabel: dayjs(x.date).format('MM-DD-YYYY'),
		value: x.value,
	}));

	for (let i = dayjs(startDate); i.isBefore(endDate); i = i.add(1, 'day')) {
		const label = i.format(labelFormat);
		const fullLabel = i.format('MM-DD-YYYY');
		const value = processedData.find((x) => x.label === label)?.value || 0;
		res.push({ label, fullLabel, value });
	}
	return res;
}

export function processRegular(data, retr) {
	return data.map((x) => ({
		label: startCase(retr(x)),
		fullLabel: startCase(retr(x)),
		value: x.value,
	}));
}

export function exportHandler(reportName, apiMethod, startDate, endDate, apiKeys, token) {
	const params: any = {
		dateFrom: startDate?.format('YYYY-MM-DD'),
		dateTo: endDate?.format('YYYY-MM-DD'),
	};
	const urlParams = new URLSearchParams(params as any);
	if (apiKeys) {
		for (const apiKey of apiKeys) {
			urlParams.append('apiKeys', apiKey);
		}
	}
	const url = `https://dashboard-api.beta.sizeup.com/${apiMethod}?${urlParams.toString()}`;
	// add token to headers
	const headers = new Headers();
	headers.append('Authorization', `Bearer ${token}`);
	fetch(url, {
		method: 'GET',
		headers,
	})
		.then((response) => response.blob())
		.then((blob) => {
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = `${reportName}_${dayjs(startDate).format('MMM_DD_YYYY')}-${dayjs(endDate).format('MMM_DD_YYYY')}.csv`;
			a.click();
		});
}

export function downloadHelper(reportName, startDate, endDate, data) {
	const csv = data.map((x) => `${x.label},${x.value}`);
	csv.unshift('Label,Value');
	const blob = new Blob([csv.join('\n')], { type: 'text/csv' });
	const url = window.URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.href = url;
	a.download = `${reportName}_${dayjs(startDate).format('MMM_DD_YYYY')}-${dayjs(endDate).format('MMM_DD_YYYY')}.csv`;
	a.click();
}

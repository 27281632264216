import React from 'react';
import toast from 'react-hot-toast';
import { useAuth } from 'react-oidc-context';
import { OpenAPI } from './api';

const useAuthHandler = () => {
	const auth = useAuth();

	React.useEffect(() => {
		const customApi = localStorage.getItem('customApi');
		if (auth.user) {
			OpenAPI.TOKEN = auth.user.access_token;

			if (customApi) {
				OpenAPI.BASE = customApi;
			}
		}

		return () => {
			// Cleanup token when component unmounts
			OpenAPI.TOKEN = undefined;
			OpenAPI.BASE = 'https://hub.sizeup.com';
		};
	}, [auth.user]);

	React.useEffect(() => {
		const accessTokenExpiringCleanup = auth.events.addAccessTokenExpiring(() => {
			toast
				.promise(auth.signinSilent(), {
					loading: 'Refreshing session...',
					success: 'Session refreshed',
					error: 'Session expired. Please log in again.',
				})
				.catch(() => {
					auth.removeUser();
				});
		});

		const silentRenewCleanup = auth.events.addSilentRenewError((err) => {
			console.error('Silent renew error:', err);
			toast.error('Session expired. Please log in again.');
		});

		return () => {
			accessTokenExpiringCleanup();
			silentRenewCleanup();
		};
	}, [auth]);

	return auth;
};

export default useAuthHandler;

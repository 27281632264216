import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';

import type { SurveyModel } from '../../api';
import DataService from '../../data.service';

function SurveySelect(props) {
	const auth = useAuth();
	const [surveys, setSurveys] = useState<SurveyModel[]>([]);

	useEffect(() => {
		async function loadSurveys() {
			const data = await DataService.surveys.getAll({ token: auth.user?.access_token });
			setSurveys(data);
		}
		loadSurveys();
	}, [auth.user?.access_token]);

	return (
		<Select {...props} placeholder="Select a survey" optionFilterProp="children">
			<Select.Option value="">None</Select.Option>
			{surveys.map((survey: SurveyModel) => (
				<Select.Option key={survey.id} value={survey.id?.toString()}>
					#{survey.id} {survey.name}
				</Select.Option>
			))}
		</Select>
	);
}

export default SurveySelect;

import { Button, Image, Popconfirm, Table, Typography } from 'antd';
import { Trash } from 'lucide-react';
import React, { useMemo } from 'react';

const { Paragraph } = Typography;

interface ImageTableProps {
	data?: unknown[];
	onRemove(...args: unknown[]): unknown;
	loading: boolean;
}

export default function ImageTable({ data, onRemove, loading }: ImageTableProps) {
	const columns = useMemo(
		() => [
			{
				title: 'Id',
				dataIndex: 'id',
				key: 'id',
				render: (text) => <Paragraph copyable>{text}</Paragraph>,
			},
			{
				title: 'Title',
				dataIndex: 'title',
				key: 'title',
			},
			{
				title: 'Image',
				dataIndex: 'thumbnailUrl',
				key: 'thumbnailUrl',
				render: (text, record) => (
					<Image
						src={record.thumbnailUrl}
						alt={record.title}
						preview={{
							src: record.sourceUrl,
						}}
					/>
				),
			},
			// {
			// 	title: 'Size',
			// 	dataIndex: 'svgContent',
			// 	key: 'svgContentLength',
			// 	render: (text, record) => (
			// 		<Paragraph>{prettyBytes(text.length)}</Paragraph>
			// 	),
			// },
			{
				title: 'Actions',
				dataIndex: 'id',
				key: 'actions',
				render: (text, record) => (
					<div>
						{/* <Button
                            onClick={() => onUpdate(record)}
                            icon={<EditOutlined />}
                            type="link"
                        /> */}
						<Popconfirm title="Sure to delete?" onConfirm={() => onRemove(record)}>
							<Button icon={<Trash size={16} />} type="link" danger />
						</Popconfirm>
					</div>
				),
			},
		],
		[onRemove],
	);

	return (
		<div>
			<Table columns={columns} dataSource={data} loading={loading} rowKey="id" pagination={false} />
		</div>
	);
}

import { map, pipe, prop, sortBy } from 'remeda';

import { type DisplayOrder, OpenAPI, type PartModel, PartsEAdminService } from '../api';

async function getAll({ token }): Promise<PartModel[]> {
	OpenAPI.TOKEN = token;
	const data: PartModel[] = await PartsEAdminService.getParts();
	return pipe(
		data,
		map((x) => ({
			...x,
			displayOrder: x.displayOrder || 0,
			id: x.id || 0,
		})),
		sortBy(prop('displayOrder'), prop('id')),
	);
}

async function add(data: PartModel, { token }): Promise<PartModel> {
	OpenAPI.TOKEN = token;
	return PartsEAdminService.createPart({ requestBody: data });
}

async function update(data: PartModel, { token }): Promise<PartModel> {
	OpenAPI.TOKEN = token;
	return PartsEAdminService.updatePart({ requestBody: data });
}

async function remove(partId: number, { token }): Promise<unknown> {
	OpenAPI.TOKEN = token;
	return PartsEAdminService.removePart({ partId });
}

async function updateOrder(data: DisplayOrder[], { token }): Promise<unknown> {
	OpenAPI.TOKEN = token;
	return PartsEAdminService.sortParts({ requestBody: data });
}

const ePartsService = {
	getAll,
	add,
	update,
	remove,
	updateOrder,
};

export default ePartsService;

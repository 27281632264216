import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useAuth } from 'react-oidc-context';

import DataService from '../../data.service';
import BusinessRequestsTable from './Table';

export function BusinessRequests() {
	const auth = useAuth();

	const [data, setData] = useState<BusinessRequest[] | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		setIsLoading(true);
		DataService.businessRequests
			.getAll({
				token: auth.user?.access_token,
			})
			.then((data) => {
				setData(data);
				setIsLoading(false);
			})
			.catch((error) => {
				console.log('err:', error);
				setError(error);
				setIsLoading(false);
			});
	}, [auth.user?.access_token]);

	if (error === 401 || error === 403) {
		toast('Your session has expired. Please sign in again.');
		auth.removeUser();
	}

	if (!data) return null;

	return <BusinessRequestsTable data={data} loading={isLoading} />;
}

import { Button, Popconfirm, Table, Typography } from 'antd';

import { isDeepEqual } from 'remeda';

import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import { ChartPie, Eye, PencilLine, Trash } from 'lucide-react';
import { type QuestionModel, SurveyDto, type SurveyModel } from '../../api';
import EditSurvey from './Edit';

const { Paragraph } = Typography;

interface SurveysTableProps {
	data: SurveyModel[];
	loading: boolean;
	onUpdate: (survey: SurveyModel) => void;
	onRemove: (id) => void;
	onQuestionUpdate: (question: QuestionModel) => Promise<void>;
}

export default function SurveysTable({ data, loading, onUpdate, onRemove, onQuestionUpdate }: SurveysTableProps) {
	const navigate = useNavigate();

	const [activeSurvey, setActiveSurvey] = useState<SurveyModel | null>(null);
	const columns = useMemo(
		() => [
			{
				title: 'Id',
				dataIndex: 'id',
				key: 'id',
			},
			{
				title: 'Name & Description',
				dataIndex: 'name',
				key: 'name',
				render: (name, record) => (
					<div>
						<Paragraph
							strong
							ellipsis
							// editable={{
							// 	onChange: (name) => {
							// 		onUpdate({ ...record, name });
							// 	},
							// 	text: record.name,
							// }}
						>
							{name}
						</Paragraph>
						<Paragraph
						// ellipsis
						// editable={{
						// 	onChange: (description) => {
						// 		onUpdate({ ...record, description });
						// 	},
						// 	text: record.description,
						// }}
						>
							{record.description}
						</Paragraph>
					</div>
				),
			},
			// {
			// 	title: 'Tenant',
			// 	dataIndex: 'tenantId',
			// 	key: 'tenantId',
			// },
			{
				title: 'Questions',
				dataIndex: 'questions',
				key: 'questions',
				render: (questions) => <div>{questions.length} </div>,
			},
			{
				title: 'Actions',
				dataIndex: 'id',
				key: 'actions',
				render: (text, record) => (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
						}}
					>
						{/* <Button
                            type="link"
                            icon={<EditOutlined />}
                            onClick={() => onUpdate(record)}
                        >
                            Edit
                        </Button> */}
						<Button
							type="link"
							icon={<Eye size={16} />}
							href={`https://surveys.sizeup.com/?surveyId=${record.id}&apiKey=73C3BD77-74CD-4A33-B170-77A0DE5251F5`}
							target="_blank"
						>
							Preview Survey
						</Button>
						<Button
							type="link"
							icon={<ChartPie size={16} />}
							onClick={() => navigate(`/surveys/${record.id}/results`)}
						>
							See Results
						</Button>
						<Button type="link" icon={<PencilLine size={16} />} onClick={() => setActiveSurvey(record)}>
							Edit Survey
						</Button>
						<Popconfirm title="Sure to delete?" onConfirm={() => onRemove(record)}>
							<Button icon={<Trash size={16} />} type="link" danger>
								Delete
							</Button>
						</Popconfirm>
					</div>
				),
			},
		],
		[onRemove, navigate],
	);

	// if (!activeSurvey) {
	// 	return (
	// 		<div>
	// 			Survey is not selected. Please select survey from the list.
	// 		</div>
	// 	);
	// }

	return (
		<div>
			<EditSurvey
				survey={activeSurvey}
				onEdit={(values) => {
					console.log('values -> ', values);
					const formData = {
						id: values.id,
						name: values.name,
						description: values.description,
						tenantId: values.tenantId,
					};

					const { questions } = values;
					const deletedQuestions = (activeSurvey?.questions || []).filter(
						(q) => questions.findIndex((a) => a.id === q.id) === -1,
					);
					console.log('deletedQuestions -> ', deletedQuestions);

					const addedQuestions = questions.filter(
						(q) => (activeSurvey?.questions || []).findIndex((a) => a.id === q.id) === -1,
					);
					console.log('addedQuestions -> ', addedQuestions);

					const updatedQuestions = questions.filter((q) => {
						const oldQuestion = (activeSurvey?.questions || []).find((a) => a.id === q.id);
						return oldQuestion && !isDeepEqual(oldQuestion, q);
					});
					console.log('updatedQuestions -> ', updatedQuestions);

					const promises: Promise<void>[] = [];
					// deletedQuestions.forEach((q) => {
					for (const q of deletedQuestions) {
						promises.push(onQuestionUpdate({ ...q, surveyId: 0 }));
					}
					// addedQuestions.forEach((q) => {
					for (const q of addedQuestions) {
						promises.push(onQuestionUpdate({ ...q, surveyId: values.id }));
					}
					// updatedQuestions.forEach((q) => {
					for (const q of updatedQuestions) {
						promises.push(onQuestionUpdate(q));
					}

					Promise.all(promises).then(() => {
						onUpdate(formData);
						setActiveSurvey(null);
					});
				}}
				onCancel={() => setActiveSurvey(null)}
			/>

			<Table columns={columns} dataSource={data} loading={loading} rowKey="id" pagination={false} />
		</div>
	);
}

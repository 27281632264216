import { ColorPicker, Form, Image, Input, Radio, Select, Slider, Spin } from 'antd';
import React, { useEffect, useState } from 'react';

import RichEditor from '../../components/RichEditor';
import { startCase } from '../../utils';
import CitySelect from './CitySelect';
import IndustrySelect from './IndustrySelect';
import SurveySelect from './SurveySelect';

const { Option } = Select;

interface SliderPlusProps {
	value?: any;
	options: any[];
	onChange: (value: any) => void;
}

function SliderPlus({ value = {}, options, onChange }: SliderPlusProps) {
	const [sliderValue, setSliderValue] = useState(value.value);
	// const [inputValue, setInputValue] = useState(value.value);

	useEffect(() => {
		setSliderValue(options.indexOf(value));
	}, [options, value]);

	return (
		<Slider
			marks={options.reduce((acc, cur, index) => {
				acc[index] = cur;
				return acc;
			}, {})}
			min={0}
			max={options.length - 1}
			step={null}
			value={sliderValue}
			tooltip={{ formatter: null }}
			onChange={(value) => {
				onChange(options[value]);
			}}
			trackStyle={{ backgroundColor: '#1890ff' }}
			handleStyle={{ borderColor: '#1890ff' }}
			railStyle={{ backgroundColor: '#d9d9d9' }}
		/>
	);
}

interface SmartSettingProps {
	setting: any;
	index: number;
	isLoading?: boolean;
	images?: any[];
	customElements?: any;
}

export default function SmartSetting({
	setting,
	index,
	isLoading = false,
	images = [],
	customElements = {},
}: SmartSettingProps) {
	return (
		<Form.Item
			label={setting.displayName || startCase(setting.keyName)}
			tooltip={setting.description}
			name={['settings', index, 'value']}
			labelCol={{ span: 5 }}
			wrapperCol={{ span: 19 }}
			labelAlign="left"
			key={index}
			normalize={(value) => {
				if (setting.uiInputType === 'color') {
					return value.toHexString();
				}
				return value;
			}}
		>
			{customElements[setting.keyName] ? (
				customElements[setting.keyName]
			) : setting.uiInputType === 'color' ? (
				<ColorPicker
					showText
					disabledAlpha
					defaultFormat="hex"
					presets={[
						{
							label: 'Recommended',
							colors: [
								'#0d7bbf',
								'#2b6cb0',
								'#2f855a',
								'#c05621',
								'#4a5568',
								'#c53030',
								'#b7791f',
								'#2c7a7b',
								'#1e88e5',
							],
						},
					]}
				/>
			) : setting.uiInputType === 'sizeup_industry' ? (
				<IndustrySelect />
			) : setting.uiInputType === 'sizeup_location' ? (
				<CitySelect />
			) : setting.uiInputType === 'sizeup_survey' ? (
				<SurveySelect />
			) : setting.uiInputType === 'image' ? (
				isLoading ? (
					<Spin />
				) : (
					<Select loading={isLoading} mode="multiple" size="large">
						{images.map((image: any) => (
							<Option key={image.id} value={image.id}>
								<Image height={32} preview={false} src={image.thumbnailUrl} />
							</Option>
						))}
					</Select>
				)
			) : setting.uiInputType === 'wysiwyg' ? (
				<RichEditor />
			) : setting.uiInputType === 'number' ? (
				<Input type="number" step="0.01" value="0.00" placeholder="0.00" />
			) : setting.uiInputType === 'slider' ? (
				<SliderPlus options={setting.allowedOptions} onChange={(x) => console.log('slideplusonchange', x)} />
			) : setting.jsonType === 'boolean' ? (
				<Radio.Group buttonStyle="solid">
					<Radio.Button value="false">Off</Radio.Button>
					<Radio.Button value="true">On</Radio.Button>
				</Radio.Group>
			) : setting.allowedOptions?.length ? (
				<Radio.Group buttonStyle="solid">
					{setting.allowedOptions.map((option, index) => (
						<Radio.Button value={option} key={option}>
							{option}
						</Radio.Button>
					))}
				</Radio.Group>
			) : (
				<Input />
			)}
		</Form.Item>
	);
}

import { Button, Divider, Space, Spin, Switch, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useAuth } from 'react-oidc-context';

import { UserPlus } from 'lucide-react';
import type { EmailBindingModel } from '../../api';
import DataService from '../../data.service';

// import usePromise from "react-fetch-hook/usePromise";

interface CustomerUsersProps {
	customerId: number | undefined;
}

export default function CustomerUsers({ customerId }: CustomerUsersProps) {
	const auth = useAuth();
	const [dataSource, setDataSource] = useState<EmailBindingModel[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const columns = [
		{
			title: 'E-Mail',
			dataIndex: 'email',
			key: 'email',
		},
		{
			title: 'Active',
			dataIndex: 'isEnabled',
			key: 'isEnabled',
			render: (text, record) => (
				<Space size="middle">
					<Switch
						checkedChildren="✓"
						unCheckedChildren=""
						defaultChecked={record.isEnabled}
						onChange={(checked) => {
							const newDataSource = dataSource.map((item) => {
								if (item.email === record.email) {
									return { ...item, isEnabled: checked };
								}
								return item;
							});

							setDataSource(newDataSource);
							// setIsLoading(true);

							const toastId = toast.loading(`Updating ${record.email}...`);

							DataService.users
								.updateEmails(customerId, newDataSource, {
									token: auth.user?.access_token,
								})
								.then(() => {
									toast.success(`${record.email} updated successfully`, {
										id: toastId,
									});
								})
								.catch((error) => {
									toast.error(error.message, { id: toastId });
								});
						}}
					/>
				</Space>
			),
		},
	];

	const handleAdd = () => {
		const email = window.prompt('Enter user e-mail');
		if (email) {
			const newData = {
				email,
				isEnabled: false,
			};
			setDataSource([...dataSource, newData]);
			setIsLoading(true);

			DataService.users
				.updateEmails(customerId, [...dataSource, newData], {
					token: auth.user?.access_token,
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	};

	const [data, setData] = useState<EmailBindingModel[]>([]);
	const [error, setError] = useState(null);

	useEffect(() => {
		if (auth.user?.access_token && customerId) {
			setIsLoading(true);
			DataService.users
				.getEmails(customerId, {
					token: auth.user?.access_token,
				})
				.then((data) => {
					setData(data);
				})
				.catch((error) => {
					setError(error);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	}, [customerId, auth.user?.access_token]);

	useEffect(() => {
		if (data) {
			setDataSource(data);
		}
	}, [data]);

	if (error) {
		return <div>Something went wrong</div>;
	}

	if (isLoading) {
		return (
			<Space size="large" align="center" direction="vertical" style={{ width: '100%' }}>
				<Spin size="large" />
			</Space>
		);
	}

	if (!customerId) {
		return <div>Customer is not selected</div>;
	}

	return (
		<div>
			<Table columns={columns} dataSource={dataSource} pagination={false} loading={isLoading} rowKey="email" />

			<Divider />

			<Button icon={<UserPlus size={16} />} type="primary" onClick={handleAdd}>
				Add User
			</Button>
		</div>
	);
}

/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import '@thaddeusjiang/react-sortable-list/dist/index.css';

import './custom.css';

import { Alert, Space, Spin } from 'antd';
import React, { useEffect, useLayoutEffect, useMemo } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useAuth } from 'react-oidc-context';
import { Navigate, Route, Routes, useLocation } from 'react-router';
import packageInfo from '../package.json';
import useAuthHandler from './useAuthHandler';

import { Auth } from './components/Auth';
import Main from './components/layout/Main';
import { AnalyticsPage } from './pages/AnalyticsPage';
import { BusinessRequests } from './pages/BusinessRequests';
import { Customer } from './pages/Customer';
import { Preview } from './pages/Customer/Preview';
import { Customers } from './pages/Customers';
import { Entrepreneur } from './pages/Entrepreneur';
import { Icons } from './pages/Icons';
import { Images } from './pages/Images';
import { Implementation, ImplementationWrapper } from './pages/Implementation';
import { Intro } from './pages/Intro';
import { Profile } from './pages/Profile';
import { Roles } from './pages/Roles';
import { Settings } from './pages/Settings';
import { SurveyResults, Surveys } from './pages/Surveys';
import { Translations } from './pages/Translations';

// Loading spinner component
const LoadingSpinner: React.FC = () => (
	<Space size="large" align="center" direction="vertical" style={{ width: '100%' }}>
		<Spin size="large" />
	</Space>
);

function App() {
	const auth = useAuthHandler();

	React.useLayoutEffect(() => {
		document.body.classList.toggle('home', !auth.user);
	}, [auth.user]);

	if (auth.isLoading) {
		return <LoadingSpinner />;
	}

	return (
		<div className="App" data-x-version={packageInfo.version}>
			<Toaster />

			<Routes>
				<Route path="/" element={<Intro />} />
				<Route element={<Main />}>
					<Route
						path="/all"
						element={
							<RequireAuth adminOnly={false}>
								<Customers />
							</RequireAuth>
						}
					/>
					<Route
						path="/edit/:id"
						element={
							<RequireAuth adminOnly={false}>
								<Customer />
							</RequireAuth>
						}
					/>
					<Route
						path="/preview/:id"
						element={
							<RequireAuth adminOnly={false}>
								<Preview />
							</RequireAuth>
						}
					/>
					<Route
						path="/implementation"
						element={
							<RequireAuth adminOnly={false}>
								<ImplementationWrapper />
							</RequireAuth>
						}
					>
						<Route
							path=":customerId/:checklistId?/:stageId?"
							element={
								<RequireAuth adminOnly={false}>
									<Implementation />
								</RequireAuth>
							}
						/>
					</Route>
					<Route
						path="/analytics"
						element={
							<RequireAuth adminOnly={false}>
								<AnalyticsPage />
							</RequireAuth>
						}
					/>
					<Route
						path="/businessrequests"
						element={
							<RequireAuth adminOnly={false}>
								<BusinessRequests />
							</RequireAuth>
						}
					/>
					<Route
						path="/surveys"
						element={
							<RequireAuth>
								<Surveys />
							</RequireAuth>
						}
					/>
					<Route
						path="/profile"
						element={
							<RequireAuth adminOnly={false}>
								<Profile />
							</RequireAuth>
						}
					/>
					<Route
						path="/logout"
						element={
							<RequireAuth adminOnly={false}>
								<Logout />
							</RequireAuth>
						}
					/>
					<Route
						path="/manage/settings"
						element={
							<RequireAuth>
								<Settings />
							</RequireAuth>
						}
					/>
					<Route
						path="/manage/sbadvisor"
						element={
							<RequireAuth>
								<Entrepreneur />
							</RequireAuth>
						}
					/>
					<Route
						path="/manage/icons"
						element={
							<RequireAuth>
								<Icons />
							</RequireAuth>
						}
					/>
					<Route
						path="/manage/images"
						element={
							<RequireAuth>
								<Images />
							</RequireAuth>
						}
					/>
					<Route
						path="/manage/roles"
						element={
							<RequireAuth>
								<Roles />
							</RequireAuth>
						}
						// loader={async () => {
						// 	let roles = await DataService.roles.getAll();
						// 	return defer({ roles });
						// }}
					/>
					<Route
						path="/manage/translations"
						element={
							<RequireAuth>
								<Translations />
							</RequireAuth>
						}
					/>
					<Route
						path="/surveys/:id/results"
						element={
							<RequireAuth>
								<SurveyResults />
							</RequireAuth>
						}
					/>
				</Route>
			</Routes>
		</div>
	);
}

function Logout() {
	const auth = useAuth();
	auth.removeUser();
	auth.signoutPopup();
	return <Navigate to="/" />;
}

// Type definitions
interface RequireAuthProps {
	children: React.ReactNode;
	adminOnly?: boolean;
	roles?: string[];
}

// Improved RequireAuth component
const RequireAuth: React.FC<RequireAuthProps> = ({ children, adminOnly = true, roles = [] }) => {
	const auth = useAuth();
	const location = useLocation();

	if (auth.isLoading) {
		return <LoadingSpinner />;
	}

	if (!auth.user) {
		return <Navigate to="/" state={{ from: location }} replace />;
	}

	const profileRole = auth.user.profile.role as string;
	const hasRequiredRole = adminOnly
		? profileRole === 'sizeup_admin'
		: roles.length === 0 || roles.includes(profileRole);

	if (!hasRequiredRole) {
		return (
			<Space size="large" align="center" direction="vertical" style={{ width: '100%' }}>
				<Alert message="You are not authorized to access this page." type="error" showIcon />
				<Auth key="auth" />
			</Space>
		);
	}

	return <>{children}</>;
};

export default App;

import { Alert, Button, Card, Col, Row, Space, Statistic, Tooltip } from 'antd';
import type dayjs from 'dayjs';
import { FileSpreadsheet, Info } from 'lucide-react';
import React, { useCallback, useMemo } from 'react';

import analyticsService from '../../service/analytics.service';
import { BarChart, LineChart, StackedBarChart } from './AnalyticsCharts';
import { downloadHelper, exportHandler, processRegular, processTimeSeriesData } from './analytics-helpers';
import useAnalytics from './useAnalytics';

function AnalyticsShopLocal({
	apiKeys,
	startDate,
	endDate,
	token = '',
	isSingleLocationMode = false,
}: {
	apiKeys: string[];
	startDate: dayjs.Dayjs;
	endDate: dayjs.Dayjs;
	token?: string;
	isSingleLocationMode?: boolean;
}) {
	// const [loads, loadsLoading, loadsError] = useAnalytics(
	// 	apiKeys,
	// 	startDate,
	// 	endDate,
	// 	analyticsService.getShopLocalLoads,
	// 	token,
	// 	(response) => {
	// 		const data: Array<{ label: string; industry: string; value: number }> = [];
	// 		const res = response.map((x) => ({ label: x.locationName, color: x.industryName, value: x.count }));
	// 		return res;
	// 	},
	// );

	// const totalLoads = useMemo(() => loads.reduce((acc, x) => acc + x.value, 0), [loads]);

	const [visits, visitsLoading, visitsError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getShopLocalVisits,
		token,
		(response) => processTimeSeriesData(response, startDate, endDate),
	);

	const [locations, locationsLoading, locationsError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getShopLocalLocations,
		token,
		(response) => processRegular(response, (x) => x.name),
	);
	const [industries, industriesLoading, industriesError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getShopLocalIndustries,
		token,
		(response) => processRegular(response, (x) => x.name),
	);

	const totalVisits = useMemo(() => visits.reduce((acc, x) => acc + x.value, 0), [visits]);

	// const [businessViews, businessViewsLoading, businessViewsError] = useAnalytics(
	// 	apiKeys,
	// 	startDate,
	// 	endDate,
	// 	analyticsService.getShopLocalBusinessViews,
	// 	token,
	// 	(response) => response,
	// );

	const download = useCallback(
		(data, name) => {
			downloadHelper(name, startDate, endDate, data);
		},
		[startDate, endDate],
	);

	const onIndustriesDownload = useCallback(() => {
		exportHandler('shoplocal_industries', 'export/shoplocal/industries', startDate, endDate, apiKeys, token);
	}, [apiKeys, startDate, endDate, token]);

	const onLocationsDownload = useCallback(() => {
		exportHandler('shoplocal_locations', 'export/shoplocal/locations', startDate, endDate, apiKeys, token);
	}, [apiKeys, startDate, endDate, token]);

	if (visitsError || locationsError || industriesError) {
		return <Alert message="Error loading data" type="error" />;
	}

	return (
		<Row gutter={[16, 16]}>
			<Col xs={24} sm={24} md={24} lg={24} xl={24}>
				<Card
					title={
						<Space>
							User Visits {totalVisits ? `(${totalVisits.toLocaleString()})` : ''}
							<Tooltip title="This reflects the number of times the widget loaded into a browser">
								<Info size={12} />
							</Tooltip>
						</Space>
					}
					extra={
						<Button
							icon={<FileSpreadsheet size={16} />}
							onClick={() => download(visits, 'ShopLocal User Visits')}
						>
							CSV
						</Button>
					}
					variant="borderless"
				>
					<LineChart data={visits as any} color="#ed6d85" loading={visitsLoading as boolean} />
				</Card>
			</Col>
			<Col
				xs={24}
				sm={24}
				md={isSingleLocationMode ? 24 : 12}
				lg={isSingleLocationMode ? 24 : 12}
				xl={isSingleLocationMode ? 24 : 12}
			>
				<Card
					title={
						<Space>
							Top Industries
							<Tooltip title="The # of views reflects a user&#x27;s industry of interest while navigating the different modules. There may be more than the 10 industries reflected and they can be downloaded using the csv button  (with the portal)">
								<Info size={12} />
							</Tooltip>
						</Space>
					}
					extra={
						<Button icon={<FileSpreadsheet size={16} />} target="_blank" onClick={onIndustriesDownload}>
							CSV
						</Button>
					}
					variant="borderless"
				>
					<BarChart
						data={industries as any}
						title="# of views"
						color="#e8af48"
						loading={industriesLoading as boolean}
					/>
				</Card>
			</Col>
			{isSingleLocationMode ? null : (
				<Col xs={24} sm={24} md={12} lg={12} xl={12}>
					<Card
						title={
							<Space>
								Top Locations
								<Tooltip title="The # of views reflects a user&#x27;s geography of interest while navigating the different modules.  There may be more than the 10 geographies reflected and they can be downloaded using the csv button (with the portal)">
									<Info size={12} />
								</Tooltip>
							</Space>
						}
						extra={
							<Button icon={<FileSpreadsheet size={16} />} target="_blank" onClick={onLocationsDownload}>
								CSV
							</Button>
						}
						variant="borderless"
					>
						<BarChart
							data={locations as any}
							title="# of views"
							color="#448fa4"
							loading={locationsLoading as boolean}
						/>
					</Card>
				</Col>
			)}
		</Row>
	);
}

export default AnalyticsShopLocal;

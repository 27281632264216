import { Form, Input, InputNumber, Modal, Radio, Select, Space } from 'antd';
import React from 'react';

const booleanOptions = [
	{ label: 'Checkbox', value: 'checkbox' },
	{ label: 'Switch', value: 'switcher' },
];

const inputOptions = [
	{ label: 'Input', value: 'input' },
	{ label: 'Textarea', value: 'textarea' },
	{ label: 'WYSIWYG', value: 'wysiwyg' },
	{ label: 'Color', value: 'color' },
	{ label: 'Slider', value: 'slider' },
	{ label: 'Image', value: 'image' },
	{ label: 'Sizeup Industry', value: 'sizeup_industry' },
	{ label: 'Sizeup Location', value: 'sizeup_location' },
	{ label: 'Sizeup Survey', value: 'sizeup_survey' },
	{ label: 'Custom', value: 'custom' },
];

interface CreateSettingFormProps {
	open: boolean;
	roles: any[];
	onCreate: (values: any) => void;
	onCancel: () => void;
}

export default function CreateSettingForm({ open, roles, onCreate, onCancel }: CreateSettingFormProps) {
	const [form] = Form.useForm();
	const [isBoolean, setIsBoolean] = React.useState(false);

	return (
		<Modal
			open={open}
			title="Create a new setting"
			okText="Create"
			cancelText="Cancel"
			onCancel={onCancel}
			onOk={() => {
				form.validateFields()
					.then((values) => {
						form.resetFields();
						onCreate(values);
					})
					.catch((info) => {
						console.log('Validate Failed:', info);
					});
			}}
		>
			<Form
				form={form}
				layout="vertical"
				name="form_in_modal"
				initialValues={{
					jsonType: 'string',
					uiInputType: 'input',
					isArray: false,
					groupTag: '',
					defaultValue: '',
					allowedOptions: [],
					allowedOptionsDisplayNames: [],
				}}
			>
				<Form.Item
					name="keyName"
					label="Key Name"
					rules={[
						{
							required: true,
							message: 'Please input the keyName',
						},
					]}
				>
					<Input placeholder="Enter key name" />
				</Form.Item>
				<Form.Item name="displayName" label="Display Name">
					<Input />
				</Form.Item>
				<Form.Item name="description" label="Description">
					<Input.TextArea placeholder="Enter description" />
				</Form.Item>
				<Form.Item name="jsonType" label="JSON Type">
					<Radio.Group
						buttonStyle="solid"
						onChange={(x) => {
							setIsBoolean(x.target.value === 'boolean');
							if (x.target.value === 'boolean') {
								form.setFieldsValue({
									defaultValue: 'false',
									allowedOptions: [],
									uiInputType: 'switcher',
								});
							} else {
								form.setFieldsValue({
									defaultValue: '',
									allowedOptions: [],
									uiInputType: 'input',
								});
							}
						}}
					>
						<Radio.Button value="string">string</Radio.Button>
						<Radio.Button value="boolean">boolean</Radio.Button>
					</Radio.Group>
				</Form.Item>
				<Space.Compact block>
					<Form.Item
						name="uiInputType"
						label="UI Input Type"
						style={{
							width: '100%',
						}}
					>
						<Select options={isBoolean ? booleanOptions : inputOptions} />
					</Form.Item>
					<Form.Item name="isArray" label="Multiple">
						<Select disabled={isBoolean}>
							<Select.Option value={false}>No</Select.Option>
							<Select.Option value>Yes</Select.Option>
						</Select>
					</Form.Item>
				</Space.Compact>
				<Form.Item name="groupTag" label="Group Tag">
					<Select>
						<Select.Option value="">None</Select.Option>
						<Select.Option value="Widget UI">Widget UI</Select.Option>
						<Select.Option value="ShopLocal UI">ShopLocal UI</Select.Option>
						<Select.Option value="Entrepreneur UI">SBAdvisor UI</Select.Option>
						<Select.Option value="Pulse UI">Pulse UI</Select.Option>
						<Select.Option value="Widget Data Placeholders">Widget Data Placeholders</Select.Option>
						<Select.Option value="Backend">Backend</Select.Option>
						<Select.Option value="PDF">PDF</Select.Option>
						<Select.Option value="Meta">Meta</Select.Option>
						<Select.Option value="Reports">Reports</Select.Option>
						<Select.Option value="Calculators">Calculators</Select.Option>
						<Select.Option value="DELETEME">Bin</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item name="parentRoleId" label="Parent Role">
					<Select loading={roles === null}>
						{roles?.map((role) => (
							<Select.Option key={role.id} value={role.id}>
								{role.name}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item name="defaultValue" label="Default Value">
					{isBoolean ? (
						<Radio.Group buttonStyle="solid">
							<Radio.Button value="true">true</Radio.Button>
							<Radio.Button value="false">false</Radio.Button>
						</Radio.Group>
					) : (
						<Input />
					)}
				</Form.Item>
				<Form.Item name="allowedOptions" label="Allowed Options (use enter to separate)">
					{isBoolean ? (
						<Input disabled />
					) : (
						<Select mode="tags" style={{ width: '100%' }} placeholder="Possible Values" />
					)}
				</Form.Item>
				<Form.Item
					name="allowedOptionsDisplayNames"
					label="Allowed Options Display Names (use enter to separate)"
				>
					{isBoolean ? (
						<Input disabled />
					) : (
						<Select mode="tags" style={{ width: '100%' }} placeholder="Possible Values Display Names" />
					)}
				</Form.Item>
				<Form.Item name="displayOrder" label="Display Order">
					<InputNumber disabled />
				</Form.Item>
			</Form>
		</Modal>
	);
}

import { Button, Form, Space, Spin, Tabs } from 'antd';

import React, { useEffect, useMemo, useState } from 'react';
// import usePromise from "react-fetch-hook/usePromise";
import toast from 'react-hot-toast';
import { useAuth } from 'react-oidc-context';
import { useNavigate, useParams } from 'react-router';

import {
	Activity, // Pulse - represents activity/vitality measurement
	BarChart2, // Analytics - data charts/statistics
	BookOpen, // SB Advisor - represents knowledge base/guides
	Briefcase,
	Gauge,
	Layout, // Widget - represents UI component/interface
	LineChart,
	PieChart, // Demographics - data visualization for population segments
	Save,
	Settings2, // Global Settings - gear representing system-wide settings
	ShieldCheck, // Roles - protected access/permissions
	Store, // Shop Local - storefront icon
	Tags, // Meta - represents metadata/tags
	TrendingUp,
	UserRoundCog,
	Users, // Users Management - multiple user profiles
} from 'lucide-react';

import PageHeader from '../../components/layout/PageHeader';

import type { CustomerSettingsModel } from '../../api';
import DataService from '../../data.service';
import CustomerAnalytics from '../AnalyticsPage/Analytics';
import CustomerDemographics from './Demographics';
import CustomerGlobal from './Global';
import CustomerMeta from './Meta';
import CustomerPulse from './Pulse';
import CustomerRoles from './Roles';
import CustomerSBAdvisor from './SBAdvisor';
import CustomerShopLocal from './ShopLocal';

import type { CustomerSettingsModelPlus } from '../../types/CustomerSettingsModelPlus';
import UsersComponent from './Users';
import CustomerWidget from './Widget';

export function Customer() {
	const params = useParams();

	const setLocation = useNavigate();

	const [isSubmitLoading, setIsSubmitLoading] = useState(false);

	//   const [reports, setReports] = useState([]);

	const auth = useAuth();

	const isAdmin = useMemo(() => auth.user?.profile?.role === 'sizeup_admin', [auth.user]);

	const [data, setData] = useState<CustomerSettingsModelPlus | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<any>(null);

	useEffect(() => {
		setIsLoading(true);
		DataService.customers
			.get(params.id, { token: auth.user?.access_token })
			.then((data: CustomerSettingsModel) => {
				// check if the server sends JSON and not HTML
				if (typeof data === 'string') {
					throw 422;
				}
				setData({
					...data,
					roles: data.roles ? data.roles.sort((a, b) => (a.displayOrder || 0) - (b.displayOrder || 0)) : [],
					settings: data.settings
						?.sort((a, b) => (a.displayOrder || 0) - (b.displayOrder || 0))
						.map((s) => ({
							...s,
							value:
								s.keyName?.endsWith('[]') && s.value ? JSON.parse(s.value.replace(/'/g, '"')) : s.value,
						})),
				});
			})
			.catch((error) => {
				if (error === 401 || error === 403) {
					toast('Your session has expired. Please sign in again.');
					auth.removeUser();
				}
				if (error === 422) {
					setError('The server sends HTML instead of JSON. Please contact the server administrator.');
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [params.id, auth.user?.access_token]);

	const [hasChanges, setHasChanges] = useState(false);
	const [form] = Form.useForm();

	const onFinish = () => {
		const updateObject = {
			id: form.getFieldValue('id'),
			name: form.getFieldValue('name'),
			displayName: form.getFieldValue('displayName'),
			isActive: form.getFieldValue('isActive'),
			cName: form.getFieldValue('cName'),
			serviceAreas: form.getFieldValue('serviceAreas'),
			serviceAreaMBR: form.getFieldValue('serviceAreaMBR'),
			roles: form.getFieldValue('roles'),
			settings: form.getFieldValue('settings').map((s) => ({
				...s,
				value: s.keyName.endsWith('[]') ? JSON.stringify(s.value) : s.value,
			})),
		};

		setIsSubmitLoading(true);

		DataService.customers
			.update(updateObject, {
				token: auth.user?.access_token,
			})
			.then((data) => {
				window.location.reload();
				// setData(data);
			})
			.catch((error) => {
				console.error(error.message);
				toast('Your session has expired. Please sign in again.');
				auth.removeUser();
			})
			.finally(() => {
				setIsSubmitLoading(false);
			});
	};

	const onDelete = (id) => {
		if (id) {
			DataService.customers.remove(id, { token: auth.user?.access_token }).then(() => {
				setLocation('/');
			});
		} else {
			console.error('id is not specified');
		}
	};

	if (error === 401 || error === 403) {
		toast('Your session has expired. Please sign in again.');
		auth.removeUser();
	}

	return (
		<div style={{ textAlign: 'left' }}>
			<PageHeader
				title="Edit Instance"
				subTitle={data?.apiKey}
				extra={[
					hasChanges ? (
						<Button
							icon={<Save size={16} />}
							type="primary"
							loading={isSubmitLoading}
							onClick={onFinish}
							key={2}
						>
							Save
						</Button>
					) : null,
				]}
			/>
			{error && <strong>Error: {error}</strong>}
			{isLoading && (
				<Space size="large" align="center" direction="vertical" style={{ width: '100%' }}>
					<Spin size="large" />
				</Space>
			)}

			{data ? (
				<div className="site-layout-background" style={{ padding: 24, minHeight: 380 }}>
					<Form
						layout="horizontal"
						initialValues={data}
						onFinish={onFinish}
						// onFinishFailed={onFinishFailed}
						form={form}
						autoComplete="off"
						onValuesChange={() => {
							setHasChanges(true);
						}}
					>
						<Tabs
							defaultActiveKey="1"
							items={[
								{
									label: (
										<span>
											<Settings2 size={12} /> Global Settings
										</span>
									),
									key: '1',
									children: <CustomerGlobal data={data} isAdmin={isAdmin} onDelete={onDelete} />,
								},
								{
									label: (
										<span>
											<UserRoundCog size={12} /> Users Management
										</span>
									),
									key: '2',
									children: <UsersComponent customerId={data.id} />,
									disabled: !isAdmin,
								},
								{
									label: (
										<span>
											<ShieldCheck size={12} /> Roles
										</span>
									),
									key: '3',
									children: (
										<CustomerRoles
											data={data}
											// isAdmin={isAdmin}
											// onChange={() => {
											// 	setHasChanges(true);
											// }}
										/>
									),
								},
								{
									label: (
										<span>
											<Gauge size={12} /> Insights
										</span>
									),
									key: '4',
									// disabled: !data.roles.find((x) => x.name === 'Widget')
									// 	?.isActive,
									children: <CustomerWidget data={data} isAdmin={isAdmin} />,
								},
								{
									label: (
										<span>
											<Briefcase size={12} /> SB Advisor
										</span>
									),
									key: '5',

									disabled: !data.roles?.find((x) => x.name === 'Entrepreneur')?.isActive,
									children: <CustomerSBAdvisor data={data} isAdmin={isAdmin} />,
								},
								{
									label: (
										<span>
											<Store size={12} /> Shop Local
										</span>
									),
									key: '6',
									disabled: !data.roles?.find((x) => x.name === 'ShopLocal')?.isActive,
									children: <CustomerShopLocal data={data} isAdmin={isAdmin} />,
								},
								{
									label: (
										<span>
											<Activity size={12} /> Pulse
										</span>
									),
									key: '7',
									disabled: !data.roles?.find((x) => x.name === 'LIPD')?.isActive,
									children: <CustomerPulse data={data} isAdmin={isAdmin} />,
								},
								{
									label: (
										<span>
											<Users size={12} /> Demographics
										</span>
									),
									key: '8',
									disabled: !data.roles?.find((x) => x.name === 'DemographicsProduct')?.isActive,
									children: <CustomerDemographics data={data} isAdmin={isAdmin} />,
								},
								{
									label: (
										<span>
											<Tags size={12} /> Meta
										</span>
									),
									key: '9',
									children: <CustomerMeta data={data} />,
								},
								{
									label: (
										<span>
											<LineChart size={12} /> Analytics
										</span>
									),
									key: '10',
									children: (
										<div>
											<CustomerAnalytics
												customer={{
													id: data.id,
													name: data.name,
													apiKey: data.apiKey,
												}}
											/>
										</div>
									),
								},
							]}
						/>
					</Form>
				</div>
			) : null}
		</div>
	);
}

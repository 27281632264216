import { create } from 'ol/transform';

const host = 'https://dashboard-api.beta.sizeup.com/';

interface AnalyticsRequestParams {
	dateFrom: string;
	dateTo: string;
	apiKeys?: string[];
	token: string;
	signal?: AbortSignal;
}

async function getRequest(
	path: string,
	{ token, signal, ...params }: AnalyticsRequestParams,
): Promise<AnalyticsRequest> {
	// remove undefined values
	const filteredParams = {
		...(params.dateFrom ? { DateFrom: params.dateFrom } : {}),
		...(params.dateTo ? { DateTo: params.dateTo } : {}),
	};

	const urlParams = new URLSearchParams(filteredParams);

	if (params.apiKeys) {
		for (const apiKey of params.apiKeys) {
			urlParams.append('ApiKeys', apiKey);
		}
	}

	try {
		const response = await fetch(`${host}${path}?${urlParams}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
			signal,
		});

		if (response.ok) {
			return await response.json();
		}
		console.error('Analytics error', response);
		return await Promise.reject(response.status);
	} catch (error) {
		console.error('Analytics exception error', error);
		return Promise.reject(error);
	}
}

function createAnalyticsRequestFunction(path: string) {
	return (params: AnalyticsRequestParams): Promise<AnalyticsRequest> => getRequest(path, params);
}

const analyticsService = {
	getVisits: createAnalyticsRequestFunction('dashboard/chart/visits'),
	getAPICalls: createAnalyticsRequestFunction('dashboard/chart/apicalls'),
	getReportHits: createAnalyticsRequestFunction('dashboard/chart/reporthits'),
	getPremiumReportHits: createAnalyticsRequestFunction('dashboard/chart/premiumreporthits'),
	getCompetition: createAnalyticsRequestFunction('dashboard/chart/competition'),
	getAdvertising: createAnalyticsRequestFunction('dashboard/chart/advertising'),
	getDemographics: createAnalyticsRequestFunction('dashboard/chart/demographics'),
	getOSTypes: createAnalyticsRequestFunction('dashboard/chart/ostypes'),
	getBrowserTypes: createAnalyticsRequestFunction('dashboard/chart/browsertypes'),
	getOccupations: createAnalyticsRequestFunction('dashboard/chart/occupations'),
	getLocations: createAnalyticsRequestFunction('dashboard/chart/locations'),
	getLanguages: createAnalyticsRequestFunction('dashboard/chart/languages'),
	getIndustries: createAnalyticsRequestFunction('dashboard/chart/industries'),
	getPulseLoads: createAnalyticsRequestFunction('pulse/loads'),
	getPulseLocations: createAnalyticsRequestFunction('pulse/locations'),
	getPulseIndustries: createAnalyticsRequestFunction('pulse/industries'),
	getSBAViews: createAnalyticsRequestFunction('sba/views'),
	getSBALocations: createAnalyticsRequestFunction('sba/locations'),
	getSBAIndustries: createAnalyticsRequestFunction('sba/industries'),
	getShopLocalLoads: createAnalyticsRequestFunction('shoplocal/loads'),
	getShopLocalFDUCount: createAnalyticsRequestFunction('shoplocal/fducount'),
	getShopLocalBusinessViews: createAnalyticsRequestFunction('shoplocal/businessviews'),
	getSigninsNew: createAnalyticsRequestFunction('dashboard/chart/signins/new'),
	getSigninsAll: createAnalyticsRequestFunction('dashboard/chart/signins/all'),
	getAuthPrompts: createAnalyticsRequestFunction('dashboard/chart/authprompts'),
	getAuthSkips: createAnalyticsRequestFunction('dashboard/chart/authskips'),
	getSurveyLoads: createAnalyticsRequestFunction('survey/chart/surveyloads'),
	getSurveySkips: createAnalyticsRequestFunction('survey/chart/surveyskips'),
	getSurveyCompletions: createAnalyticsRequestFunction('survey/chart/surveycompletions'),
	getInsightsPageViews: createAnalyticsRequestFunction('dashboard/chart/v2/lbipageviews'),
	getInsightsReportHits: createAnalyticsRequestFunction('dashboard/chart/v2/reportHits'),
	getShopLocalVisits: createAnalyticsRequestFunction('dashboard/chart/v2/shoplocalvisits'),
	getPulseVisits: createAnalyticsRequestFunction('dashboard/chart/v2/pulsevisits'),
	getDemographicsVisits: createAnalyticsRequestFunction('dashboard/chart/v2/demographicsvisits'),
	getShopLocalLocations: createAnalyticsRequestFunction('dashboard/chart/v2/shoplocallocations'),
	getShopLocalIndustries: createAnalyticsRequestFunction('dashboard/chart/v2/shoplocalindustries'),
	getDemographicsFilterTypes: createAnalyticsRequestFunction('dashboard/chart/v2/demographicsfiltertypes'),
	getDemographicsReportTypes: createAnalyticsRequestFunction('dashboard/chart/v2/demographicsreporttypes'),
	getDeviceTypes: createAnalyticsRequestFunction('dashboard/chart/v2/devicetypes'),
	getProductSavingsCalculations: createAnalyticsRequestFunction('savingscalculation/products'),
	getIndividualSavingsCalculations: async (apiKey: string, dateFrom: string, dateTo: string, token: string) => {
		const params = new URLSearchParams({
			apiKey,
			dateFrom,
			dateTo,
		});
		const response = await fetch(`${host}savingscalculation/individualsavings?${params}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});
		if (response.ok) {
			return await response.json();
		}
		console.error('Analytics error', response);
		return await Promise.reject(response.status);
	},
};

export default analyticsService;

/*
/dashboard/chart/v2/demographicsfiltertypes
/dashboard/chart/v2/demographicsreporttypes
*/

import { Alert, Button, Card, Col, Flex, Row, Space, Statistic, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import { FileSpreadsheet, Info } from 'lucide-react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { startCase } from '../../utils';

import analyticsService from '../../service/analytics.service';
import { BarChart, LineChart, PieChart, StackedBarChart } from './AnalyticsCharts';
import { downloadHelper, processRegular, processTimeSeriesData } from './analytics-helpers';
import useAnalytics from './useAnalytics';

const { Text } = Typography;

function AnalyticsDemographics({
	apiKeys,
	startDate,
	endDate,
	token = '',
}: { apiKeys: string[]; startDate: dayjs.Dayjs; endDate: dayjs.Dayjs; token?: string }) {
	const reportTypes = [
		{ label: 'Radius', value: 2331 },
		{ label: 'Drive Time', value: 1332 },
		{ label: 'Polygon', value: 2321 },
		{ label: 'Standard Geography', value: 34234 },
	];

	const [visits, visitsLoading, visitsError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getDemographicsVisits,
		token,
		(response) => processTimeSeriesData(response, startDate, endDate),
	);

	const totalVisits = useMemo(() => visits.reduce((acc, x) => acc + x.value, 0), [visits]);

	const [demographics, demographicsLoading, demographicsError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getDemographics,
		token,
		(response) => processRegular(response, (x) => x.id),
	);

	const [mostPopularReports, mostPopularReportsLoading, mostPopularReportsError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getDemographicsReportTypes,
		token,
		(response) => {
			return [
				{ label: 'Demographic Overview', value: response.find((x) => x.id === 'Census')?.value ?? 0 },
				{
					label: 'Consumer Expenditures',
					value: response.find((x) => x.id === 'ConsumerExpenditures')?.value ?? 0,
				},
				{ label: 'Labor Force', value: response.find((x) => x.id === 'LaborForce')?.value ?? 0 },
			];
		},
	);

	const [filterTypes, filterTypesLoading, filterTypesError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getDemographicsFilterTypes,
		token,
		(response) => processRegular(response, (x) => x.id),
	);

	const download = useCallback(
		(data, name) => {
			downloadHelper(name, startDate, endDate, data);
		},
		[startDate, endDate],
	);

	if (visitsError || demographicsError || mostPopularReportsError || filterTypesError) {
		return <Alert message="Error loading data" type="error" />;
	}

	return (
		<Row gutter={[16, 16]}>
			<Col xs={24} sm={24} md={24} lg={24} xl={24}>
				<Card
					style={{ height: '100%' }}
					title={
						<Space>
							User Visits {totalVisits ? `(${totalVisits.toLocaleString()})` : ''}
							<Tooltip title="This reflects the number of times the widget loaded into a browser">
								<Info size={12} />
							</Tooltip>
						</Space>
					}
					extra={
						<Button
							icon={<FileSpreadsheet size={16} />}
							onClick={() => download(visits, 'ShopLocal User Visits')}
						>
							CSV
						</Button>
					}
					variant="borderless"
				>
					<LineChart data={visits as any} color="#ed6d85" loading={visitsLoading as boolean} />
				</Card>
			</Col>
			<Col xs={24} sm={24} md={8} lg={8} xl={8}>
				<Card
					style={{ height: '100%' }}
					title={
						<Space>
							Geographic Type of Report
							<Tooltip title="This reflects the number of sub-tab(s) that were clicked on in the Demographics module. These include Place, Boundary and around an address. This does not reflect changes between search type (demographics, labor force, or consumer expenditures) or if certain criteria were toggled on/off">
								<Info size={12} />
							</Tooltip>
						</Space>
					}
					extra={
						<Button
							icon={<FileSpreadsheet size={16} />}
							onClick={() => download(demographics, 'Demographics Modules')}
						>
							CSV
						</Button>
					}
					variant="borderless"
				>
					<BarChart
						data={demographics as any}
						title="# of views"
						color="#5d768a"
						loading={demographicsLoading as boolean}
					/>
				</Card>
			</Col>
			<Col xs={24} sm={24} md={8} lg={8} xl={8}>
				<Card
					style={{ height: '100%' }}
					title={
						<Space>
							Most Popular Reports
							<Tooltip title="This reflects the number of times a report was clicked on in the Demographics module.">
								<Info size={12} />
							</Tooltip>
						</Space>
					}
					extra={
						<Button
							icon={<FileSpreadsheet size={16} />}
							onClick={() => download(mostPopularReports, 'Demographics Most Popular Reports')}
						>
							CSV
						</Button>
					}
					variant="borderless"
					actions={
						startDate.isBefore(dayjs('2025-01-22'))
							? [
									<Text type="secondary" key="dateHint">
										* this data collection started on 01-22-2025
									</Text>,
								]
							: undefined
					}
				>
					<Row gutter={[16, 16]}>
						{mostPopularReports.map((item, index) => (
							<Col xs={24} sm={24} md={24} lg={24} xl={12} key={item.value}>
								<Statistic
									title={item.label}
									value={item.value || 0}
									valueStyle={{ color: '#3f8600' }}
									loading={mostPopularReportsLoading}
								/>
							</Col>
						))}
					</Row>
				</Card>
			</Col>
			<Col xs={24} sm={24} md={8} lg={8} xl={8}>
				<Card
					style={{ height: '100%' }}
					title={
						<Space>
							Number of reports by type
							<Tooltip title="This reflects the count for each of tools when they are opened">
								<Info size={12} />
							</Tooltip>
						</Space>
					}
					extra={
						<Button
							icon={<FileSpreadsheet size={16} />}
							onClick={() => download(reportTypes, 'Demographics Top Report Types')}
						>
							CSV
						</Button>
					}
					variant="borderless"
					actions={
						startDate.isBefore(dayjs('2025-01-22'))
							? [
									<Text type="secondary" key="dateHint">
										* this data collection started on 01-22-2025
									</Text>,
								]
							: undefined
					}
				>
					<Row gutter={[16, 16]}>
						{filterTypes.map((item, index) => (
							<Col xs={24} sm={12} md={12} lg={12} xl={12} key={item.value}>
								<Statistic
									title={item.label}
									value={item.value || 0}
									valueStyle={{ color: '#3f8600' }}
									loading={filterTypesLoading}
								/>
							</Col>
						))}
					</Row>
				</Card>
			</Col>
		</Row>
	);
}

export default AnalyticsDemographics;

import { Table, Typography } from 'antd';
import React, { useMemo } from 'react';

const { Paragraph } = Typography;

interface TranslationsTableProps {
	data: any[];
	loading: boolean;
}

export default function TranslationsTable({ data, loading }: TranslationsTableProps) {
	const columns = useMemo(
		() => [
			{
				title: 'Translation Key',
				dataIndex: 'key',
				key: 'key',
				render: (text) => <Paragraph copyable>{text}</Paragraph>,
			},
			{
				title: 'English',
				dataIndex: 'en',
				key: 'en',
				ellipsis: true,
			},
			{
				title: 'Spanish',
				dataIndex: 'es',
				key: 'es',
				ellipsis: true,
			},
		],
		[],
	);

	return (
		<div>
			<Table columns={columns} dataSource={data} loading={loading} rowKey="key" pagination={false} />
		</div>
	);
}

import { Layout, Result, Space } from 'antd';
import React from 'react';
import { useAuth } from 'react-oidc-context';
import { Navigate } from 'react-router';

import packageInfo from '../../package.json';
import { Auth } from '../components/Auth';

function SizeupLogo() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fillRule="evenodd"
			strokeLinejoin="round"
			strokeMiterlimit="2"
			clipRule="evenodd"
			viewBox="0 0 1038 415"
		>
			<path
				fill="#027aff"
				fillRule="nonzero"
				d="M997.4 237.42c0-15.904-4.453-28.628-12.723-39.442a42.622 42.622 0 00-34.352-15.904 44.53 44.53 0 00-34.989 15.268 57.254 57.254 0 00-13.359 39.441c0 15.904 4.453 29.263 12.723 39.442a44.53 44.53 0 0036.26 15.267c13.36 0 24.81-5.089 33.717-15.267a55.981 55.981 0 0013.36-38.17M904.521 414.27H865.08V151.539h39.442v19.084c15.903-15.904 33.08-23.537 52.8-23.537 23.538 0 42.623 8.27 57.254 25.446 15.904 17.176 23.538 38.805 23.538 64.887 0 25.446-7.634 46.44-22.902 63.616-15.267 17.176-34.352 25.446-57.253 25.446-19.721 0-38.17-8.27-53.437-24.174V414.27z"
			/>
			<path fill="#f6c244" d="M736.625 69.388h41.21V292.46h-41.21z" />
			<path fill="#9fcd63" d="M671.96 94.183h41.318V292.81H671.96z" />
			<path
				fill="#027aff"
				d="M801.256 45.174l-16.794 2.596a4.58 4.58 0 01-4.733-4.58c0-1.375.458-2.444 1.221-3.36l37.712-38.474a4.58 4.58 0 013.358-1.374 4.58 4.58 0 013.207 1.526l37.71 38.17c.917 1.068 1.375 2.137 1.222 3.359a4.58 4.58 0 01-4.58 4.58l-16.947-2.596v169.777c0 63.82-52.674 115.882-117.714 115.882a116.95 116.95 0 01-117.714-116.034v-95.27h41.223v95.422c0 41.223 34.352 74.812 76.338 74.812 42.14 0 76.339-33.589 76.339-74.812V45.327z"
			/>
			<g fill="#071f5b" fillRule="nonzero">
				<path d="M549 213.797c-5.725-20.993-19.085-31.808-39.441-31.808a40.714 40.714 0 00-25.447 8.906 45.803 45.803 0 00-14.631 22.902h80.155zm40.078 31.808H467.572c1.273 13.995 5.726 25.446 13.36 33.716 8.27 8.27 19.084 12.087 31.171 12.087 9.542 0 17.812-2.545 24.174-6.998 6.361-4.453 13.36-12.723 21.63-25.446l33.08 17.812a132.75 132.75 0 01-16.54 22.902 73.794 73.794 0 01-39.442 23.537c-7.634 1.909-15.268 2.545-24.174 2.545-25.446 0-45.803-8.27-61.07-24.174-15.905-16.54-23.539-38.17-23.539-65.524 0-26.718 7.634-48.347 22.902-65.523 14.631-15.904 34.352-24.174 59.162-24.174 25.446 0 44.53 7.634 59.799 23.537 13.995 15.904 21.629 38.17 21.629 66.16v8.906z" />
				<path d="M325.1 284.023h101.148v38.17H247.49L356.908 188.6H270.39v-36.897h164.763L325.1 284.023zM238.43 322.02h-38.806V151.531h38.805v170.49zM193.898 95.55c0-6.362 1.908-12.723 6.997-17.812 5.09-4.454 10.815-6.998 17.813-6.998 6.997 0 13.359 2.544 18.448 6.998 5.09 5.089 7.634 11.45 7.634 18.448 0 6.362-2.545 12.723-7.634 17.812-5.09 5.09-10.815 7.634-17.812 7.634-7.634 0-13.36-2.544-18.449-7.634a25.446 25.446 0 01-7.634-18.448M165.8 92.363l-33.08 19.084a57.254 57.254 0 00-17.812-20.993c-5.726-3.18-12.723-5.09-22.266-5.09-11.45 0-20.356 3.182-27.99 9.543A29.9 29.9 0 0053.2 118.445c0 12.723 10.178 23.537 29.263 31.807l27.355 10.815c21.629 8.906 38.169 19.72 47.711 32.444 10.178 12.723 15.268 28.627 15.268 47.075a82.7 82.7 0 01-24.81 61.707 85.245 85.245 0 01-61.707 23.538 82.7 82.7 0 01-57.89-20.993A95.423 95.423 0 01-.236 246.312l41.35-8.907a69.977 69.977 0 0010.178 33.717 42.622 42.622 0 0036.261 16.54c12.723 0 23.538-4.454 31.808-12.724a44.53 44.53 0 0012.723-31.807c0-5.726-1.272-10.815-2.545-14.632-1.272-4.453-3.817-8.906-6.361-12.723a54.073 54.073 0 00-12.723-9.542l-17.176-9.542-25.447-10.815C30.3 170.61 11.851 147.707 11.851 117.81c0-20.357 7.634-37.533 23.538-50.893C50.656 52.921 69.74 46.56 92.642 46.56c31.808 0 55.982 14.631 73.158 44.53" />
			</g>
		</svg>
	);
}

export function Intro() {
	const auth = useAuth();

	// check if user is logged in
	if (auth.user) {
		if (auth?.user?.profile?.role === 'customer_admin') {
			return <Navigate to="/analytics" />;
		}
		return <Navigate to="/all" />;
	}

	return (
		<Layout.Content style={{ padding: '2rem' }}>
			<Space size="large" align="center" direction="vertical" style={{ width: '100%', margin: '2rem' }}>
				<Result
					icon={<SizeupLogo />}
					title="admin tool"
					subTitle="manage applications settings"
					extra={[<Auth key="auth" />]}
				/>
				<div
					style={{
						position: 'absolute',
						top: '2rem',
						left: 0,
						fontSize: '8px',
						color: '#aaa',
						transform: 'rotate(-90deg)',
						transformOrigin: 'top left',
					}}
				>
					{packageInfo.version}
				</div>
			</Space>
		</Layout.Content>
	);
}

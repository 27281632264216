import { Col, Row, Select, type SelectProps, Spin, Tag } from 'antd';

import React, { useMemo } from 'react';
import toast from 'react-hot-toast';
import { useAuth } from 'react-oidc-context';
import { debounce } from 'remeda';

import DataService from '../../data.service';

const { Option } = Select;

type CustomTagProps = {
	label: React.ReactNode;
	value: any;
	disabled: boolean;
	onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	closable: boolean;
};

function TagRender(props: CustomTagProps): React.ReactElement {
	const { label, value, closable, onClose } = props;
	const onPreventMouseDown = (event) => {
		event.preventDefault();
		event.stopPropagation();
	};
	const colors = [
		'#767687', // state
		'#B2203A', // county
		'#35B', // city
	];

	// count number of dots in the string
	const colorIndex = (value || '').split(',')[0].match(/\./g)?.length;
	const color = colors[Number(colorIndex)];
	return (
		<Tag
			color={color}
			onMouseDown={onPreventMouseDown}
			closable={closable}
			onClose={onClose}
			style={{ marginRight: 3 }}
		>
			{label}
		</Tag>
	);
}

interface DebounceSelectProps {
	fetchOptions: (value: string, granularity: number, token?: string) => Promise<SelectProps['options']>;
	debounceTimeout?: number;
	granularity?: number;
	token?: string;
	[key: string]: any;
}

function DebounceSelect({
	fetchOptions,
	debounceTimeout = 800,
	granularity = 2,
	token,
	...props
}: DebounceSelectProps) {
	const [fetching, setFetching] = React.useState(false);
	const [options, setOptions] = React.useState<SelectProps['options']>([]);
	const fetchRef = React.useRef(0);
	const debounceFetcher = React.useMemo(() => {
		const loadOptions = (value) => {
			if (value === '') {
				return;
			}

			fetchRef.current += 1;
			const fetchId = fetchRef.current;
			setOptions([]);
			setFetching(true);
			fetchOptions(value, granularity, token)
				.then((newOptions) => {
					if (fetchId !== fetchRef.current) {
						// for fetch callback order
						return;
					}

					if (newOptions && newOptions.length === 0) {
						toast.error('No results found');
					}
					setOptions(newOptions);
				})
				.catch((error) => {
					toast.error('Failed to fetch areas');
				})
				.finally(() => {
					if (fetchId === fetchRef.current) {
						setFetching(false);
					}
				});
		};

		return debounce(loadOptions, { waitMs: debounceTimeout });
	}, [fetchOptions, granularity, debounceTimeout]);
	return (
		<Select
			labelInValue
			filterOption={false}
			onSearch={debounceFetcher.call}
			tagRender={TagRender}
			notFoundContent={fetching ? <Spin size="small" /> : null}
			{...props}
			options={options}
			disabled={props.disabled}
		/>
	);
} // Usage of DebounceSelect

async function fetchAreaList(areaname, granularity, token = '') {
	const areas = await DataService.regions.get({ areaname, granularity, token });
	return areas.map((area) => ({
		label: area.name,
		value: [area.seoKey, area.granularityId].join(','),
	}));
}

interface AreasSelectProps {
	value?: any[];
	onChange?: (value: any[]) => void;
	disabled?: boolean;
}

function AreasSelect(props: AreasSelectProps) {
	const auth = useAuth();
	console.log('👤👤👤', auth);
	const value = useMemo(
		() =>
			props.value?.map((x) => ({
				label: x.name,
				value: `${x.geographicLocation},${x.granularityId}`,
			})),
		[props.value],
	);
	const [granularity, setGranularity] = React.useState(2);

	return (
		<Row>
			<Col flex="90px">
				<Select
					style={{ width: 90 }}
					defaultValue={2}
					onChange={(x) => setGranularity(x)}
					disabled={props.disabled}
				>
					<Option value={2}>City</Option>
					<Option value={3}>County</Option>
					<Option value={6}>State</Option>
				</Select>
			</Col>
			<Col flex="auto">
				<DebounceSelect
					mode="multiple"
					value={value}
					placeholder="Select service areas"
					fetchOptions={fetchAreaList}
					granularity={granularity}
					token={auth?.user?.access_token}
					labelInValue
					disabled={props.disabled}
					onChange={(newValue) => {
						props.onChange?.(
							newValue.map((x) => ({
								name: x.label,
								granularityId: Number(x.value.split(',')[1]),
								geographicLocation: x.value.split(',')[0],
								seoKey: x.value.split(',')[0],
							})),
						);
					}}
					style={{
						width: '100%',
					}}
				/>
			</Col>
		</Row>
	);
}

export default AreasSelect;

import { Alert, Button, Card, Col, Flex, Row, Space, Spin, Statistic, Tooltip } from 'antd';
import React, { useCallback, useMemo } from 'react';

import dayjs from 'dayjs';
import { FileSpreadsheet, Info } from 'lucide-react';

import analyticsService from '../../service/analytics.service';
import useAnalytics from './useAnalytics';

const AnalyticsCard = ({ title, tooltip, total, onDownload, children }) => (
	<Card
		title={
			<Space>
				<span>
					{title} {total ? `(${total})` : ''}
				</span>
				<Tooltip title={tooltip}>
					<Info size={12} />
				</Tooltip>
			</Space>
		}
		extra={
			<Button icon={<FileSpreadsheet size={16} />} onClick={onDownload}>
				CSV
			</Button>
		}
		variant="borderless"
	>
		{children}
	</Card>
);

function AnalyticsSurveys({ apiKeys, startDate, endDate, token = '' }) {
	const [surveyLoads, surveyLoadsLoading, surveyLoadsError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getSurveyLoads,
		token,
		(response) => response.map((x) => ({ label: x.id, value: x.value })),
	);
	const totalSurveyLoads = useMemo(() => surveyLoads.reduce((acc, x) => acc + x.value, 0), [surveyLoads]);

	const [surveyCompletions, surveyCompletionsLoading, surveyCompletionsError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getSurveyCompletions,
		token,
		(response) => response.map((x) => ({ label: x.id, value: x.value })),
	);
	// const totalSurveyCompletions = useMemo(
	// 	() => surveyCompletions.reduce((acc, x) => acc + x.value, 0),
	// 	[surveyCompletions],
	// );

	const surveyLoadsAndCompletions = useMemo(() => {
		const res: Array<{
			label: string;
			loaded: number;
			completed: number;
			ratio: string;
			value: string;
		}> = [];

		for (const load of surveyLoads) {
			res.push({
				label: load.label,
				loaded: load.value,
				completed: 0,
				ratio: '0%',
				value: `0 / ${load.value}`,
			});
		}
		for (const completion of surveyCompletions) {
			const survey = res.find((x) => x.label === completion.label);
			if (survey) {
				survey.completed = completion.value;
				survey.ratio = `${((survey.completed / survey.loaded) * 100).toFixed(2)}%`;
				survey.value = `${survey.completed} / ${survey.loaded}`;
			}
		}
		return res;
	}, [surveyLoads, surveyCompletions]);

	const [surveySkips, surveySkipsLoading, surveySkipsError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getSurveySkips,
		token,
		(response) => response.map((x) => ({ label: x.id, value: x.value })),
	);
	const totalSurveySkips = useMemo(() => surveySkips.reduce((acc, x) => acc + x.value, 0), [surveySkips]);

	const download = useCallback(
		(data, name) => {
			const csvEscape = (str) => (str.includes(',') ? `"${str}"` : str);
			const csv = data.map((x) => `${csvEscape(x.label)},${x.value}`);
			csv.unshift('Name,Value');
			const blob = new Blob([csv.join('\n')], { type: 'text/csv' });
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = `${name}_${dayjs(startDate).format('MMM_DD_YYYY')}-${dayjs(endDate).format('MMM_DD_YYYY')}.csv`;
			a.click();
		},
		[startDate, endDate],
	);

	if (surveyLoadsError || surveyCompletionsError || surveySkipsError) {
		return <Alert message="Error loading data" type="error" />;
	}

	const isLoading = surveyLoadsLoading || surveyCompletionsLoading || surveySkipsLoading;
	const isEmpty = surveyLoads.length === 0 && surveyCompletions.length === 0 && surveySkips.length === 0;
	if (!isLoading && isEmpty) {
		return (
			<Alert
				message={
					<div>
						<p>
							If there appears to be data missing for the time period you selected it could be for a few
							reasons.
						</p>
						<ul>
							<li>Surveys were not active during the time period selected</li>
							<li>Your surveys have not been activated</li>
							<li>No surveys have been completed during the time selected</li>
						</ul>
						<p>
							In all of these cases please contact your Customer Service Manager to have this resolved at{' '}
							<a href="mailto:CustomerSuccess@sizeup.com" target="_blank" rel="noreferrer">
								CustomerSuccess@sizeup.com
							</a>
						</p>
					</div>
				}
				type="info"
			/>
		);
	}

	return (
		<Row gutter={[16, 16]}>
			<Col xs={24} sm={24} md={12} lg={12} xl={12}>
				<AnalyticsCard
					title="Survey Loads and Completions"
					tooltip="The numbers reflect the survey loads for the selected time period."
					total={totalSurveyLoads}
					onDownload={() => download(surveyLoadsAndCompletions, 'Survey Loads')}
				>
					{surveyLoadsLoading || surveyCompletionsLoading ? (
						<Flex align="center" justify="center">
							<Spin size="default" />
						</Flex>
					) : (
						<Space direction="vertical">
							{surveyLoadsAndCompletions.map((x) => (
								<Statistic
									key={x.label}
									title={x.label}
									value={`${x.completed} / ${x.loaded} completed (${x.ratio})`}
								/>
							))}
						</Space>
					)}
				</AnalyticsCard>
			</Col>
			<Col xs={24} sm={24} md={12} lg={12} xl={12}>
				<AnalyticsCard
					title="Survey Skips"
					tooltip="The numbers reflect the survey skips for the selected time period."
					total={totalSurveySkips}
					onDownload={() => download(surveySkips, 'Survey Skips')}
				>
					{surveySkipsLoading ? (
						<Flex align="center" justify="center">
							<Spin size="default" />
						</Flex>
					) : (
						<Row gutter={[16, 16]}>
							{surveySkips.map((x) => (
								<Col span={12} key={x.label}>
									<Statistic title={x.label} value={x.value} />
								</Col>
							))}
						</Row>
					)}
				</AnalyticsCard>
			</Col>
		</Row>
	);
}

export default AnalyticsSurveys;

import { Button, Col, Row, Space, Transfer, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';

import { ExternalLink, Eye } from 'lucide-react';
import type { ImageResourceModel } from '../../api';
import DataService from '../../data.service';
import type { CustomerSettingsModelPlus } from '../../types/CustomerSettingsModelPlus';
import CitySelect from './CitySelect';
import IndustrySelect from './IndustrySelect';
import SmartSetting from './SmartSetting';

const { Text } = Typography;

const KPIs = [
	{ id: 'revenue', title: 'Revenue' },
	{ id: 'yearStarted', title: 'Year Started' },
	{ id: 'salary', title: 'Salary' },
	{ id: 'employees', title: 'Employees' },
	{ id: 'revenuePerCapita', title: 'Revenue Per Capita' },
	{ id: 'turnover', title: 'Local Retention & Net Job Change' },
	{ id: 'healthcareCost', title: 'Healthcare Cost' },
	{ id: 'workersComp', title: 'Workers Comp' },
	{ id: 'competitors', title: 'Competitors' },
	{ id: 'workHours', title: 'Work Hours' },
	{ id: 'squareFootage', title: 'Physical Space' },
	{ id: 'digital', title: 'Digital' },
];

function getCode(apiKey) {
	return `<script src="https://pulse.sizeup.com/bundle.js" data-key="${apiKey}"></script>`;
}

function getCustomCode(apiKey, city, industry) {
	return `<script src="https://pulse.sizeup.com/bundle.js" data-key="${apiKey}" data-city="${city}" data-industry="${industry}"></script>`;
}

interface PulseProps {
	data: CustomerSettingsModelPlus;
	isAdmin?: boolean;
}

export default function Pulse({ data, isAdmin }: PulseProps) {
	const auth = useAuth();

	const [images, setImages] = useState<ImageResourceModel[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const [customIndustry, setCustomIndustry] = useState<string>('');
	const [customCity, setCustomCity] = useState<string>('');

	const disabledKPIs = data?.settings?.find((s) => s.keyName === 'lipdDisabledKPIs[]')?.value;
	const [targetKeys, setTargetKeys] = useState<React.Key[]>((disabledKPIs as string[]) || []);
	const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);

	const handleChange = (nextTargetKeys) => {
		setTargetKeys(nextTargetKeys);
	};

	const handleSelectChange = (sourceSelectedKeys: React.Key[], targetSelectedKeys: React.Key[]) => {
		setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
	};

	useEffect(() => {
		setIsLoading(true);

		DataService.images
			.getAll({
				token: auth.user?.access_token,
			})
			.then((images) => {
				setImages(images);
				setIsLoading(false);
			});
	}, [auth.user?.access_token]);

	return (
		<div>
			{isAdmin ? (
				<Row gutter={[8, 0]} wrap={false}>
					<Col flex="auto">
						<Text copyable={{ text: getCode(data.apiKey) }}>Integration Code: </Text>
					</Col>
					<Col>
						{data.apiKey ? (
							<Space.Compact>
								<Button
									type="link"
									icon={<Eye size={16} />}
									onClick={() =>
										window.Sizeup.initPopupWidget({ product: 'pulse', key: data.apiKey || '' })
									}
								>
									Inline Preview
								</Button>
								<Button
									type="link"
									icon={<ExternalLink size={16} />}
									onClick={() =>
										window.open(`https://${data.apiKey}.instance.sizeup.com/pulse.html`, '_blank')
									}
								>
									Hosted Instance
								</Button>
								<Button
									type="link"
									icon={<ExternalLink size={16} />}
									onClick={() =>
										window.open(
											`https://pulse.sizeup.com/preview.html?apiKey=${data.apiKey}`,
											'_blank',
										)
									}
								>
									External Preview
								</Button>
								<Button
									type="link"
									icon={<ExternalLink size={16} />}
									onClick={() =>
										window.open(
											`https://pulse-beta.sizeup.com/preview.html?apiKey=${data.apiKey}`,
											'_blank',
										)
									}
								>
									(Beta)
								</Button>
							</Space.Compact>
						) : null}
					</Col>
				</Row>
			) : null}
			{isAdmin ? (
				<pre style={{ background: 'white', padding: '1rem', fontSize: 'smaller' }}>{getCode(data.apiKey)}</pre>
			) : null}

			{data.settings?.map((setting, index) =>
				setting.groupTag === 'Pulse UI' ? (
					<SmartSetting
						setting={setting}
						index={index}
						key={setting.keyName}
						images={images}
						isLoading={isLoading}
						customElements={{
							'lipdDisabledKPIs[]': (
								<Transfer
									dataSource={KPIs}
									titles={['Enabled', 'Disabled']}
									targetKeys={targetKeys}
									selectedKeys={selectedKeys}
									onChange={handleChange}
									onSelectChange={handleSelectChange}
									rowKey={(record: any) => record.id}
									render={(item: any) => item.title}
									oneWay
									showSearch
									listStyle={{
										width: 350,
										height: 350,
									}}
								/>
							),
						}}
					/>
				) : null,
			)}

			{isAdmin ? (
				<React.Fragment>
					<Typography.Title level={5}>Custom Instance</Typography.Title>

					<Row gutter={[8, 0]} wrap={false}>
						<Col flex="auto">
							<CitySelect onChange={(value: string) => setCustomCity(value)} />
						</Col>
						<Col flex="auto">
							<IndustrySelect onChange={(value: string) => setCustomIndustry(value)} />
						</Col>
					</Row>

					{customCity && customIndustry && (
						<Row gutter={[8, 0]} wrap={false}>
							<Col flex="auto">
								<Text copyable={{ text: getCode(data.apiKey) }}>Integration Code: </Text>
								<pre style={{ background: 'white', padding: '1rem', fontSize: 'smaller' }}>
									{getCustomCode(data.apiKey, customCity, customIndustry)}
								</pre>
							</Col>
						</Row>
					)}
				</React.Fragment>
			) : null}
		</div>
	);
}

import { Form, Input, Modal, Select, Space } from 'antd';
import React, { useEffect } from 'react';
import SVG from 'react-inlinesvg';

import type { IconResourceModel, TagModel } from '../../api';

interface TagsEditProps {
	data?: TagModel;
	open: boolean;
	onUpdate(...args: unknown[]): unknown;
	onCancel(...args: unknown[]): unknown;
	icons: IconResourceModel[];
}

export default function TagsEdit({ data, open, onUpdate, onCancel, icons }: TagsEditProps) {
	const [form] = Form.useForm();

	useEffect(() => {
		form.setFieldsValue(data);
	}, [form, data]);

	return (
		<Modal
			open={open}
			title="Edit Tag"
			okText="Edit"
			cancelText="Cancel"
			onCancel={onCancel}
			onOk={() => {
				form.validateFields()
					.then((values) => {
						form.resetFields();

						onUpdate({
							...data,
							...values,
						});
					})
					.catch((info) => {
						console.log('Validate Failed:', info);
					});
			}}
		>
			<Form form={form} layout="vertical" name="e_edit_form">
				<Form.Item
					name="name"
					label="Name"
					rules={[
						{
							required: true,
							message: 'Please input the name',
						},
					]}
				>
					<Input placeholder="Enter a name" />
				</Form.Item>
				<Form.Item name="iconId" label="Icon">
					<Select
						placeholder="Select a icon"
						allowClear
						showSearch
						optionFilterProp="title"
						filterOption={(input, option: any) => option.title.toLowerCase().includes(input.toLowerCase())}
					>
						{icons.map((icon) => (
							<Select.Option key={icon.id} value={icon.id} title={icon.title}>
								<Space align="baseline">
									{icon.svgContent && (
										<SVG
											src={icon.svgContent}
											style={{
												width: '20px',
												height: '20px',
												verticalAlign: 'middle',
											}}
										/>
									)}
									{icon.title}
								</Space>
							</Select.Option>
						))}
					</Select>
				</Form.Item>
			</Form>
		</Modal>
	);
}

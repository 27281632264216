import { Button } from 'antd';
import { SquarePlus } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useAuth } from 'react-oidc-context';
import PageHeader from '../../components/layout/PageHeader';

import type { IconResourceModel } from '../../api';
import DataService from '../../data.service';
import IconCreate from './Create';
import IconEdit from './Edit';
import IconsList from './List';

// import tempbase from './tempbase.json';

export function Icons() {
	const auth = useAuth();
	const [createForm, setCreateForm] = useState(false);
	const [editForm, setEditForm] = useState(false);

	const [selectedRow, setSelectedRow] = useState<IconResourceModel | null>(null);

	const [data, setData] = useState<IconResourceModel[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	const onCreate = (values) => {
		console.info('Create Icon', values);
		const toastId = toast.loading('Creating Icon...');
		DataService.icons
			.add(values, {
				token: auth.user?.access_token,
			})
			.then((item: IconResourceModel) => {
				setIsLoading(false);
				setData((data) => [item, ...data]);
				toast.success('Icon Created', { id: toastId });
			})
			.catch((err) => {
				toast.error(err?.message, { id: toastId });
			});
		setCreateForm(false);
	};

	const onUpdate = (values) => {
		console.info('Update Icon', values);
		const toastId = toast.loading('Updating Icon...');
		DataService.icons
			.update(values, {
				token: auth.user?.access_token,
			})
			.then((item: IconResourceModel) => {
				setIsLoading(false);
				setData((data) => {
					const index = data.findIndex((x) => x.id === item.id);
					data[index] = { ...item };
					return [...data];
				});
				toast.success('Icon Updated', { id: toastId });
			})
			.catch((err) => {
				toast.error(err?.message, { id: toastId });
			});
		setEditForm(false);
		setSelectedRow(null);
	};

	const onRemove = ({ id }) => {
		console.info('Remove Icon', id);
		const toastId = toast.loading('Removing Icon...');
		DataService.icons
			.remove(id, {
				token: auth.user?.access_token,
			})
			.then(() => {
				setData((data) => {
					const index = data.findIndex((x) => x.id === id);
					data.splice(index, 1);
					return [...data];
				});
				toast.success('Icon Removed', { id: toastId });
			})
			.catch((err) => {
				toast.error(err?.message, { id: toastId });
			});

		setCreateForm(false);
	};

	useEffect(() => {
		setIsLoading(true);
		DataService.icons
			.getAll({ token: auth.user?.access_token })
			.then((data) => {
				setData(data);
				setIsLoading(false);
			})
			.catch((error) => {
				setError(error);
				setIsLoading(false);
			});
	}, [auth.user?.access_token]);

	if (error === 401 || error === 403) {
		toast('Your session has expired. Please sign in again.');
		auth.removeUser();
	}

	return (
		<div>
			<PageHeader
				title="Icons"
				subTitle="manage content"
				extra={[
					<Button icon={<SquarePlus size={16} />} onClick={() => setCreateForm(true)} key="1">
						Add an icon
					</Button>,
				]}
			/>

			<IconCreate
				open={createForm}
				onCreate={onCreate}
				onCancel={() => {
					setCreateForm(false);
				}}
			/>

			<IconEdit
				open={editForm}
				onUpdate={onUpdate}
				onCancel={() => {
					setEditForm(false);
					setSelectedRow(null);
				}}
				data={selectedRow}
			/>

			<IconsList
				data={data}
				onEdit={(row: IconResourceModel) => {
					setEditForm(true);
					setSelectedRow(row);
				}}
				onRemove={onRemove}
				loading={isLoading}
			/>
		</div>
	);
}

import { type ImageResourceModel, OpenAPI, ResourcesService } from '../api';

interface Image {
	creatorId: string;
	dateCreated: string;
	fileType: string;
	id: string;
	sourceUrl: string;
	thumbnailUrl: string;
	title: string;
}

async function getAll({ token }): Promise<ImageResourceModel[]> {
	OpenAPI.TOKEN = token;
	return ResourcesService.getImages();
}

async function add(data, { token }): Promise<ImageResourceModel> {
	OpenAPI.TOKEN = token;
	return ResourcesService.createImage({
		formData: data,
	});
}

async function remove(imageId, { token }): Promise<unknown> {
	OpenAPI.TOKEN = token;
	return ResourcesService.removeImage({ imageId });
}

const imagesService = {
	getAll,
	add,
	// update,
	remove,
};

export default imagesService;

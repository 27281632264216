import type { ChecklistItemModel, ChecklistModel, ChecklistStageModel } from '../../api';

type DataReducerSetAction = {
	type: 'set';
	payload: ChecklistModel[];
};

type DataReducerUpdateAction = {
	type: 'update';
	payload: ChecklistItemModel;
};

type DataReducerResetAction = {
	type: 'reset';
	payload: ChecklistModel;
};

function implementationDataReducer(
	data: ChecklistModel[],
	action: DataReducerSetAction | DataReducerUpdateAction | DataReducerResetAction,
): ChecklistModel[] {
	if (action.type === 'set') {
		if (!Array.isArray(action.payload)) {
			console.error('Invalid data');
			return data;
		}
		return action.payload;
	}
	if (action.type === 'reset') {
		if (!action.payload || !action.payload.id) {
			console.error('Invalid data');
			return data;
		}
		return data.map((x) => ({
			...x,
			...(action.payload.id === x.id ? action.payload : {}),
		}));
	}

	if (action.type === 'update') {
		if (!action.payload || !action.payload.id) {
			console.error('Invalid data');
			return data;
		}
		return data.map((x) => ({
			...x,
			stages: (x.stages ?? []).map((y: ChecklistStageModel) => ({
				...y,
				items: (y.items ?? []).map((z: ChecklistItemModel) => ({
					...z,
					...(action.payload.id === z.id ? action.payload : {}),
				})),
			})),
		}));
	}
	return data;
}

export default implementationDataReducer;

import { Alert, Button, Card, Col, Row, Space, Statistic, Tooltip } from 'antd';
import type React from 'react';
import { useCallback, useMemo } from 'react';

import dayjs from 'dayjs';
import { FileSpreadsheet, Info } from 'lucide-react';

import analyticsService from '../../service/analytics.service';
import { LineChart } from './AnalyticsCharts';
import useAnalytics from './useAnalytics';

const processData = (response, startDate, endDate): Array<{ label: string; fullLabel: string; value: number }> => {
	const data: Array<{ label: string; fullLabel: string; value: number }> = [];
	const res = response.map((x) => ({
		label: dayjs(x.date).format('MM-DD'),
		fullLabel: dayjs(x.date).format('MM-DD-YYYY'),
		value: x.value,
	}));

	for (let i = dayjs(startDate); i.isBefore(endDate); i = i.add(1, 'day')) {
		const label = i.format('MM-DD');
		const fullLabel = i.format('MM-DD-YYYY');
		const value = res.find((x) => x.label === label)?.value || 0;
		data.push({ label, fullLabel, value });
	}
	return data;
};

const AnalyticsCard = ({
	title,
	tooltip,
	data,
	total,
	loading,
	onDownload,
	color,
	children = null,
}: {
	title: string;
	tooltip: string;
	data: Array<{ label: string; fullLabel: string; value: number }>;
	total: number;
	loading: boolean;
	onDownload: () => void;
	color: string;
	children?: React.ReactNode;
}) => (
	<Card
		title={
			<Space>
				<span>
					{title} {total ? `(${total})` : ''}
				</span>
				<Tooltip title={tooltip}>
					<Info size={16} />
				</Tooltip>
			</Space>
		}
		extra={
			<Button icon={<FileSpreadsheet size={16} />} onClick={onDownload}>
				CSV
			</Button>
		}
		variant="borderless"
	>
		{children ? children : <LineChart data={data} color={color} loading={loading} integersOnly />}
	</Card>
);

function AnalyticsAuth({ apiKeys, startDate, endDate, token = '' }) {
	const [newAuth, newAuthLoading, newAuthError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getSigninsNew,
		token,
		(response) => processData(response, startDate, endDate),
	);
	const totalNewAuth = useMemo(() => newAuth.reduce((acc, x) => acc + x.value, 0), [newAuth]);

	const [allAuth, allAuthLoading, allAuthError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getSigninsAll,
		token,
		(response) => processData(response, startDate, endDate),
	);
	const totalAllAuth = useMemo(() => allAuth.reduce((acc, x) => acc + x.value, 0), [allAuth]);

	const [allAuthPrompts, allAuthPromptsLoading, allAuthPromptsError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getAuthPrompts,
		token,
		(response) => processData(response, startDate, endDate),
	);
	const totalAllAuthPrompts = useMemo(() => allAuthPrompts.reduce((acc, x) => acc + x.value, 0), [allAuthPrompts]);

	const [allAuthSkips, allAuthSkipsLoading, allAuthSkipsError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getAuthSkips,
		token,
		(response) => response.map((x) => ({ label: x.id, value: x.value })),
	);
	const totalAllAuthSkips = useMemo(() => allAuthSkips.reduce((acc, x) => acc + x.value, 0), [allAuthSkips]);

	const download = useCallback(
		(data, name) => {
			const csvEscape = (str) => (str.includes(',') ? `"${str}"` : str);
			const csv = data.map((x) => `${csvEscape(x.label)},${x.value}`);
			csv.unshift('Name,Value');
			const blob = new Blob([csv.join('\n')], { type: 'text/csv' });
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = `${name}_${dayjs(startDate).format('MMM_DD_YYYY')}-${dayjs(endDate).format('MMM_DD_YYYY')}.csv`;
			a.click();
		},
		[startDate, endDate],
	);

	if (newAuthError || allAuthError || allAuthPromptsError || allAuthSkipsError) {
		return <Alert message="Error loading data" type="error" />;
	}

	return (
		<Row gutter={[16, 16]}>
			<Col xs={24} sm={24} md={12} lg={12} xl={12}>
				<AnalyticsCard
					title="Unique Registrations"
					tooltip="This reflects the number of unique registrations for the selected time period."
					data={newAuth}
					total={totalNewAuth}
					loading={newAuthLoading}
					onDownload={() => download(newAuth, 'newAuth')}
					color="#004B95"
				/>
			</Col>
			<Col xs={24} sm={24} md={12} lg={12} xl={12}>
				<AnalyticsCard
					title="Logins"
					tooltip="The numbers reflect the summation of new and existing user logins for the selected time period."
					data={allAuth}
					total={totalAllAuth}
					loading={allAuthLoading}
					onDownload={() => download(allAuth, 'All Auth')}
					color="#38812F"
				/>
			</Col>
			<Col xs={24} sm={24} md={12} lg={12} xl={12}>
				<AnalyticsCard
					title="Auth Prompts"
					tooltip="The numbers reflect the summation of auth prompts for the selected time period."
					data={allAuthPrompts}
					total={totalAllAuthPrompts}
					loading={allAuthPromptsLoading}
					onDownload={() => download(allAuthPrompts, 'All Auth Prompts')}
					color="#115e59"
				/>
			</Col>
			<Col xs={24} sm={24} md={12} lg={12} xl={12}>
				<AnalyticsCard
					title="Auth Skips"
					tooltip="The numbers reflect the auth skip distribution for the selected time period."
					data={allAuthSkips}
					total={totalAllAuthSkips}
					loading={allAuthSkipsLoading}
					onDownload={() => download(allAuthSkips, 'All Auth Skips')}
					color="#e11d48"
				>
					<Row gutter={[16, 16]}>
						{allAuthSkips.map((x) => (
							<Col span={12} key={x.label}>
								<Statistic title={x.label} value={x.value} />
							</Col>
						))}
					</Row>
				</AnalyticsCard>
			</Col>
		</Row>
	);
}

export default AnalyticsAuth;

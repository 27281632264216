import { pipe, prop, reverse, sortBy } from 'remeda';

const host = 'https://businessdata.sizeup.com/BusinessRequests/';

async function getAll({ token }): Promise<BusinessRequest[]> {
	const [requests, stages] = await Promise.all([getRequests({ token }), getStages({ token })]);
	const stageCodes = Object.values(stages);
	const stageNames = Object.keys(stages);
	return requests.map((request) => {
		const stageText = stageNames[stageCodes.indexOf(request.reviewStageId)];
		return {
			...request,
			stageText,
		};
	});
}

async function getRequests({ token }): Promise<BusinessRequest[]> {
	const response = await fetch(`${host}`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	});

	if (response.ok) {
		const data: BusinessRequest[] = await response.json();
		return sortBy(data, [prop('dateCreated'), 'desc']);
	}
	console.error('BusinessRequests getAll error', response);
	return Promise.reject(response.status);
}

async function getStages({ token }): Promise<Record<string, number>> {
	const response = await fetch(`${host}reviewstages`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	});

	if (response.ok) {
		const data = await response.json();
		return data;
	}
	console.error('BusinessRequests getStages error', response);
	return Promise.reject(response.status);
}

async function getDetails({ token, requestId }): Promise<BusinessRequest> {
	const response = await fetch(`${host}details/${requestId}`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	});
	if (response.ok) {
		const data = await response.json();
		return data;
	}
	console.error('BusinessRequests getDetails error', requestId, response);
	return Promise.reject(response.status);
}

async function acceptRequest({ token, requestId }): Promise<boolean> {
	const searchParams = new URLSearchParams({ requestId });
	const response = await fetch(`${host}accept?${searchParams}`, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	});
	if (response.ok) {
		return true;
	}
	console.error('BusinessRequests accept error', response);
	return Promise.reject(response.status);
}

async function rejectRequest({ token, requestId }): Promise<boolean> {
	const searchParams = new URLSearchParams({ requestId });
	const response = await fetch(`${host}reject?${searchParams}`, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	});
	if (response.ok) {
		return true;
	}
	console.error('BusinessRequests reject error', response);
	return Promise.reject(response.status);
}

const businessRequestsService = {
	getAll,
	getStages,
	getDetails,
	acceptRequest,
	rejectRequest,
};

export default businessRequestsService;

async function getAll({ token }) {
	const response = await fetch('/translations.json', {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	});

	if (response.ok) {
		const data = await response.json();
		return data;
	}
	console.error('images getimages error', response);
	return Promise.reject(response.status);
}

const translationsService = {
	getAll,
};

export default translationsService;

import { type EmailBindingModel, OpenAPI, UserManagementService } from '../api';

async function getAll({ token }) {
	OpenAPI.TOKEN = token;
	return UserManagementService.getAdministrators();
}

async function getEmails(customerId, { token }): Promise<EmailBindingModel[]> {
	OpenAPI.TOKEN = token;
	const response = await UserManagementService.getAdministratorsByCustomerId({ customerId });
	return response.emailBindings || [];
}

async function updateEmails(customerId, data, { token }) {
	OpenAPI.TOKEN = token;
	const objectFormat = {};
	for (const { email, isEnabled } of data) {
		objectFormat[email] = isEnabled;
	}

	return UserManagementService.updateAdministratorsByCustomerId({ customerId, requestBody: objectFormat });
}

const usersService = {
	getAll,
	getEmails,
	updateEmails,
};

export default usersService;

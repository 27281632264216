import { Button, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useAuth } from 'react-oidc-context';
import PageHeader from '../../components/layout/PageHeader';

import { UploadIcon } from 'lucide-react';
import type { ImageResourceModel } from '../../api';
import DataService from '../../data.service';
import ImagesTable from './Table';

export function Images() {
	const auth = useAuth();

	const [isUploading, setIsUploading] = useState(false);

	const [data, setData] = useState<ImageResourceModel[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	const onRemove = ({ id }) => {
		console.log('remove icon', id);
		setIsLoading(true);
		DataService.images
			.remove(id, {
				token: auth.user?.access_token,
			})
			.then(() => {
				setIsLoading(false);
				setData((data) => {
					const index = data.findIndex((x) => x.id === id);
					data.splice(index, 1);
					return [...data];
				});
			})
			.catch((err) => {
				setIsLoading(false);
				toast.error(err.message);
			});
	};

	useEffect(() => {
		setIsLoading(true);
		DataService.images
			.getAll({ token: auth.user?.access_token })
			.then((data) => {
				console.log('🖼️ Images -> ', data);
				setData(data);
				setIsLoading(false);
			})
			.catch((error) => {
				setError(error);
				setIsLoading(false);
			});
	}, [auth.user?.access_token]);

	if (error === 401 || error === 403) {
		toast('Your session has expired. Please sign in again.');
		auth.removeUser();
	}

	return (
		<div>
			<PageHeader
				title="Images"
				subTitle="manage content"
				extra={[
					<Upload
						key="upload"
						name="File"
						action="/resources/images"
						headers={{
							Authorization: `Bearer ${auth.user?.access_token}`,
						}}
						showUploadList={false}
						data={(file) => ({
							Title: file.name,
						})}
						onChange={(info) => {
							if (info.file.status === 'uploading') {
								console.log(info.file, info.fileList);
								setIsUploading(true);
							}

							if (info.file.status === 'done') {
								toast.success(`${info.file.name} file uploaded successfully`);
								setData((data) => [info.file.response, ...data]);
								setIsUploading(false);
							} else if (info.file.status === 'error') {
								toast.error(`${info.file.name} file upload failed.`);
								setIsUploading(false);
							}
						}}
						beforeUpload={(file) => {
							const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
							if (!isJpgOrPng) {
								toast.error('You can only upload JPG/PNG file!');
							}
							const isLt2M = file.size / 1024 / 1024 < 2;
							if (!isLt2M) {
								toast.error('Image must smaller than 2MB!');
							}
							return isJpgOrPng && isLt2M;
						}}
					>
						<Button icon={<UploadIcon size={16} />} key="1" loading={isUploading}>
							Upload an image
						</Button>
					</Upload>,
				]}
			/>

			<ImagesTable data={data} onRemove={onRemove} loading={isLoading} />
		</div>
	);
}

import React, { Suspense, lazy } from 'react';

const Editor = lazy(() => import('./RichEditorModule'));

export default function RichEditor(props) {
	return (
		<Suspense fallback={<div>Loading...</div>}>
			<Editor {...props} />
		</Suspense>
	);
}

import { Card, Col, Result, Row, Select, type SelectProps, Switch } from 'antd';

import React, { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useAuth } from 'react-oidc-context';
import { Outlet, useNavigate, useOutletContext, useParams } from 'react-router';
import customersService from '../../service/customers.service';

export function ImplementationWrapper() {
	const auth = useAuth();
	const params = useParams();
	const activeCustomerId = params.customerId ? Number.parseInt(params.customerId) : undefined;
	console.log('ImplementationWrapper', activeCustomerId);

	const isAdmin = useMemo(() => {
		if (auth.user?.profile?.role === 'sizeup_admin') {
			return true;
		}
		return false;
	}, [auth.user?.profile?.role]);

	const [adminMode, setAdminMode] = useState(isAdmin);
	const [superEditMode, setSuperEditMode] = useState(false);
	const [options, setOptions] = useState<SelectProps['options']>([]);
	const [loadingOptions, setLoadingOptions] = useState<boolean>(false);

	const navigate = useNavigate();
	const filterOption = (input: string, option?: any): boolean =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	useEffect(() => {
		setLoadingOptions(true);
		customersService
			.getList({ token: auth.user?.access_token })
			.then((response) => {
				setOptions(
					response
						.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))
						.map((x) => ({
							label: `#${x.id} ${x.name}`,
							value: x.id,
						})),
				);
				if (response.find((x) => x.id === 515) && activeCustomerId !== 515) {
					navigate('/implementation/515');
				}
			})
			.catch((error) => {
				toast.error('Error fetching customer implementation list');
			})
			.finally(() => {
				setLoadingOptions(false);
			});
	}, [auth.user?.access_token]);

	return (
		<div style={{ margin: '1rem' }}>
			<Row gutter={[16, 16]} style={{ marginBottom: '1rem' }} justify="space-between">
				<Col xs={24} sm={24} md={12} lg={12} xl={12}>
					<Select
						showSearch
						filterOption={filterOption}
						loading={loadingOptions}
						style={{ width: '100%' }}
						allowClear
						placeholder="Select instance"
						options={options}
						defaultValue={activeCustomerId || undefined}
						value={activeCustomerId || undefined}
						onChange={(item: number) => {
							navigate(`/implementation/${item}`);
							// setActiveCustomer(item);
						}}
					/>
				</Col>
				<Col>
					{isAdmin ? (
						<Switch
							checkedChildren="Super Edit Mode On"
							unCheckedChildren="Super Edit Mode Off"
							checked={superEditMode}
							onChange={(checked) => {
								if (!isAdmin) {
									toast.error('You are not authorized to access admin mode');
									return;
								}
								setSuperEditMode(checked);
							}}
							style={{ marginLeft: '.5rem' }}
						/>
					) : null}
					{isAdmin ? (
						<Switch
							checkedChildren="Admin Mode"
							unCheckedChildren="Client Mode"
							checked={adminMode}
							onChange={(checked) => {
								if (!isAdmin) {
									toast.error('You are not authorized to access admin mode');
									return;
								}
								setAdminMode(checked);
							}}
							style={{ marginLeft: '.5rem' }}
						/>
					) : null}
				</Col>
			</Row>
			{loadingOptions ? (
				<Result title="Implementation Dashboard" subTitle={'Loading instances...'} />
			) : (
				<Outlet context={{ adminMode, superEditMode }} />
			)}
		</div>
	);
}

import { Button, Flex, Popconfirm, Segmented, Spin, type StepProps } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { toCamelCase } from 'remeda';

import Icon, { CheckCircleFilled, RollbackOutlined } from '@ant-design/icons';
import { useAuth } from 'react-oidc-context';
import ItemsCollapse from './ItemsCollapse';

import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import type { ChecklistItemModel, ChecklistModel, ChecklistStageModel } from '../../api';
import stageArrows from '../../assets/icons/stage-arrows.svg?react';
import stageCalendarIcon from '../../assets/icons/stage-calendar.svg?react';
import stageImages from '../../assets/icons/stage-images.svg?react';
import stageImplementationIcon from '../../assets/icons/stage-implementation.svg?react';
import stageLaunchIcon from '../../assets/icons/stage-launch.svg?react';
import stageMegaphoneIcon from '../../assets/icons/stage-megaphone.svg?react';
import stageNextStepsIcon from '../../assets/icons/stage-next-steps.svg?react';
import stagePlanIcon from '../../assets/icons/stage-plan.svg?react';
import stageStartIcon from '../../assets/icons/stage-start.svg?react';
import stageTrainIcon from '../../assets/icons/stage-train.svg?react';
import stageTrophyIcon from '../../assets/icons/stage-trophy.svg?react';
import DataService from '../../data.service';

const iconsDB = {
	start: stageStartIcon,
	plan: stagePlanIcon,
	implement: stageImplementationIcon,
	train: stageTrainIcon,
	launch: stageLaunchIcon,
	internalTrainingAndPublicLaunch: stageLaunchIcon,
	imagesAndURL: stageImages,
	'review&NextSteps': stageNextStepsIcon,
	marketing: stageMegaphoneIcon,
	contests: stageTrophyIcon,
	specialEvents: stageCalendarIcon,
	ongoing: stageArrows,
};

interface DataSourceItem {
	id: number;
	title: string;
	total: number;
	verified: number;
	items: ChecklistItemModel[];
}

function Stages({
	adminMode,
	superEditMode,
	loading,
	customerId,
	checklistId,
	stageId,
	data,
	onUpdate,
	onReset,
}: {
	adminMode: boolean;
	superEditMode: boolean;
	loading: boolean;
	customerId?: number;
	checklistId?: number;
	stageId?: number;
	data: ChecklistStageModel[];
	onUpdate: (data: ChecklistItemModel) => void;
	onReset: (data: ChecklistModel) => void;
}) {
	const auth = useAuth();
	const navigate = useNavigate();

	const isAdmin = useMemo(() => {
		return auth.user?.profile?.role === 'sizeup_admin';
	}, [auth.user?.profile?.role]);

	const dataSource: DataSourceItem[] = useMemo(
		() =>
			data.map((x, i, arr) => ({
				id: x.id || i,
				title: x.name || 'No Title',
				total: x.items?.length || 0,
				verified: x.items?.filter((y) => y.status?.keyName?.endsWith('done')).length || 0,
				items: x.items || [],
			})),
		[data],
	);

	const activeStageId = stageId || dataSource[0]?.id;

	const items = useMemo(
		() => dataSource.find((x) => x.id === activeStageId)?.items || [],
		[dataSource, activeStageId],
	);

	const segmentedOptions = useMemo(
		() =>
			dataSource.map((x) => {
				const isCurrent = activeStageId === x.id;
				const isCompleted = x.verified === x.total;
				//status?: 'wait' | 'process' | 'finish' | 'error';
				const status = isCurrent ? 'process' : isCompleted ? 'finish' : 'wait';
				const icon = iconsDB[toCamelCase(x.title)];
				const percent = Math.max(x.total ? (x.verified / x.total) * 100 : 0, 1);

				return {
					value: x.id,
					label: (
						<>
							{x.title}{' '}
							{isCompleted ? (
								<CheckCircleFilled style={{ color: '#52c41a' }} />
							) : (
								<Spin
									percent={percent}
									size="small"
									className={status === 'wait' ? 'not-active' : ''}
								/>
							)}
						</>
					),
					icon: <Icon component={icon} />,
				};
			}),
		[activeStageId, dataSource],
	);

	const onChangeSegmented = useCallback((value: number) => {
		console.log('navigate', `/implementation/${customerId}/${checklistId}/${value}`);
		navigate(`/implementation/${customerId}/${checklistId}/${value}`);
	}, []);

	const onChecklistReset = useCallback(() => {
		DataService.checklists
			.reset({
				customerId,
				checklistId,
				token: auth.user?.access_token,
			})
			.then((res) => {
				onReset(res);
				toast.success('Checklist reset successfully');
			})
			.catch((err) => {
				console.error(err);
				toast.error('Error resetting item');
			})
			.finally(() => {
				// setLoadingAction(null);
			});
	}, [customerId, checklistId, auth.user?.access_token, onUpdate, onReset]);

	if (loading) {
		return <Spin />;
	}
	return (
		<div style={{ maxWidth: '1200px' }}>
			<Flex justify="space-between" align="center">
				<Segmented
					size="large"
					options={segmentedOptions}
					value={activeStageId}
					onChange={onChangeSegmented}
					style={{ marginBottom: '1rem' }}
				/>
				{superEditMode ? (
					<Popconfirm
						title="Reset the checklist"
						description="Are you sure to reset the checklist to its initial state?"
						onConfirm={onChecklistReset}
						// onCancel={cancel}
						okText="Yes"
						cancelText="No"
					>
						<Button icon={<RollbackOutlined />} danger>
							Reset
						</Button>
					</Popconfirm>
				) : null}
			</Flex>

			<ItemsCollapse
				data={items}
				adminMode={adminMode}
				superEditMode={superEditMode}
				onUpdate={onUpdate}
				customerId={customerId || 0}
			/>
		</div>
	);
}

export default Stages;

import { Collapse, Switch } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth } from 'react-oidc-context';

import { useCountdown } from './useCountdown';

const { Panel } = Collapse;

export function Profile() {
	const auth = useAuth();

	const isAdmin = useMemo(() => auth.user?.profile?.role === 'sizeup_admin', [auth.user]);

	const [isBeta, setIsBeta] = useState(false);

	//get information from local storage
	useEffect(() => {
		const customApiHost = localStorage.getItem('customApi');
		if (customApiHost === 'https://hub-beta.sizeup.com') {
			setIsBeta(true);
		}
	}, []);

	const handleSwitchChange = useCallback((checked: boolean) => {
		if (checked) {
			localStorage.removeItem('customApi');
		} else {
			localStorage.setItem('customApi', 'https://hub-beta.sizeup.com');
		}
		//reload page
		window.location.reload();
	}, []);

	const { days, hours, minutes, seconds } = useCountdown((auth.user?.expires_at || 0) * 1000);

	return (
		<div>
			<div style={{ textAlign: 'left' }}>
				<Collapse>
					<Panel header="Show Debug Info" key="1">
						<pre>{JSON.stringify(auth.user, null, 2)}</pre>
					</Panel>
				</Collapse>
			</div>

			<div style={{ textAlign: 'left' }}>
				<h3
					style={{
						display: 'inline-block',
						minWidth: '6rem',
						margin: '0.5rem',
						padding: '0.5rem',
					}}
				>
					Auth Provider
				</h3>
				<div
					style={{
						display: 'inline-block',
						margin: '0.5rem',
						padding: '0.5rem',
						backgroundColor: '#eee',
					}}
				>
					{(auth.user?.profile as any)?.idp}
				</div>
			</div>

			<div style={{ textAlign: 'left' }}>
				<h3
					style={{
						display: 'inline-block',
						minWidth: '6rem',
						margin: '0.5rem',
						padding: '0.5rem',
					}}
				>
					Email:
				</h3>
				<div
					style={{
						display: 'inline-block',
						margin: '0.5rem',
						padding: '0.5rem',
						backgroundColor: '#eee',
					}}
				>
					{auth.user?.profile?.email || 'n/a'}
				</div>
			</div>

			<div style={{ textAlign: 'left' }}>
				<h3
					style={{
						display: 'inline-block',
						minWidth: '6rem',
						margin: '0.5rem',
						padding: '0.5rem',
					}}
				>
					Active Session Time Remaining:
				</h3>
				<div
					style={{
						display: 'inline-block',
						margin: '0.5rem',
						padding: '0.5rem',
						backgroundColor: '#eee',
					}}
				>
					{days ? `${days} days ` : ''} {hours} hours {minutes} minutes {seconds} seconds
				</div>
			</div>

			{isAdmin ? (
				<div style={{ textAlign: 'left' }}>
					<h3
						style={{
							display: 'inline-block',
							minWidth: '6rem',
							margin: '0.5rem',
							padding: '0.5rem',
						}}
					>
						Hub API Version:
					</h3>
					<div
						style={{
							display: 'inline-block',
							margin: '0.5rem',
							padding: '0.5rem',
							backgroundColor: '#eee',
						}}
					>
						<Switch
							checkedChildren="Production"
							unCheckedChildren="Beta"
							checked={!isBeta}
							onChange={handleSwitchChange}
						/>
					</div>
				</div>
			) : null}
		</div>
	);
}

import React from 'react';
import packageInfo from '../../../package.json';

function CSTVersion() {
	return (
		<div
			style={{
				position: 'absolute',
				bottom: 0,
				left: 0,
				right: 0,
				padding: '20px 20px 0 20px',
				fontSize: 12,
				textAlign: 'left',
				color: '#aaa',
			}}
		>
			CST build v. {packageInfo.version}
		</div>
	);
}

export default CSTVersion;

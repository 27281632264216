import Icon, {
	ClockCircleOutlined,
	DollarOutlined,
	FallOutlined,
	PercentageOutlined,
	RiseOutlined,
} from '@ant-design/icons';
import { Card, Col, Row, Space, Statistic, Table } from 'antd';
import type dayjs from 'dayjs';
import { CirclePercent } from 'lucide-react';
import React, { useEffect, useMemo, useState } from 'react';
import { mapValues, sum, sumBy, values } from 'remeda';
import analyticsService from '../../service/analytics.service';
import useAnalytics from './useAnalytics';

const RELATIVE_VALUES = ['increaseInBusinessServed', 'businessIncrease'];

function DetailedReportAnalysis({ data, title, isLoading }) {
	const tableColumns = [
		{
			title: 'Report Type',
			dataIndex: 'title',
			key: 'title',
		},
		{
			title: 'External Cost',
			dataIndex: 'externalCost',
			key: 'externalCost',
			render: (value) => `$${value.toLocaleString()}`,
		},
		{
			title: 'Usage',
			dataIndex: 'sizeUpHits',
			key: 'sizeUpHits',
			render: (value) => value.toLocaleString(),
		},
		{
			title: 'Total Savings',
			dataIndex: 'savedAmount',
			key: 'savedAmount',
			render: (value) => `$${value.toLocaleString()}`,
		},
	];

	const tableData = useMemo(() => {
		const items = data?.costOfSavings || {};
		return Object.values(items);
	}, [data]);

	return (
		<Card type="inner" title={title}>
			<Table
				columns={tableColumns}
				dataSource={tableData}
				pagination={false}
				scroll={{ x: true }}
				loading={isLoading}
			/>
		</Card>
	);
}

function AnalyticsCalculator({
	apiKeys,
	startDate,
	endDate,
	token = '',
}: {
	apiKeys: string[];
	startDate: dayjs.Dayjs;
	endDate: dayjs.Dayjs;
	token?: string;
}) {
	const [calcValues, calcValuesLoading, calcValuesError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getProductSavingsCalculations,
		token,
		(resp) => resp,
	);

	const [individualSavings, setIndividualSavings] = useState<
		{ title: string; type: string; amount: number; key: string }[]
	>([]);
	const [individualSavingsLoading, setIndividualSavingsLoading] = useState(false);

	useEffect(() => {
		const loadIndividualSavings = async (apiKey, startDate, endDate, token) => {
			setIndividualSavingsLoading(true);
			let data: {
				[key: string]: { title: string; type: string; amount: number };
			};

			try {
				const dateFrom = startDate.format('YYYY-MM-DD');
				const dateTo = endDate.format('YYYY-MM-DD');
				data = await analyticsService.getIndividualSavingsCalculations(apiKey, dateFrom, dateTo, token);

				const res: {
					key: string;
					title: string;
					type: string;
					amount: number;
				}[] = [];
				for (const [key, value] of Object.entries(data)) {
					res.push({ key, ...value });
				}
				setIndividualSavings(res);
			} catch (error) {
				console.error('Error loading individual savings', error);
				setIndividualSavings([]);
			} finally {
				setIndividualSavingsLoading(false);
			}
		};
		if (apiKeys.length === 1) {
			loadIndividualSavings(apiKeys[0], startDate, endDate, token);
		} else {
			setIndividualSavings([]);
		}
	}, [apiKeys, startDate, endDate, token]);

	const statsValues = useMemo(() => {
		if (!calcValues || calcValues.length === 0) return null;

		const {
			insights: insightsValue,
			sbadvisor: advisorValue,
			shoplocal: shopLocalValue,
			demographics: demographicsValue,
			pulse: pulseValue,
		} = mapValues(calcValues, (value) =>
			Math.floor(sumBy(values(value.costOfSavings ?? {}), ({ savedAmount }) => savedAmount)),
		);

		const totalBusinessValue = insightsValue + advisorValue + shopLocalValue + demographicsValue + pulseValue;

		return {
			insightsValue,
			advisorValue,
			shopLocalValue,
			demographicsValue,
			pulseValue,
			totalBusinessValue,
			// businessIncrease,
			// staffCostSavings,
			// outsourcingSavings,
			// marketingSavings,
			// totalOrgValue,
		};
	}, [calcValues]);

	return (
		<Space direction="vertical" size="large" style={{ width: '100%' }}>
			<Row gutter={[16, 16]}>
				<Col xs={24} xl={24}>
					<Card title="Savings to businesses" variant="borderless">
						<Space direction="vertical" style={{ width: '100%' }}>
							<Card type="inner" title="Your Savings" loading={calcValuesLoading}>
								<Row gutter={[16, 16]}>
									<Col xs={12} lg={8}>
										<Statistic
											title="Insights Value"
											value={statsValues?.insightsValue ?? 0}
											precision={0}
											prefix={<DollarOutlined />}
										/>
									</Col>
									<Col xs={12} lg={8}>
										<Statistic
											title="Advisor Value"
											value={statsValues?.advisorValue ?? 0}
											precision={0}
											prefix={<DollarOutlined />}
										/>
									</Col>
									<Col xs={12} lg={8}>
										<Statistic
											title="Shop Local Value"
											value={statsValues?.shopLocalValue ?? 0}
											precision={0}
											prefix={<DollarOutlined />}
										/>
									</Col>
									<Col xs={12} lg={8}>
										<Statistic
											title="Demographics Value"
											value={statsValues?.demographicsValue ?? 0}
											precision={0}
											prefix={<DollarOutlined />}
										/>
									</Col>
									<Col xs={12} lg={8}>
										<Statistic
											title="Pulse Value"
											value={statsValues?.pulseValue ?? 0}
											precision={0}
											prefix={<DollarOutlined />}
										/>
									</Col>
								</Row>
							</Card>

							<Card type="inner" title="Total Value" loading={calcValuesLoading}>
								<Statistic
									title="Total Business Value"
									value={statsValues?.totalBusinessValue ?? 0}
									precision={0}
									prefix={<DollarOutlined />}
									valueStyle={{ color: '#3f8600' }}
								/>
							</Card>
						</Space>
					</Card>
				</Col>
			</Row>
			{apiKeys.length === 1 ? (
				<Row>
					<Col span={24}>
						<Card title="Your Organization’s Value" loading={individualSavingsLoading}>
							<Row gutter={[16, 16]}>
								{individualSavings.map((individualSaving, idx) => (
									<Col
										xs={24}
										md={idx > 0 ? 12 : 24}
										lg={idx > 0 ? 8 : 24}
										key={individualSaving.key}
									>
										<Statistic
											title={individualSaving.title}
											value={individualSaving.amount}
											precision={
												individualSaving.type === 'percentage' &&
												individualSaving.key !== 'percentOfGoalReachedForBusinessAssistance'
													? 2
													: 0
											}
											prefix={
												individualSaving.type === 'hour' ? (
													<ClockCircleOutlined />
												) : individualSaving.type === 'usd' ? (
													<DollarOutlined />
												) : individualSaving.type === 'percentage' ? (
													RELATIVE_VALUES.includes(individualSaving.key) ? (
														individualSaving.amount > 0 ? (
															<RiseOutlined />
														) : (
															<FallOutlined />
														)
													) : (
														<Icon component={CirclePercent} className="antd-lucide-icon" />
													)
												) : null
											}
											suffix={
												individualSaving.type === 'percentage' ? <PercentageOutlined /> : null
											}
											valueStyle={
												RELATIVE_VALUES.includes(individualSaving.key)
													? { color: individualSaving.amount > 0 ? '#3f8600' : '#cc0033' }
													: {}
											}
										/>
									</Col>
								))}
								{individualSavings.length === 0 ? (
									<Col span={24}>
										<p>No data available</p>
									</Col>
								) : null}
							</Row>
						</Card>
					</Col>
				</Row>
			) : null}
			<Row>
				<Col span={24}>
					<Card title="Detailed Report Analysis" loading={calcValuesLoading}>
						{calcValuesError ? <p>Error loading data</p> : null}
						{calcValues.insights ? (
							<DetailedReportAnalysis
								data={calcValues.insights}
								title="Insights"
								isLoading={calcValuesLoading}
							/>
						) : null}
						{calcValues.advisor ? (
							<DetailedReportAnalysis
								data={calcValues.advisor}
								title="SB Advisor"
								isLoading={calcValuesLoading}
							/>
						) : null}
						{calcValues.shoplocal ? (
							<DetailedReportAnalysis
								data={calcValues.shoplocal}
								title="ShopLocal"
								isLoading={calcValuesLoading}
							/>
						) : null}
						{calcValues.demographics ? (
							<DetailedReportAnalysis
								data={calcValues.demographics}
								title="Demographics"
								isLoading={calcValuesLoading}
							/>
						) : null}
						{calcValues.pulse ? (
							<DetailedReportAnalysis
								data={calcValues.pulse}
								title="Pulse"
								isLoading={calcValuesLoading}
							/>
						) : null}
					</Card>
				</Col>
			</Row>
		</Space>
	);
}

export default AnalyticsCalculator;

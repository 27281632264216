import analyticsService from './service/analytics.service';
import businessRequestsService from './service/business-requests.service';
import checklistsService from './service/checklists.service';
import customersService, { type CustomerMinimizedSettingsModelPlus } from './service/customers.service';
import ePagesService from './service/e-pages.service';
import ePartsService from './service/e-parts.service';
import eTagsService from './service/e-tags.service';
import eTopicsService, { type TopicsModelSimplified } from './service/e-topics.service';
import iconsService from './service/icons.service';
import imagesService from './service/images.service';
import regionsService from './service/regions.service';
import rolesService from './service/roles.service';
import settingsService from './service/settings.service';
import surveysService from './service/surveys.service';
import translationsService from './service/translations.service';
import usersService from './service/users.service';

const DataService = {
	analytics: analyticsService,
	businessRequests: businessRequestsService,
	checklists: checklistsService,
	customers: customersService,
	regions: regionsService,
	roles: rolesService,
	users: usersService,
	settings: settingsService,
	icons: iconsService,
	images: imagesService,
	translations: translationsService,
	entrepreneur: {
		topics: eTopicsService,
		pages: ePagesService,
		parts: ePartsService,
		tags: eTagsService,
	},
	surveys: surveysService,
};

export type { TopicsModelSimplified, CustomerMinimizedSettingsModelPlus };

export default DataService;

import { Card, Col, Flex, Form, Row, Switch, Typography } from 'antd';
import React, { useCallback } from 'react';

import type { RoleValueModel } from '../../api';
import rolesMap from '../../roles.json';
import type { CustomerSettingsModelPlus } from '../../types/CustomerSettingsModelPlus';

const { Title } = Typography;

const ucfirst = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const sections = Object.keys(rolesMap);

type RoleValueModelWithIndex = RoleValueModel & { index: number };

function CustomerRolesSection({ data, section }: { data: CustomerSettingsModelPlus; section: string }) {
	const allDefined: string[] = Object.values(rolesMap).flat();
	const roles = data.roles || [];
	const items = rolesMap[section] || roles.map((r) => r.name || '').filter((r) => !allDefined.includes(r));

	const formInstance = Form.useFormInstance();

	const cards: RoleValueModelWithIndex[] = items
		.map((roleName) => {
			const roleIndex = roles.findIndex((role) => role.name === roleName);
			return roleIndex === -1
				? null
				: {
						...roles[roleIndex],
						index: roleIndex,
					};
		})
		.filter((role) => role !== null);

	const selectedAll = Form.useWatch((values) => cards.every((card) => values?.roles?.[card.index]?.isActive));

	const toggleAll = useCallback(
		(checked) => {
			const { roles } = formInstance.getFieldsValue(['roles']);
			for (const card of cards) {
				formInstance.setFieldValue(['roles', card.index, 'isActive'], checked);
			}
		},
		[formInstance, cards],
	);

	return (
		<div
			style={{
				marginBottom: '24px',
			}}
		>
			<Flex align="center" gap="16px">
				<Title level={4}>{ucfirst(section)}</Title>
				<Switch
					style={{ marginBottom: '.5rem' }}
					checked={selectedAll}
					onChange={(checked) => toggleAll(checked)}
					checkedChildren="All"
					unCheckedChildren="All"
				/>
			</Flex>

			{cards.length > 0 ? (
				<Row gutter={[16, 16]}>
					{cards.map((role) => (
						<Col key={role.index} xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
							<Card
								title={role.displayName}
								extra={
									<Form.Item
										name={['roles', role.index, 'isActive']}
										style={{
											marginBottom: 0,
										}}
										valuePropName="checked"
									>
										<Switch />
									</Form.Item>
								}
							>
								<p
									style={{
										minHeight: '44px',
									}}
								>
									{role.description}
								</p>
							</Card>
						</Col>
					))}
				</Row>
			) : (
				<p>No roles in this section.</p>
			)}
		</div>
	);
}

export default function CustomerRoles({ data }: { data: CustomerSettingsModelPlus }) {
	return (
		<div>
			{sections.map((section) => (
				<CustomerRolesSection data={data} section={section} key={section} />
			))}
			<CustomerRolesSection data={data} section="other" key="other" />
		</div>
	);
}

import { Form, Input, Modal, Select, Space } from 'antd';
import React from 'react';
import SVG from 'react-inlinesvg';
import type { IconResourceModel } from '../../api';

interface TagsCreateProps {
	open: boolean;
	onCreate(...args: unknown[]): unknown;
	onCancel(...args: unknown[]): unknown;
	icons: IconResourceModel[];
}

export default function TagsCreate({ open, onCreate, onCancel, icons }: TagsCreateProps) {
	const [form] = Form.useForm();
	return (
		<Modal
			open={open}
			title="Create a new tag"
			okText="Create"
			cancelText="Cancel"
			onCancel={onCancel}
			onOk={() => {
				form.validateFields()
					.then((values) => {
						form.resetFields();
						onCreate(values);
					})
					.catch((info) => {
						console.log('Validate Failed:', info);
					});
			}}
		>
			<Form
				form={form}
				layout="vertical"
				name="e_create_form"
				initialValues={{
					name: '',
					iconId: '',
				}}
			>
				<Form.Item
					name="name"
					label="Name"
					rules={[
						{
							required: true,
							message: 'Please input the name',
						},
					]}
				>
					<Input placeholder="Enter a Tag name" />
				</Form.Item>
				<Form.Item name="iconId" label="Icon">
					<Select
						placeholder="Select a icon"
						allowClear
						showSearch
						optionFilterProp="title"
						filterOption={(input, option: any) => option.title.toLowerCase().includes(input.toLowerCase())}
					>
						{icons?.map((icon) => (
							<Select.Option key={icon.id} value={icon.id} title={icon.title}>
								<Space align="baseline">
									{icon.svgContent && (
										<SVG
											src={icon.svgContent}
											style={{
												width: '20px',
												height: '20px',
												verticalAlign: 'middle',
											}}
										/>
									)}
									{icon.title}
								</Space>
							</Select.Option>
						))}
					</Select>
				</Form.Item>
			</Form>
		</Modal>
	);
}

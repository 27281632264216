import { type DisplayOrder, OpenAPI, type PageModel, PagesEAdminService } from '../api';

async function getAll({ token }): Promise<PageModel[]> {
	OpenAPI.TOKEN = token;
	return PagesEAdminService.getPages();
}

async function add(data: PageModel, { token }): Promise<PageModel> {
	OpenAPI.TOKEN = token;
	return PagesEAdminService.createPage({ requestBody: data });
}

async function update(data: PageModel, { token }): Promise<PageModel> {
	OpenAPI.TOKEN = token;
	return PagesEAdminService.updatePage({ requestBody: data });
}

async function remove(pageId: number, { token }): Promise<unknown> {
	OpenAPI.TOKEN = token;
	return PagesEAdminService.removePage({ pageId });
}

async function updateOrder(data: DisplayOrder[], { token }): Promise<unknown> {
	OpenAPI.TOKEN = token;
	return PagesEAdminService.sortPages({ requestBody: data });
}

const ePagesService = {
	getAll,
	add,
	update,
	remove,
	updateOrder,
};

export default ePagesService;

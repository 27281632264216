import { Button, Popconfirm, Typography } from 'antd';
import { PencilLine, Trash } from 'lucide-react';
import prettyBytes from 'pretty-bytes';
import React, { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useAuth } from 'react-oidc-context';

import type { IconResourceModel, PageModel, TopicModel } from '../../api';
import Sortable from '../../components/Sortable';
import DataService from '../../data.service';
import PageCreate from './Pages-Create';
// import tempbase from './pages-db';
import PageEdit from './Pages-Edit';

const { Paragraph } = Typography;

interface PagesTableProps {
	data?: PageModel[];
	onEdit(...args: unknown[]): unknown;
	onRemove(...args: unknown[]): unknown;
	onOrderUpdate?(...args: unknown[]): unknown;
	loading: boolean;
	icons: IconResourceModel[];
	topics?: TopicModel[];
}

function PagesTable({ data, onEdit, onRemove, onOrderUpdate, loading, icons }: PagesTableProps) {
	const columns = useMemo(
		() => [
			{
				key: 'sort',
			},
			{
				title: 'Id',
				dataIndex: 'id',
				key: 'id',
			},
			{
				title: 'Name',
				dataIndex: 'name',
				key: 'name',
				render: (text, record) => (
					<div>
						{text}
						{record.hasProduct ? ' ★' : ''}
					</div>
				),
			},
			{
				title: 'Description Size',
				dataIndex: 'description',
				key: 'description',
				render: (text) => <Paragraph>{prettyBytes(text?.length || 0)}</Paragraph>,
			},
			{
				title: 'Actions',
				dataIndex: 'id',
				key: 'actions',
				render: (text, record) => (
					<div>
						<Button onClick={() => onEdit(record)} icon={<PencilLine size={16} />} type="link" />
						<Popconfirm title="Sure to delete?" onConfirm={() => onRemove(record)}>
							<Button icon={<Trash size={16} />} type="link" danger />
						</Popconfirm>
					</div>
				),
			},
		],
		[onEdit, onRemove],
	);

	return (
		<div>
			<Sortable
				columns={columns}
				dataSource={data}
				loading={loading}
				rowKey="id"
				pagination={false}
				onOrderUpdate={onOrderUpdate}
			/>
		</div>
	);
}

interface PagesProps {
	createMode: boolean;
	setCreateMode(...args: unknown[]): unknown;
	icons: IconResourceModel[];
	data?: PageModel[];
	setData(...args: unknown[]): unknown;
	isLoading: boolean;
	setIsLoading(...args: unknown[]): unknown;
}

// import tempbase from './tempbase.json';

export function Pages({ data, setData, isLoading, setIsLoading, createMode, setCreateMode, icons }: PagesProps) {
	const auth = useAuth();

	const [editForm, setEditForm] = useState(false);

	const [selectedRow, setSelectedRow] = useState<PageModel | null>(null);

	const onCreate = (values) => {
		console.log('create page', values);
		const toastId = toast.loading('Creating page...');
		DataService.entrepreneur.pages
			.add(values, {
				token: auth.user?.access_token,
			})
			.then((item) => {
				setIsLoading(false);
				setData((data) => [...data, item]);
				toast.success('Page created', { id: toastId });
			})
			.catch((err) => {
				toast.error(err?.message, { id: toastId });
			});
		setCreateMode(false);
	};

	const onUpdate = (values) => {
		const toastId = toast.loading('Updating page...');
		DataService.entrepreneur.pages
			.update(values, {
				token: auth.user?.access_token,
			})
			.then((item) => {
				setIsLoading(false);
				setData((data) => {
					const index = data.findIndex((x) => x.id === item.id);
					data[index] = { ...item };
					return [...data];
				});
				toast.success('Page updated', { id: toastId });
			})
			.catch((err) => {
				toast.error(err?.message, { id: toastId });
			});
		setEditForm(false);
		setSelectedRow(null);
	};

	const onRemove = ({ id }) => {
		const toastId = toast.loading('Removing page...');
		DataService.entrepreneur.pages
			.remove(id, {
				token: auth.user?.access_token,
			})
			.then(() => {
				setIsLoading(false);
				setData((data) => {
					const index = data.findIndex((x) => x.id === id);
					data.splice(index, 1);
					return [...data];
				});
				toast.success('Page removed', { id: toastId });
			})
			.catch((err) => {
				setIsLoading(false);
				toast.error(err?.message, { id: toastId });
			});

		setCreateMode(false);
	};

	const onOrderChange = (newOrder) => {
		setIsLoading(true);
		DataService.entrepreneur.pages
			.updateOrder(newOrder, {
				token: auth.user?.access_token,
			})
			.then(() => {
				setIsLoading(false);
			});
	};

	return (
		<div>
			<PageCreate
				open={createMode}
				onCreate={onCreate}
				onCancel={() => {
					setCreateMode(false);
				}}
			/>

			{selectedRow ? (
				<PageEdit
					open={editForm}
					onUpdate={onUpdate}
					onCancel={() => {
						setEditForm(false);
						setSelectedRow(null);
					}}
					data={selectedRow}
					// icons={icons}
				/>
			) : null}

			<PagesTable
				data={data}
				onEdit={(row: PageModel) => {
					setEditForm(true);
					setSelectedRow(row);
				}}
				onRemove={onRemove}
				onOrderUpdate={onOrderChange}
				loading={isLoading}
				icons={icons}
				// rowKey="id"
			/>
		</div>
	);
}

import { Button, Col, Row, Typography } from 'antd';

import React from 'react';

import type { CustomerSettingsModelPlus } from '../../types/CustomerSettingsModelPlus';
import SmartSetting from './SmartSetting';

const { Text } = Typography;

interface MetaProps {
	data: CustomerSettingsModelPlus;
}

export default function Meta({ data }: MetaProps) {
	return (
		<div>
			{data.settings?.map((setting, index) =>
				setting.groupTag === 'Meta' ? (
					<SmartSetting setting={setting} index={index} key={setting.keyName} />
				) : null,
			)}
		</div>
	);
}

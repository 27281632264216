import { Button, Tabs } from 'antd';
import { SquarePlus } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useAuth } from 'react-oidc-context';
import PageHeader from '../../components/layout/PageHeader';

import { type IconResourceModel, type PageModel, type PartModel, type TagModel, TopicModel } from '../../api';
import DataService, { type TopicsModelSimplified } from '../../data.service';
import { Pages } from './Pages';
import { Parts } from './Parts';
import { Tags } from './Tags';
import { Topics } from './Topics';

export function Entrepreneur() {
	const auth = useAuth();

	const [createForm, setCreateForm] = useState(false);

	const [icons, setIcons] = useState<IconResourceModel[]>([]);
	const [parts, setParts] = useState<PartModel[]>([]);
	const [topics, setTopics] = useState<TopicsModelSimplified[]>([]);
	const [pages, setPages] = useState<PageModel[]>([]);
	const [tags, setTags] = useState<TagModel[]>([]);

	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		if (!auth.user) {
			return;
		}

		setIsLoading(true);

		const token = auth.user.access_token;

		Promise.all([
			DataService.icons.getAll({ token }),
			DataService.entrepreneur.parts.getAll({ token }),
			DataService.entrepreneur.topics.getAll({ token }),
			DataService.entrepreneur.pages.getAll({ token }),
			DataService.entrepreneur.tags.getAll({ token }),
		])
			.then(([icons, parts, topics, pages, tags]) => {
				console.log('CST Data', {
					icons,
					parts,
					topics,
					pages,
					tags,
				});
				setIcons(icons);
				setParts(parts);
				setTopics(topics);
				setPages(pages);
				setTags(tags);
				setError(null);
			})
			.catch((err) => {
				setError(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [auth.user]);

	if (error === 401 || error === 403) {
		toast('Your session has expired. Please sign in again.');
		auth.removeUser();
	}

	return (
		<div>
			<PageHeader
				title="SB Advisor"
				subTitle="manage content"
				extra={[
					<Button icon={<SquarePlus size={16} />} onClick={() => setCreateForm(true)} key="1">
						Create SBA Content
					</Button>,
				]}
			/>

			<Tabs
				defaultActiveKey="1"
				centered={false}
				destroyInactiveTabPane
				items={[
					{
						key: '1',
						label: 'Parts',
						children: (
							<Parts
								createMode={createForm}
								setCreateMode={setCreateForm}
								icons={icons}
								data={parts}
								setData={setParts}
								isLoading={isLoading}
								setIsLoading={setIsLoading}
								// changeOrder={changeOrder}
							/>
						),
					},
					{
						key: '2',
						label: 'Topics',
						children: (
							<Topics
								createMode={createForm}
								setCreateMode={setCreateForm}
								icons={icons}
								tags={tags}
								parts={parts}
								pages={pages}
								data={topics}
								setData={setTopics}
								isLoading={isLoading}
								setIsLoading={setIsLoading}
								// changeOrder={changeOrder}
							/>
						),
					},
					{
						key: '3',
						label: 'Pages',
						children: (
							<Pages
								createMode={createForm}
								setCreateMode={setCreateForm}
								icons={icons}
								data={pages}
								setData={setPages}
								isLoading={isLoading}
								setIsLoading={setIsLoading}
								// changeOrder={changeOrder}
							/>
						),
					},
					{
						key: '4',
						label: 'Tags',
						children: (
							<Tags
								createMode={createForm}
								setCreateMode={setCreateForm}
								data={tags}
								icons={icons}
								setData={setTags}
								isLoading={isLoading}
								setIsLoading={setIsLoading}
							/>
						),
					},
				]}
			/>
		</div>
	);
}

import {
	type CreateRoleDto,
	type DisplayOrder,
	OpenAPI,
	type RoleModel,
	RolesService,
	type UpdateRoleDto,
} from '../api';

async function getAll({ token }): Promise<RoleModel[]> {
	OpenAPI.TOKEN = token;
	return RolesService.getRoles();
}

async function add(data: CreateRoleDto, { token }): Promise<RoleModel> {
	OpenAPI.TOKEN = token;
	return RolesService.createRole({ requestBody: data });
}

async function update(data: UpdateRoleDto, { token }): Promise<RoleModel> {
	OpenAPI.TOKEN = token;
	return RolesService.updateRole({ requestBody: data });
}

async function updateOrder(data: DisplayOrder[], { token }) {
	OpenAPI.TOKEN = token;
	return RolesService.sortRoles({ requestBody: data });
}

const rolesService = {
	getAll,
	add,
	update,
	updateOrder,
};

export default rolesService;

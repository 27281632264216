import { type ChecklistItemModel, type ChecklistModel, ChecklistsService, OpenAPI } from '../api';

async function getCustomerChecklists({ customerId, token }): Promise<ChecklistModel[]> {
	OpenAPI.TOKEN = token;
	return ChecklistsService.getCustomerChecklists({ customerId });
}

async function setInput({ customerId, itemId, value, onBehalfOfClientMode, token }): Promise<unknown> {
	OpenAPI.TOKEN = token;
	return ChecklistsService.inputChecklistItem({
		customerId,
		itemId,
		input: value,
		onBehalfOfClientMode,
	});
}

async function uploadFile({
	customerId,
	itemId,
	file,
	token,
	fileName,
	fileType,
	onBehalfOfClientMode,
}): Promise<unknown> {
	OpenAPI.TOKEN = token;
	return ChecklistsService.inputFileChecklistItem({
		formData: {
			CustomerId: customerId,
			ItemId: itemId,
			Title: fileName,
			CIDFileInputType: fileType,
			File: file,
			OnBehalfOfClientMode: onBehalfOfClientMode,
		},
	});
}

async function verify({ customerId, itemId, isVerified, token }): Promise<unknown> {
	OpenAPI.TOKEN = token;
	return ChecklistsService.verifyChecklistItem({
		customerId,
		itemId,
		isVerified,
	});
}

async function transitionTo({
	customerId,
	itemId,
	transitionTo,
	onBehalfOfClientMode,
	token,
}): Promise<ChecklistItemModel> {
	console.log('transitionTo', { customerId, itemId, transitionTo, onBehalfOfClientMode, token });
	OpenAPI.TOKEN = token;
	return ChecklistsService.transitionItemStatus({
		customerId,
		itemId,
		onBehalfOfClientMode,
		transitionTo,
	});
}

async function reset({ customerId, checklistId, token }) {
	OpenAPI.TOKEN = token;
	return ChecklistsService.resetCustomerChecklist({
		customerId,
		checklistId,
	});
}

const checklistsService = {
	getCustomerChecklists,
	setInput,
	verify,
	transitionTo,
	reset,
	uploadFile,
};

export default checklistsService;

import { Card, Result, Spin, Tabs, type TabsProps } from 'antd';

import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useAuth } from 'react-oidc-context';

import { Activity, Briefcase, CircleDot, Gauge, Store, Users } from 'lucide-react';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import type { ChecklistItemModel, ChecklistModel, ChecklistStageModel } from '../../api';
import DataService from '../../data.service';
import Stages from './Stages';
import implementationDataReducer from './dataReducer';

export function Implementation() {
	const params = useParams();
	const navigate = useNavigate();
	const activeCustomerId = params.customerId ? Number.parseInt(params.customerId) : undefined;
	const activeChecklistId = params.checklistId ? Number.parseInt(params.checklistId) : undefined;
	const activeStageId = params.stageId ? Number.parseInt(params.stageId) : undefined;

	const auth = useAuth();

	const { adminMode, superEditMode } = useOutletContext<{
		readonly adminMode: boolean;
		readonly superEditMode: boolean;
	}>();

	const [data, updateData] = useReducer(implementationDataReducer, []);
	const [loadingData, setLoadingData] = useState(false);
	const [error, setError] = useState<string | undefined>(undefined);

	useEffect(() => {
		if (activeCustomerId) {
			setLoadingData(true);
			DataService.checklists
				.getCustomerChecklists({
					customerId: activeCustomerId,
					token: auth.user?.access_token,
				})
				.then((res) => {
					console.log('Customer Checklists', res);
					updateData({ type: 'set', payload: res });
				})
				.catch((err) => {
					setError(err.message);
					console.error(err);
				})
				.finally(() => {
					setLoadingData(false);
				});
		}
	}, [activeCustomerId, auth.user?.access_token]);

	const updateDataItem = useCallback((item: ChecklistItemModel) => {
		updateData({ type: 'update', payload: item });
	}, []);

	const resetChecklist = useCallback((item: ChecklistModel) => {
		updateData({ type: 'reset', payload: item });
	}, []);

	const icons = [
		<Gauge size={12} strokeWidth={3} key="gaugeIcon" />, //Insights
		<Briefcase size={12} strokeWidth={3} key="briefcaseIcon" />, //SBA
		<Store size={12} strokeWidth={3} key="storeIcon" />, //ShopLocal
		<Activity size={12} strokeWidth={3} key="activityIcon" />, //Pulse
		<CircleDot size={12} strokeWidth={3} key="circleIcon" />, //OnGoing
		<Gauge size={12} strokeWidth={3} key="gaugeIcon" />, //Insights
		<Users size={12} strokeWidth={3} key="usersIcon" />, //Demographics
	];

	const items: TabsProps['items'] = useMemo(
		() =>
			data.map((x, idx) => ({
				key: x.id?.toString() ?? '',
				icon: icons[idx],
				label: x.name,
				children: (
					<Stages
						adminMode={!!adminMode}
						superEditMode={!!superEditMode}
						loading={loadingData}
						checklistId={x.id}
						data={x.stages ?? []}
						customerId={activeCustomerId}
						stageId={activeStageId}
						onUpdate={updateDataItem}
						onReset={resetChecklist}
					/>
				),
			})),
		[data, loadingData, adminMode, superEditMode, activeCustomerId, activeStageId, updateDataItem],
	);

	return (
		<div>
			{error ? (
				<Result status="error" title="Error loading data" subTitle={error} />
			) : activeCustomerId ? (
				<Card>
					<Tabs
						items={items}
						activeKey={activeChecklistId?.toString()}
						onChange={(checklistId) => {
							navigate(`/implementation/${activeCustomerId}/${checklistId}`);
						}}
					/>
				</Card>
			) : loadingData ? (
				<Spin />
			) : (
				<Result title="Implementation Dashboard" />
			)}
		</div>
	);
}

import { Button } from 'antd';
import { SquarePlus } from 'lucide-react';
import React, { useEffect, useState } from 'react';
// import usePromise from "react-fetch-hook/usePromise";
import toast from 'react-hot-toast';
import { useAuth } from 'react-oidc-context';
import PageHeader from '../../components/layout/PageHeader';

import type { RoleModel } from '../../api';
import DataService from '../../data.service';
import RoleCreate from './Create';
import RoleTable from './Table';

export function Roles() {
	const auth = useAuth();
	const [createForm, setCreateForm] = useState(false);

	const [data, setData] = useState<RoleModel[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	const onCreate = (values) => {
		console.info('Create Role', values);
		const toastId = toast.loading('Creating Role...');
		DataService.roles
			.add(values, {
				token: auth.user?.access_token,
			})
			.then((item) => {
				setData((oldData) => [...oldData, item]);
				toast.success('Role Created', { id: toastId });
			})
			.catch((error) => {
				console.error(error);
				toast.error('There was an error creating the role.', { id: toastId });
			});
		setCreateForm(false);
	};

	const onUpdate = (newItem) => {
		console.info('Update Role', newItem);
		const toastId = toast.loading('Updating Role...');
		DataService.roles
			.update(newItem, {
				token: auth.user?.access_token,
			})
			.then((item) => {
				setIsLoading(false);
				setData((data) => {
					const index = data.findIndex((x) => x.id === item.id);
					data[index] = { ...item };
					return [...data];
				});
				toast.success('Role Updated', { id: toastId });
			})
			.catch((error) => {
				console.error(error);
				toast.error('There was an error updating the role.', { id: toastId });
			});
	};

	const onOrderChange = (newOrder) => {
		console.log('order change', newOrder);
		setIsLoading(true);
		DataService.roles
			.updateOrder(newOrder, {
				token: auth.user?.access_token,
			})
			.then(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		setIsLoading(true);
		DataService.roles
			.getAll({ token: auth.user?.access_token })
			.then((data) => {
				setData(data);
				setIsLoading(false);
			})
			.catch((error) => {
				setError(error);
				setIsLoading(false);
			});
	}, [auth.user?.access_token]);

	if (error === 401 || error === 403) {
		toast('Your session has expired. Please sign in again.');
		auth.removeUser();
	}

	return (
		<div>
			<PageHeader
				title="Roles"
				subTitle="manage roles"
				extra={[
					<Button icon={<SquarePlus size={16} />} onClick={() => setCreateForm(true)} key="1">
						Create a New Role
					</Button>,
				]}
			/>
			{error && <strong>Error: {error}</strong>}

			<RoleCreate
				open={createForm}
				onCreate={onCreate}
				onCancel={() => {
					setCreateForm(false);
				}}
				displayOrder={data?.length}
			/>
			<RoleTable data={data} onUpdate={onUpdate} loading={isLoading} onOrderUpdate={onOrderChange} />
		</div>
	);
}

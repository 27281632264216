import { Alert, Button, Card, Col, Row, Space, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { FileSpreadsheet, Info } from 'lucide-react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { startCase } from '../../utils';

import analyticsService from '../../service/analytics.service';
import { BarChart, LineChart, PieChart, StackedBarChart } from './AnalyticsCharts';
import useAnalytics from './useAnalytics';

function AnalyticsSBA({
	apiKeys,
	startDate,
	endDate,
	token = '',
	isSingleLocationMode = false,
}: {
	apiKeys: string[];
	startDate: dayjs.Dayjs;
	endDate: dayjs.Dayjs;
	token?: string;
	isSingleLocationMode?: boolean;
}) {
	const [views, viewsLoading, viewsError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getSBAViews,
		token,
		(response) => {
			const tags = response.tags?.map((x) => ({
				label: x.name,
				value: x.viewsCount,
			}));
			const parts = response.parts
				?.map((x) => ({ label: x.name, value: x.viewsCount }))
				.sort((a, b) => b.value - a.value);
			const topics = (response.parts || [])
				.flatMap((x) => x.topics)
				.map((x) => ({
					label: `${x.title} (${x.viewsCount})`,
					value: x.viewsCount,
				}))
				.sort((a, b) => b.value - a.value);
			return { tags, parts, topics };
		},
	);
	const tags = (views as any)?.tags || [];
	const parts = (views as any)?.parts || [];
	const topics = (views as any)?.topics || [];

	const [locations, locationsLoading, locationsError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getSBALocations,
		token,
		(response) =>
			/*
		[
			{
				"name": "Circuit Boards Manufacturing",
				"value": 5
			}, ...
		] */
			response.map((x) => ({
				label: x.name,
				fullLabel: startCase(x.name),
				value: x.value,
			})),
	);

	const [industries, industriesLoading, industriesError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getSBAIndustries,
		token,
		(response) =>
			/*
		[
			{
				"name": "Circuit Boards Manufacturing",
				"value": 5
			}, ...
		] */
			response.map((x) => ({
				label: x.name,
				fullLabel: startCase(x.name),
				value: x.value,
			})),
	);

	const download = useCallback(
		(data, name) => {
			const csvEscape = (str) => (str.includes(',') ? `"${str}"` : str);
			const csv = data.map((x) => `${csvEscape(x.label)},${x.value}`);
			csv.unshift('Name,Value');
			const blob = new Blob([csv.join('\n')], { type: 'text/csv' });
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = `${name}_${dayjs(startDate).format('MMM_DD_YYYY')}-${dayjs(endDate).format('MMM_DD_YYYY')}.csv`;
			a.click();
		},
		[startDate, endDate],
	);

	if (viewsError || locationsError || industriesError) {
		return <Alert message="Error loading data" type="error" />;
	}

	return (
		<Row gutter={[16, 16]}>
			<Col xs={24} sm={24} md={24} lg={24} xl={24}>
				<Card
					title={
						<Space>
							Most Popular Business Topics
							<Tooltip title="This reflects the number of views of the SBA content">
								<Info size={12} />
							</Tooltip>
						</Space>
					}
					extra={
						<Button
							icon={<FileSpreadsheet size={16} />}
							onClick={() => download(topics, 'Most Popular Business Topics')}
						>
							CSV
						</Button>
					}
					variant="borderless"
				>
					<PieChart data={topics} color="#e39361" loading={viewsLoading as boolean} />
				</Card>
			</Col>
			<Col xs={24} sm={24} md={12} lg={12} xl={12}>
				<Card
					title={
						<Space>
							Lifecycle
							<Tooltip title="This reflects the number of views of the SBA content">
								<Info size={12} />
							</Tooltip>
						</Space>
					}
					extra={
						<Button icon={<FileSpreadsheet size={16} />} onClick={() => download(parts, 'Lifecycle')}>
							CSV
						</Button>
					}
					variant="borderless"
				>
					<BarChart data={parts} title="SBA Parts" color="#63c9ca" loading={viewsLoading as boolean} />
				</Card>
			</Col>
			<Col xs={24} sm={24} md={12} lg={12} xl={12}>
				<Card
					title={
						<Space>
							Top Subject Categories
							<Tooltip title="This reflects the number of views of the SBA content">
								<Info size={12} />
							</Tooltip>
						</Space>
					}
					extra={
						<Button
							icon={<FileSpreadsheet size={16} />}
							onClick={() => download(tags, 'Top Subject Categories')}
						>
							CSV
						</Button>
					}
					variant="borderless"
				>
					<BarChart data={tags} title="Tags" color="#4787f7" loading={viewsLoading as boolean} />
				</Card>
			</Col>

			<Col
				xs={24}
				sm={24}
				md={isSingleLocationMode ? 24 : 12}
				lg={isSingleLocationMode ? 24 : 12}
				xl={isSingleLocationMode ? 24 : 12}
			>
				<Card
					title={
						<Space>
							Top Industries
							<Tooltip title="This reflects the number of industries that were viewed in the SBA widget">
								<Info size={12} />
							</Tooltip>
						</Space>
					}
					extra={
						<Button
							icon={<FileSpreadsheet size={16} />}
							onClick={() => download(industries, 'SB Advisor Industries')}
						>
							CSV
						</Button>
					}
					variant="borderless"
				>
					<BarChart
						data={industries.slice(0, 10) as any}
						title="Industries"
						color="#ed6d85"
						loading={industriesLoading as boolean}
					/>
				</Card>
			</Col>

			{isSingleLocationMode ? null : (
				<Col xs={24} sm={24} md={12} lg={12} xl={12}>
					<Card
						title={
							<Space>
								Top Locations
								<Tooltip title="This reflects the number of locations that were viewed in the SBA widget">
									<Info size={12} />
								</Tooltip>
							</Space>
						}
						extra={
							<Button
								icon={<FileSpreadsheet size={16} />}
								onClick={() => download(locations, 'SB Advisor Locations')}
							>
								CSV
							</Button>
						}
						variant="borderless"
					>
						<BarChart
							data={locations.slice(0, 10) as any}
							title="Locations"
							color="#e39361"
							loading={locationsLoading as boolean}
						/>
					</Card>
				</Col>
			)}
		</Row>
	);
}

export default AnalyticsSBA;

import {
	type CreateSettingDto,
	type DisplayOrder,
	OpenAPI,
	type SettingModel,
	SettingsService,
	type UpdateSettingDto,
} from '../api';

async function getAll({ token }) {
	OpenAPI.TOKEN = token;
	return SettingsService.getSettings();
}

async function add(data: CreateSettingDto, { token }): Promise<SettingModel> {
	OpenAPI.TOKEN = token;
	return SettingsService.createSetting({ requestBody: data });
}

async function update(data: UpdateSettingDto, { token }): Promise<SettingModel> {
	OpenAPI.TOKEN = token;
	return SettingsService.updateSetting({ requestBody: data });
}

async function updateOrder(data: DisplayOrder[], { token }): Promise<unknown> {
	OpenAPI.TOKEN = token;
	return SettingsService.sortSettings({ requestBody: data });
}

const settingsService = {
	getAll,
	add,
	update,
	updateOrder,
};

export default settingsService;

import { DndContext } from '@dnd-kit/core';
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Table } from 'antd';
import { Menu } from 'lucide-react';
import React, { useEffect, useState } from 'react';

function Row({ children, ...props }) {
	const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
		id: props['data-row-key'],
	});

	const style = {
		...props.style,
		transform: CSS.Transform.toString(transform),
		transition,
		...(isDragging
			? {
					position: 'relative',
					zIndex: 9999,
				}
			: {}),
	};
	return (
		<tr {...props} ref={setNodeRef} style={style} {...attributes}>
			{React.Children.map(children, (child) => {
				if (child.key === 'sort') {
					return React.cloneElement(child, {
						children: (
							<Menu
								// ref={setActivatorNodeRef}
								size={20}
								style={{
									touchAction: 'none',
									cursor: 'move',
								}}
								{...listeners}
							/>
						),
					});
				}
				return child;
			})}
		</tr>
	);
}
function Sortable(props) {
	const [dataSource, setDataSource] = useState(props.dataSource);

	useEffect(() => {
		setDataSource(props.dataSource);
	}, [props.dataSource]);

	const onDragEnd = ({ active, over }) => {
		if (active.id !== over?.id) {
			setDataSource((previous) => {
				const activeIndex = previous.findIndex((i) => i[props.rowKey] === active.id);
				const overIndex = previous.findIndex((i) => i[props.rowKey] === over?.id);

				const newArray = arrayMove(previous, activeIndex, overIndex);

				props.onOrderUpdate(
					newArray.map((item: any, index) => ({
						id: item[props.rowKey],
						order: index + 1,
					})),
				);

				return newArray;
			});
		}
	};
	return props.loading || !dataSource ? (
		<Table {...props} />
	) : (
		<DndContext onDragEnd={onDragEnd}>
			<SortableContext items={dataSource.map((i) => i[props.rowKey])} strategy={verticalListSortingStrategy}>
				<Table
					{...props}
					components={{
						body: {
							row: Row,
						},
					}}
					dataSource={dataSource}
				/>
			</SortableContext>
		</DndContext>
	);
}
export default Sortable;

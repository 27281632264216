import { Alert, Button, Card, Col, Row, Space, Tooltip } from 'antd';
import type dayjs from 'dayjs';
import { FileSpreadsheet, Info } from 'lucide-react';
import React, { useCallback, useMemo } from 'react';

import analyticsService from '../../service/analytics.service';
import { BarChart, LineChart, StackedBarChart } from './AnalyticsCharts';
import { downloadHelper, exportHandler, processRegular, processTimeSeriesData } from './analytics-helpers';
import useAnalytics from './useAnalytics';

function AnalyticsPulse({
	apiKeys,
	startDate,
	endDate,
	token = '',
	isSingleLocationMode = false,
}: {
	apiKeys: string[];
	startDate: dayjs.Dayjs;
	endDate: dayjs.Dayjs;
	token?: string;
	isSingleLocationMode?: boolean;
}) {
	// const [loads, loadsLoading, loadsError] = useAnalytics(
	// 	apiKeys,
	// 	startDate,
	// 	endDate,
	// 	analyticsService.getPulseLoads,
	// 	token,
	// 	(response) => {
	// 		const res = response.map((x) => ({
	// 			label: x.locationName,
	// 			color: x.industryName,
	// 			value: x.count,
	// 		}));
	// 		return res;
	// 	},
	// );

	// const totalLoads = useMemo(() => loads.reduce((acc, x) => acc + x.value, 0), [loads]);

	const [visits, visitsLoading, visitsError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getPulseVisits,
		token,
		(response) => processTimeSeriesData(response, startDate, endDate),
	);

	const totalVisits = useMemo(() => visits.reduce((acc, x) => acc + x.value, 0), [visits]);

	const [locations, locationsLoading, locationsError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getPulseLocations,
		token,
		(response) => processRegular(response, (x) => x.name),
	);
	const [industries, industriesLoading, industriesError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getPulseIndustries,
		token,
		(response) => processRegular(response, (x) => x.name),
	);

	const download = useCallback(
		(data, name) => {
			downloadHelper(name, startDate, endDate, data);
		},
		[startDate, endDate],
	);

	const onIndustriesDownload = useCallback(() => {
		exportHandler('pulse_industries', 'export/pulse/industries', startDate, endDate, apiKeys, token);
	}, [apiKeys, startDate, endDate, token]);

	const onLocationsDownload = useCallback(() => {
		exportHandler('pulse_locations', 'export/pulse/locations', startDate, endDate, apiKeys, token);
	}, [apiKeys, startDate, endDate, token]);

	if (locationsError || industriesError || visitsError) {
		return <Alert message="Error loading data" type="error" />;
	}

	return (
		<Row gutter={[16, 16]}>
			<Col xs={24} sm={24} md={24} lg={24} xl={24}>
				<Card
					title={
						<Space>
							User Visits {totalVisits ? `(${totalVisits.toLocaleString()})` : ''}
							<Tooltip title="This reflects the number of times the widget loaded into a browser">
								<Info size={12} />
							</Tooltip>
						</Space>
					}
					extra={
						<Button
							icon={<FileSpreadsheet size={16} />}
							onClick={() => download(visits, 'ShopLocal User Visits')}
						>
							CSV
						</Button>
					}
					variant="borderless"
				>
					<LineChart data={visits as any} color="#ed6d85" loading={visitsLoading as boolean} />
				</Card>
			</Col>
			<Col
				xs={24}
				sm={24}
				md={isSingleLocationMode ? 24 : 12}
				lg={isSingleLocationMode ? 24 : 12}
				xl={isSingleLocationMode ? 24 : 12}
			>
				<Card
					title={
						<Space>
							Top Industries
							<Tooltip title="This reflects the number of industries that were viewed in the Pulse widget">
								<Info size={12} />
							</Tooltip>
						</Space>
					}
					extra={
						<Button icon={<FileSpreadsheet size={16} />} onClick={onIndustriesDownload}>
							CSV
						</Button>
					}
					variant="borderless"
				>
					<BarChart
						data={industries.slice(0, 10) as any}
						title="Industries"
						color="#ed6d85"
						loading={industriesLoading as boolean}
					/>
				</Card>
			</Col>
			{isSingleLocationMode ? null : (
				<Col xs={24} sm={24} md={12} lg={12} xl={12}>
					<Card
						title={
							<Space>
								Top Locations
								<Tooltip title="This reflects the number of locations that were viewed in the Pulse widget">
									<Info size={12} />
								</Tooltip>
							</Space>
						}
						extra={
							<Button icon={<FileSpreadsheet size={16} />} onClick={onLocationsDownload}>
								CSV
							</Button>
						}
						variant="borderless"
					>
						<BarChart
							data={locations.slice(0, 10) as any}
							title="Locations"
							color="#e39361"
							loading={locationsLoading as boolean}
						/>
					</Card>
				</Col>
			)}
		</Row>
	);
}

export default AnalyticsPulse;

import { Button } from 'antd';

import { Eye, SquarePlus } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useAuth } from 'react-oidc-context';
import { useNavigate, useParams } from 'react-router';
import PageHeader from '../../components/layout/PageHeader';

import type { QuestionModel, SurveyModel } from '../../api';
import DataService from '../../data.service';
import SurveyCreate from './Create';
import SurveyResults from './Results';
import SurveysTable from './Table';

function Surveys() {
	const auth = useAuth();
	const [createForm, setCreateForm] = useState(false);
	const navigate = useNavigate();

	const [data, setData] = useState<SurveyModel[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	const onCreate = (values) => {
		const toastId = toast.loading('Creating Survey...');
		DataService.surveys
			.add(values, {
				token: auth.user?.access_token,
			})
			.then((item) => {
				setData((data) => [...data, item]);
				toast.success('Survey Created', { id: toastId });
			})
			.catch((error) => {
				console.error(error);
				toast.error('There was an error creating the survey.', { id: toastId });
			})
			.finally(() => {
				setCreateForm(false);
			});
	};

	const onUpdate = (values) => {
		const toastId = toast.loading('Updating Survey...', values);
		DataService.surveys
			.update(values, {
				token: auth.user?.access_token,
			})
			.then((item) => {
				setData((data) => {
					const index = data.findIndex((x) => x.id === item.id);
					data[index] = item;
					return [...data];
				});
				toast.success('Survey Updated', { id: toastId });
			})
			.catch((error) => {
				console.error(error);
				toast.error('There was an error updating the survey.', { id: toastId });
			});
	};

	const onQuestionAdd = (values) => {
		const toastId = toast.loading('Updating Question...');

		return DataService.surveys.questions
			.add(values, {
				token: auth.user?.access_token,
			})
			.then((item) => {
				setData((data) => {
					const index = data.findIndex((x) => x.id === item.surveyId);
					data[index].questions = [...(data[index].questions ?? []), item];
					return [...data];
				});
				toast.success('Question Added', { id: toastId });
			})
			.catch((error) => {
				console.error(error);
				toast.error('There was an error adding the question.', {
					id: toastId,
				});
			});
	};

	const onQuestionRemove = (values) => {
		const toastId = toast.loading('Removing Question...');

		return DataService.surveys.questions
			.remove(values.id, {
				token: auth.user?.access_token,
			})
			.then(() => {
				setData((data) => {
					const index = data.findIndex((x) => x.id === values.surveyId);
					data[index].questions = (data[index].questions ?? []).filter((x) => x.id !== values.id);
					return [...data];
				});
				toast.success('Question Removed', { id: toastId });
			})
			.catch((error) => {
				console.error(error);
				toast.error('There was an error removing the question.', {
					id: toastId,
				});
			});
	};

	const onQuestionUpdate = (question: QuestionModel): Promise<void> => {
		if (!question.id) {
			return onQuestionAdd(question);
		}

		if (question.surveyId === 0) {
			return onQuestionRemove(question);
		}

		const toastId = toast.loading('Updating Question...');

		return DataService.surveys.questions
			.update(question, {
				token: auth.user?.access_token,
			})
			.then((item) => {
				setData((data) => {
					const index = data.findIndex((x) => x.id === item.surveyId);
					data[index].questions = (data[index].questions ?? []).map((x) => {
						if (x.id === item.id) {
							return item;
						}
						return x;
					});
					return [...data];
				});
				toast.success('Question Updated', { id: toastId });
			})
			.catch((error) => {
				console.error(error);
				toast.error('There was an error updating the question.', {
					id: toastId,
				});
			});
	};

	const onRemove = ({ id }) => {
		const toastId = toast.loading('Removing Survey...');
		DataService.surveys
			.remove(id, {
				token: auth.user?.access_token,
			})
			.then(() => {
				setData((data) => {
					const index = data.findIndex((x) => x.id === id);
					data.splice(index, 1);
					return [...data];
				});
				toast.success('Survey Removed', { id: toastId });
			})
			.catch((error) => {
				console.error(error);
				toast.error('There was an error removing the survey.', { id: toastId });
			});
	};

	useEffect(() => {
		setIsLoading(true);
		DataService.surveys
			.getAll({ token: auth.user?.access_token })
			.then((data: SurveyModel[]) => {
				setData(data);
				setIsLoading(false);
			})
			.catch((error) => {
				setError(error);
				setIsLoading(false);
			});
	}, [auth.user?.access_token]);

	if (error === 401 || error === 403) {
		toast('Your session has expired. Please sign in again.');
		auth.removeUser();
	}

	return (
		<div>
			<PageHeader
				title="Surveys"
				extra={[
					<Button key="1" icon={<SquarePlus size={16} />} onClick={() => setCreateForm(true)}>
						Create a New Survey
					</Button>,
					<Button key="2" icon={<Eye size={16} />} onClick={() => navigate('/surveys/0/results')}>
						View Summary Results
					</Button>,
				]}
			/>
			<SurveyCreate
				open={createForm}
				onCreate={onCreate}
				onCancel={() => {
					setCreateForm(false);
				}}
			/>
			<SurveysTable
				data={data}
				loading={isLoading}
				onUpdate={onUpdate}
				onQuestionUpdate={onQuestionUpdate}
				onRemove={onRemove}
			/>
		</div>
	);
}

export { Surveys, SurveyResults };

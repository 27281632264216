import {
	Alert,
	Button,
	type ButtonProps,
	Card,
	Col,
	Collapse,
	type CollapseProps,
	DatePicker,
	Form,
	Image,
	Input,
	List,
	Radio,
	Result,
	Row,
	Select,
	Space,
	Switch,
	Tag,
	Tooltip,
	Typography,
	Upload,
	type UploadFile,
} from 'antd';
import type React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
	CalendarFilled,
	CheckCircleOutlined,
	ClockCircleFilled,
	FileTextFilled,
	LinkOutlined,
	MailOutlined,
	RollbackOutlined,
	YoutubeFilled,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import {
	ArrowDownToLine,
	ArrowUpToLineIcon,
	BanIcon,
	CheckCircleIcon,
	ExternalLink,
	Eye,
	EyeOff,
	ImageUp,
	MailIcon,
	PlayCircleIcon,
	RotateCcwIcon,
	SendIcon,
	XCircleIcon,
} from 'lucide-react';
import toast from 'react-hot-toast';
import { useAuth } from 'react-oidc-context';
import { uniqueBy } from 'remeda';
import type { CheckListItemStatusModel, ChecklistItemModel } from '../../api';
import DataService from '../../data.service';
import CodeField from './CodeField';
const { Text, Paragraph } = Typography;

interface StageModel {
	id: number;
	name: string;
	title: string;
	description: string;
	tooltip: string;
	value: any;
	placeholder: string;
	type: string;
	isVerifiable: boolean;
	isVerified: boolean;
	isAdmin: boolean;
	isDone: boolean;
}

type ImplementationAction =
	| 'transition-to-in-progress'
	| 'transition-to-abandon-on'
	| 'transition-to-abandon-off'
	| 'transition-to-accepted'
	| 'transition-to-rejected'
	| 'transition-to-completed'
	| 'preview:show'
	| 'preview:hide'
	| 'submit'
	| 'upload'
	| 'reset'
	| 'log';

function readingTime(text) {
	const averageWPM = 320;

	const strippedContent = text.replace(/<[^>]*>/g, ' ');

	// Remove extra whitespace and split into words
	const words = strippedContent
		.replace(/[\n\r]+/g, ' ') // Replace newlines with spaces
		.replace(/\s+/g, ' ') // Replace multiple spaces with single space
		.trim() // Remove leading/trailing whitespace
		.split(' ')
		.filter((word) => word.length > 0); // Remove empty strings

	const wordCount = words.length;
	const minutes = wordCount / averageWPM;

	const formattedAdjustedTime = minutes > 1 ? `${Math.round(minutes)} min` : 'Less than 1 min';

	return formattedAdjustedTime;
}

function StageItemElement({
	model,
	adminMode,
	superEditMode,
}: {
	model: StageModel;
	adminMode: boolean;
	superEditMode: boolean;
}) {
	const normalizeFn = useCallback(
		(value) => {
			switch (model.type) {
				case 'email':
					return value ? value.join(',') : [];
				case 'date':
					return value ? dayjs(value).add(12, 'hours').toISOString() : '';
				case 'datetime':
					return value ? value.toISOString() : '';
				case 'checkbox':
					return value ? 'true' : 'false';
				case 'imagefile':
				case 'longtext':
					return value;
				default:
					return value;
			}
		},
		[model.type],
	);

	const getValuePropsFn = useCallback(
		(value) => {
			switch (model.type) {
				case 'email':
					return { value: value ? value.split(',') : [] };
				case 'date':
				case 'datetime':
					return { value: value && dayjs(value).isValid() ? dayjs(value) : '' };
				case 'checkbox':
					return { checked: value === 'true' };
				case 'imagefile':
					if (typeof value === 'string') {
						return {
							file: {
								uid: '-1',
								name: value,
								type: 'image/png, image/jpeg',
							},
							fileList: value
								? [
										{
											uid: '-1',
											name: value,
											status: 'done',
											type: 'image/png, image/jpeg',
											thumbUrl: `/Resources/thumbnail/${value}`,
										},
									]
								: [],
						};
					}
					return value?.fileList;
				default:
					return { value };
			}
		},
		[model.type],
	);

	const isNotMyRole = model.isAdmin !== adminMode;

	const [fileList, setFileList] = useState<UploadFile[]>(
		model.value
			? [
					{
						uid: '-1',
						name: `${model.value}.png`,
						status: 'done',
						type: 'image/png, image/jpeg',
						thumbUrl: `/Resources/thumbnail/${model.value}`,
						url: `/Resources/${model.value}`,
					},
				]
			: [],
	);

	const isDisabled = !superEditMode && (isNotMyRole || model.isDone);

	return (
		<Form.Item name={model.name} normalize={normalizeFn} getValueProps={getValuePropsFn} noStyle>
			{model.type === 'email' ? (
				<Select
					mode="tags"
					disabled={isDisabled}
					placeholder={model.placeholder}
					style={{ width: '100%', minWidth: '200px' }}
				/>
			) : model.type === 'date' ? (
				<DatePicker disabled={isDisabled} format="MM/DD/YYYY" style={{ minWidth: '200px' }} />
			) : model.type === 'datetime' ? (
				<DatePicker
					disabled={isDisabled}
					format="MM/DD/YYYY hh:mm A"
					use12Hours
					showTime
					minuteStep={5}
					style={{ minWidth: '200px' }}
				/>
			) : model.type === 'imagefile' ? (
				<>
					<Upload
						listType="picture"
						accept="image/png, image/jpeg"
						beforeUpload={(file) => {
							const reader = new FileReader();
							reader.readAsDataURL(file);
							reader.onload = (e) => {
								const base64 = e.target?.result;
								setFileList([
									{
										uid: '-1',
										name: file.name,
										status: 'done',
										type: 'image/png, image/jpeg',
										thumbUrl: base64 as string,
									},
								]);
							};
							setFileList([...fileList, file]);
							return false;
						}}
						onRemove={() => setFileList([])}
						fileList={fileList}
						disabled={isDisabled}
						maxCount={1}
						showUploadList={{
							showRemoveIcon: (!isNotMyRole && !isDisabled) || superEditMode,
							showPreviewIcon: false,
							showDownloadIcon: false,
						}}
					>
						{fileList.length === 0 && !isDisabled ? (
							<Button icon={<ImageUp size={16} />} disabled={isDisabled} size="middle">
								Choose Image
							</Button>
						) : null}
					</Upload>
				</>
			) : model.type === 'longtext' ? (
				<Input.TextArea
					placeholder={model.placeholder}
					disabled={isDisabled}
					style={{ width: '100%', minWidth: '200px', height: '8rem' }}
				/>
			) : model.type === 'embedhtml' ? (
				// <Input.TextArea placeholder={model.placeholder} disabled={isDisabled} style={{ width: '100%', height: '8rem', fontFamily: 'monospace' }} />
				<CodeField placeholder={model.placeholder} disabled={isDisabled} />
			) : model.type === 'checkbox' ? (
				<Switch disabled={isDisabled} />
			) : (
				<Input
					placeholder={model.placeholder}
					type={model.type}
					disabled={isDisabled}
					style={{ width: '100%', minWidth: '200px' }}
				/>
			)}
		</Form.Item>
	);
}

function StageItemStatus({
	item,
	adminMode,
	superEditMode,
}: {
	item: ChecklistItemModel;
	adminMode: boolean;
	superEditMode: boolean;
}) {
	if (item.isAbandoned) {
		return <Tag color="error">Abandoned</Tag>;
	}

	// if (item.status.keyName === 'sizeup_todo') {
	if (item.status) {
		const statuses = [
			'client_todo',
			'sizeup_todo',
			'client_in_progress',
			'sizeup_in_progress',
			'client_ready_for_review',
			'sizeup_ready_for_review',
			'done',
		];
		const colors = [
			'warning',
			'warning',
			'processing',
			'processing',
			'processing',
			'processing',
			'success',
			'success',
		];
		const color = item.status.keyName ? colors[statuses.indexOf(item.status.keyName)] : 'default';
		return (
			<Tag color={color} icon={<CheckCircleOutlined />}>
				{item.status.title}
			</Tag>
		);
	}
}

function StageItemContent({ item, adminMode }: { item: ChecklistItemModel; adminMode: boolean }) {
	const isURL = useCallback((str: string) => {
		return str?.length > 5 && /^(http|https):\/\/[^ "]+$/.test(str);
	}, []);

	if (!item.inputValue) {
		return;
	}
	if (item.checklistItemType === 'EmbedHtml') {
		// check vimeo or youtube tags
		if (item.inputValue.includes('vimeo.com') || item.inputValue.includes('youtube.com')) {
			return <Tag icon={<YoutubeFilled />}>Video</Tag>;
		}
		const rt = readingTime(item.inputValue);
		return <Tag icon={<FileTextFilled />}>{rt}</Tag>;
	}
	if (item.checklistItemType === 'DateTime') {
		const fromNow = dayjs(item.inputValue).fromNow();
		return <Tag icon={<ClockCircleFilled />}>{fromNow}</Tag>;
	}
	if (item.checklistItemType === 'Date') {
		const fromNow = dayjs(item.inputValue).fromNow();
		return <Tag icon={<CalendarFilled />}>{fromNow}</Tag>;
	}
	if (item.checklistItemType === 'URL' && isURL(item.inputValue)) {
		return (
			<a href={item.inputValue} target="_blank" rel="noreferrer">
				<Tag icon={<LinkOutlined />}>{item.inputValue}</Tag>{' '}
			</a>
		);
	}
	if (item.checklistItemType === 'Email') {
		const mails = item.inputValue.split(',');

		return <Tag icon={<MailOutlined />}>{mails.length === 1 ? mails[0] : `${mails.length} emails`}</Tag>;
	}
	// return <Tag>{item.checklistItemType}</Tag>;
	return null;
}

function StageItemAction({
	item,
	adminMode,
	superEditMode,
	loadingAction,
	isPreviewOpen,
	isFieldsTouched,
	onAction,
}: {
	item: ChecklistItemModel;
	adminMode: boolean;
	superEditMode: boolean;
	loadingAction: ImplementationAction | null;
	isPreviewOpen: boolean;
	isFieldsTouched: boolean;
	onAction: (action: ImplementationAction) => void;
}) {
	const isNotMyRole = (item.inputPartyType === 'SizeUp') !== adminMode;
	const isDisabled = false;

	let buttons: ButtonProps[] = [];

	const isURL = useCallback((str: string) => {
		return str?.length > 5 && /^(http|https):\/\/[^ "]+$/.test(str);
	}, []);

	const isValidEmail = useCallback((str: string) => {
		return str?.length > 5 && /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4})(,[\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4})*$/.test(str);
	}, []);

	if (item.inputValue) {
		if (item.checklistItemType === 'EmbedHtml') {
			if (isPreviewOpen) {
				buttons.push({
					id: 'preview-hide',
					children: 'Hide Preview',
					icon: <EyeOff size={16} />,
					loading: false,
					disabled: false,
					type: 'primary',
					onClick: () => onAction('preview:hide'),
				});
			} else {
				buttons.push({
					id: 'preview-show',
					children: 'Preview',
					icon: <Eye size={16} />,
					loading: false,
					disabled: false,
					type: 'primary',
					onClick: () => onAction('preview:show'),
				});
			}
		}

		if (item.checklistItemType === 'URL' && isURL(item.inputValue)) {
			buttons.push({
				id: 'open-link',
				children: 'Open Link',
				icon: <ExternalLink size={16} />,
				loading: false,
				disabled: false,
				type: 'primary',
				onClick: () => (item.inputValue ? window.open(item.inputValue, '_blank') : null),
			});
		}

		if (item.checklistItemType === 'Email' && isValidEmail(item.inputValue)) {
			buttons.push({
				id: 'send-email',
				children: 'Send Email',
				icon: <MailIcon size={16} />,
				loading: false,
				disabled: false,
				type: 'primary',
				onClick: () => window.open(`mailto:${item.inputValue}`, '_blank'),
			});
		}
	}

	const status = item.status?.keyName ?? '';

	if (
		((status === 'client_todo' || status === 'client_in_progress') && adminMode === false) ||
		((status === 'sizeup_todo' || status === 'sizeup_in_progress') && adminMode === true) ||
		superEditMode
	) {
		if (item.checklistItemType === 'ImageFile') {
			buttons.push({
				id: 'upload',
				children: 'Upload',
				icon: <ImageUp size={16} />,
				loading: false,
				disabled: isDisabled,
				onClick: () => onAction('upload'),
			});
		} else {
			buttons.push({
				id: 'submit',
				children: 'Submit',
				icon: <SendIcon size={16} />,
				loading: false,
				type: 'primary',
				onClick: () => onAction('submit'),
			});
		}
	}

	if ((status === 'client_todo' && adminMode === false) || (status === 'sizeup_todo' && adminMode === true)) {
		buttons.push({
			id: 'transition-to-in-progress',
			children: 'To In Progress',
			icon: <PlayCircleIcon size={16} />,
			loading: loadingAction === 'transition-to-in-progress',
			disabled: loadingAction !== null || isDisabled,
			type: 'primary',
			onClick: () => onAction('transition-to-in-progress'),
		});
	}

	if (adminMode === false) {
		if (status === 'client_todo' || status === 'client_in_progress' || status === 'client_ready_for_review') {
			buttons.push({
				id: 'abandon-on',
				children: 'To Abandon',
				icon: <BanIcon size={16} />,
				loading: loadingAction === 'transition-to-abandon-on',
				disabled: loadingAction !== null || isDisabled,
				type: 'primary',
				onClick: () => onAction('transition-to-abandon-on'),
				danger: true,
			});
		}
	}

	if ((status === 'client_ready_for_review' && !adminMode) || (status === 'sizeup_ready_for_review' && adminMode)) {
		buttons.push({
			id: 'accept',
			children: 'Accept',
			icon: <CheckCircleIcon size={16} />,
			loading: loadingAction === 'transition-to-accepted',
			disabled: loadingAction !== null || isDisabled,
			type: 'primary',
			onClick: () => onAction('transition-to-accepted'),
		});

		buttons.push({
			id: 'reject',
			children: 'Reject',
			icon: <XCircleIcon size={16} />,
			loading: loadingAction === 'transition-to-rejected',
			disabled: loadingAction !== null || isDisabled,
			type: 'primary',
			onClick: () => onAction('transition-to-rejected'),
			danger: true,
		});
	}

	if (item.isAbandoned) {
		buttons = [
			{
				id: 'abandon-off',
				children: 'From Abandon',
				icon: <RotateCcwIcon size={16} />,
				loading: loadingAction === 'transition-to-abandon-off',
				disabled: loadingAction !== null || isDisabled,
				type: 'primary',
				onClick: () => onAction('transition-to-abandon-off'),
			},
		];
	}

	// if (adminMode && superEditMode) {
	// 	buttons.push({
	// 		id: 'reset',
	// 		children: 'Reset',
	// 		danger: true,
	// 		icon: <RollbackOutlined size={16} />,
	// 		loading: false,
	// 		onClick: () => onAction('reset'),
	// 	});
	// }

	return (
		<Space align="baseline" direction="horizontal">
			{buttons.map((btn) => (
				<Button {...btn} style={{ minWidth: '8rem', margin: '.5rem' }} key={btn.id} />
			))}
		</Space>
	);
}

const StageItemPreview = ({ item, actions }: { item: ChecklistItemModel; actions: React.ReactNode }) => {
	return (
		<Card>
			{item.checklistItemType === 'EmbedHtml' ? (
				<div>
					<div dangerouslySetInnerHTML={{ __html: item.inputValue ?? '' }} />
					{actions}
				</div>
			) : item.checklistItemType === 'ImageFile' && item.inputValue ? (
				<div style={{ marginTop: '1rem' }}>
					<Image src={`/Resources/${item.inputValue}`} height={200} alt={item.title || 'Image Preview'} />
				</div>
			) : (
				<Result title="No Content" />
			)}
		</Card>
	);
};

interface ItemsCollapseProps {
	data: ChecklistItemModel[];
	adminMode?: boolean;
	superEditMode?: boolean;
	customerId: number;
	onUpdate: (data: ChecklistItemModel) => void;
}

const ItemRow: React.FC<{
	item: ChecklistItemModel;
	adminMode: boolean;
	superEditMode: boolean;
	customerId: number;
	onUpdate: (data: ChecklistItemModel) => void;
}> = ({ item, adminMode, superEditMode, customerId, onUpdate }) => {
	const auth = useAuth();
	const [form] = Form.useForm();

	const [loadingAction, setLoadingAction] = useState<ImplementationAction | null>(null);
	const [showPreview, setShowPreview] = useState(false);

	const model: StageModel = useMemo(
		() => ({
			id: item.id ?? 0,
			name: `form-${item.id}`,
			title: item.title ?? '',
			description: item.preHTML ?? '',
			tooltip: item.tooltip ?? '',
			value: item.inputValue ?? '',
			placeholder: item.placeholder ?? '',
			type: item.checklistItemType?.toLowerCase() ?? '',
			isVerifiable: !!item.verificationPartyType,
			isVerified: false,
			isAdmin: item.inputPartyType === 'SizeUp',
			isDone: item.status?.keyName === 'done',
		}),
		[item],
	);

	const transitionItem = useCallback(
		(itemId: number, action: ImplementationAction) => {
			let transitionTo: string;
			const onBehalfOfClientMode = auth.user?.profile?.role === 'sizeup_admin' && !adminMode;

			switch (action) {
				case 'transition-to-in-progress':
					transitionTo = 'in_progress';
					break;
				case 'transition-to-abandon-on':
					transitionTo = 'abandon_on';
					break;
				case 'transition-to-abandon-off':
					transitionTo = 'abandon_off';
					break;
				case 'transition-to-accepted':
					transitionTo = 'accepted';
					break;
				case 'transition-to-rejected':
					transitionTo = 'rejected';
					break;
				case 'transition-to-completed':
					transitionTo = 'completed';
					break;
				default:
					toast.error('Invalid action');
					return;
			}

			setLoadingAction(action);
			DataService.checklists
				.transitionTo({
					customerId,
					itemId,
					transitionTo,
					onBehalfOfClientMode,
					token: auth.user?.access_token,
				})
				.then((res: ChecklistItemModel) => {
					onUpdate(res);
				})
				.catch((err) => {
					//get error message from err
					toast.error(err.body ?? 'Error transitioning item');
				})
				.finally(() => {
					setLoadingAction(null);
				});
		},
		[customerId, onUpdate, adminMode, auth.user?.access_token],
	);

	const resetItem = useCallback(
		(item: ChecklistItemModel) => {
			setLoadingAction('reset');
			DataService.checklists
				.reset({
					customerId,
					checklistId: item.id,
					token: auth.user?.access_token,
				})
				.then((res: ChecklistItemModel) => {
					console.log('Reset item', res);
					onUpdate(res);
				})
				.catch((err) => {
					console.error(err);
					toast.error('Error resetting item');
				})
				.finally(() => {
					setLoadingAction(null);
				});
		},
		[customerId, onUpdate, auth.user?.access_token],
	);

	const setInput = useCallback(
		(item: ChecklistItemModel, value: any) => {
			const onBehalfOfClientMode = auth.user?.profile?.role === 'sizeup_admin' && !adminMode;
			if (item.checklistItemType === 'ImageFile') {
				if (value?.fileList?.length) {
					const { originFileObj: file, name: fileName, type: mimeType } = value.fileList[0];
					// const fileType = mimeType === 'application/pdf' ? 'pdf' : mimeType === 'image/png' ? 'image' : null;
					const fileType =
						mimeType === 'application/pdf'
							? 'pdf'
							: mimeType === 'image/png'
								? 'image'
								: mimeType === 'image/jpeg'
									? 'image'
									: null;
					if (!fileType) {
						toast.error('Invalid file type');
						return;
					}
					setLoadingAction('upload');
					DataService.checklists
						.uploadFile({
							customerId,
							itemId: item.id,
							file,
							fileType,
							fileName,
							onBehalfOfClientMode,
							token: auth.user?.access_token,
						})
						.then((res: any) => {
							if (res.id) {
								onUpdate(res);
								toast.success('File uploaded successfully');
								return Promise.resolve(res);
							}
							return Promise.reject(new Error('Invalid response'));
						})
						.catch((err) => {
							console.error(err);
							toast.error('Error uploading file');
						})
						.finally(() => {
							setLoadingAction(null);
						});
				} else {
					toast.error(`Value for ${item.title} is required`);
				}
				return;
			}
			if (value || value === false) {
				setLoadingAction('submit');
				DataService.checklists
					.setInput({
						customerId,
						itemId: item.id,
						value,
						onBehalfOfClientMode,
						token: auth.user?.access_token,
					})
					.then((res) => {
						onUpdate(res as ChecklistItemModel);
					})
					.catch((err) => {
						console.error(err);
						toast.error('Error updating item');
					})
					.finally(() => {
						setLoadingAction(null);
					});
			} else {
				toast.error(`Value for ${item.title} is required`);
			}
		},
		[customerId, onUpdate, adminMode, auth.user?.access_token],
	);

	const onAction = useCallback(
		(
			action:
				| 'preview:show'
				| 'preview:hide'
				| 'submit'
				| 'upload'
				| 'reset'
				| 'log'
				| 'transition-to-in-progress'
				| 'transition-to-abandon-on'
				| 'transition-to-abandon-off'
				| 'transition-to-accepted'
				| 'transition-to-rejected'
				| 'transition-to-completed',
		) => {

			if (!item.id) {
				toast.error('Invalid item');
				return;
			}
			switch (action) {
				case 'preview:show':
					setShowPreview(true);
					break;
				case 'preview:hide':
					setShowPreview(false);
					break;
				case 'transition-to-in-progress':
					transitionItem(item.id, 'transition-to-in-progress');
					break;
				case 'transition-to-abandon-on':
					transitionItem(item.id, 'transition-to-abandon-on');
					break;
				case 'transition-to-abandon-off':
					transitionItem(item.id, 'transition-to-abandon-off');
					break;
				case 'transition-to-accepted':
					transitionItem(item.id, 'transition-to-accepted');
					break;
				case 'transition-to-rejected':
					transitionItem(item.id, 'transition-to-rejected');
					break;
				case 'transition-to-completed':
					transitionItem(item.id, 'transition-to-completed');
					break;
				case 'upload':
					setInput(item, form.getFieldValue(`form-${item.id}`));
					break;
				case 'reset':
					resetItem(item);
					break;
				case 'submit':
					setInput(item, form.getFieldValue(`form-${item.id}`));
					break;
				case 'log':
					console.log('Log clicked');
					toast('This feature is not yet implemented');
					break;
			}
		},
		[item, showPreview, transitionItem, setInput, resetItem],
	);

	const previewActions: React.ReactNode = useMemo(() => {
		const status = item.status?.keyName ?? '';
		if (item.checklistItemType === 'EmbedHtml' && item.inputValue && !item.isAbandoned && status !== 'done') {
			return (
				<Button
					type="primary"
					style={{ marginTop: '1rem' }}
					onClick={() => onAction('transition-to-completed')}
					loading={loadingAction === 'transition-to-completed'}
				>
					{item.actionText?.title ?? 'Mark as Done'}
				</Button>
			);
		}
		return null;
	}, [item]);

	const isNotMyRole = (item.inputPartyType === 'SizeUp') !== adminMode;
	if (item.verificationPartyType === null && item.inputValue && isNotMyRole) {
		return <StageItemPreview item={item} actions={previewActions} />;
	}

	const isVerticalLayout = ['embedhtml', 'longtext', 'email'].includes(model.type);

	return (
		<div>
			{!adminMode && item.status?.keyName === 'sizeup_todo' ? (
				<Paragraph>
					<Alert message="Awaiting action from the SizeUp team" type="info" />
				</Paragraph>
			) : null}
			{model.tooltip ? (
				<Paragraph>
					<div dangerouslySetInnerHTML={{ __html: model.tooltip }} />
				</Paragraph>
			) : null}
			{model.description ? (
				<Paragraph>
					<div dangerouslySetInnerHTML={{ __html: model.description }} />
				</Paragraph>
			) : null}
			<Form form={form} style={{ width: '100%' }} initialValues={{ [model.name]: model.value }}>
				<Space direction={isVerticalLayout ? 'vertical' : 'horizontal'} style={{ width: '100%' }}>
					<StageItemElement model={model} adminMode={adminMode} superEditMode={superEditMode} />
					<StageItemAction
						item={item}
						adminMode={adminMode}
						superEditMode={superEditMode}
						loadingAction={loadingAction}
						isPreviewOpen={showPreview}
						onAction={onAction}
						isFieldsTouched={form.isFieldsTouched()}
					/>
				</Space>
			</Form>
			{showPreview && <StageItemPreview item={item} actions={previewActions} />}
		</div>
	);
	// 	</List.Item>
	// );
};

const ItemTitle: React.FC<{
	item: ChecklistItemModel;
	adminMode: boolean;
	superEditMode: boolean;
}> = ({ item, adminMode, superEditMode }) => {
	return (
		<Space direction="horizontal" align="center">
			<Text strong style={{ minWidth: '200px', display: 'inline-block' }}>
				{item.title}
			</Text>
			<StageItemStatus item={item} adminMode={adminMode} superEditMode={superEditMode} />
			<StageItemContent item={item} adminMode={adminMode} />
			{adminMode && <Tag style={{ opacity: '.2' }}>{item.checklistItemType}</Tag>}
			{adminMode && <Tag style={{ opacity: '.2' }}>{item.inputPartyType}</Tag>}
		</Space>
	);
};

const ItemsCollapse: React.FC<ItemsCollapseProps> = ({
	data = [],
	adminMode = false,
	superEditMode = false,
	customerId,
	onUpdate,
}) => {
	const [activeKey, setActiveKey] = useState<string[]>([]);
	const [filterKey, setFilterKey] = useState<string>('all');

	const expandAll = useCallback(() => {
		setActiveKey(
			data
				.map((x) => x.id)
				.filter((x) => x !== undefined)
				.map((x) => x.toString()),
		);
	}, [data]);

	const collapseAll = useCallback(() => {
		setActiveKey([]);
	}, []);

	const filterKeys: CheckListItemStatusModel[] = useMemo(() => {
		return uniqueBy(
			data.map((item) => item.status).filter((x) => x !== undefined),
			(x) => x.keyName,
		);
	}, [data]);

	const items: CollapseProps['items'] = useMemo(
		() =>
			(data || [])
				.filter((item) => {
					if (filterKey === 'all') return true;
					return item.status?.keyName === filterKey;
				})
				.map((item) => ({
					key: item.id ? item.id.toString() : '',
					label: <ItemTitle item={item} adminMode={adminMode} superEditMode={superEditMode} />,
					children: (
						<ItemRow
							item={item}
							adminMode={adminMode}
							superEditMode={superEditMode}
							customerId={customerId}
							onUpdate={onUpdate}
						/>
					),
				})),
		[data, adminMode, customerId, superEditMode, filterKey],
	);

	const isAllCollapsed = activeKey.length === 0;
	const isAllExpanded = activeKey.length === data.length;

	return (
		<div>
			<Row style={{ width: '100%' }}>
				<Col xs={24} sm={12} md={12} lg={12} xl={12}>
					<Radio.Group
						size="middle"
						buttonStyle="solid"
						style={{ textTransform: 'uppercase', marginBottom: '1rem' }}
						defaultValue={'all'}
						onChange={(e) => setFilterKey(e.target.value)}
						value={filterKey}
					>
						<Radio.Button value="all" style={{ fontSize: '12px' }}>
							All ({data.length})
						</Radio.Button>
						{filterKeys.map((filterKey) => (
							<Radio.Button
								value={filterKey.keyName}
								key={filterKey.keyName}
								style={{ fontSize: '12px' }}
							>
								{filterKey.title} ({data.filter((x) => x.status?.keyName === filterKey.keyName).length})
							</Radio.Button>
						))}
					</Radio.Group>
				</Col>
				<Col xs={24} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'right' }}>
					<Space>
						<Button
							type="link"
							size="small"
							icon={<ArrowUpToLineIcon />}
							onClick={collapseAll}
							disabled={isAllCollapsed}
							style={{ fontSize: '14px' }}
						>
							Collapse All
						</Button>
						<Button
							type="link"
							size="small"
							icon={<ArrowDownToLine />}
							onClick={expandAll}
							disabled={isAllExpanded}
							style={{ fontSize: '14px' }}
						>
							Expand All
						</Button>
					</Space>
				</Col>
			</Row>
			{/* {isLocked ? (
				<Alert
					message="Please complete the previous stage before proceeding"
					type="warning"
					showIcon
					style={{ marginBottom: "1rem" }}
				/>
			) : null} */}
			<Collapse bordered={false} items={items} activeKey={activeKey} onChange={setActiveKey} />
		</div>
	);
};

export default ItemsCollapse;

import { Button, Tag, Typography } from 'antd';
import React, { useMemo } from 'react';

import { PencilLine } from 'lucide-react';
import Sortable from '../../components/Sortable';

const { Paragraph, Title } = Typography;

interface SettingsTableProps {
	data?: unknown[];
	loading: boolean;
	onOrderUpdate?(...args: unknown[]): unknown;
	onEdit(...args: unknown[]): unknown;
	roles: unknown[];
}

export default function SettingsTable({ data, loading, onOrderUpdate, onEdit }: SettingsTableProps) {
	const columns = useMemo(
		() => [
			{
				key: 'sort',
			},
			{
				title: 'Id',
				dataIndex: 'id',
				key: 'id',
				render: (text, record) => (
					<Paragraph>
						{text}
						<Button type="link" icon={<PencilLine size={16} />} onClick={() => onEdit(record)} />
					</Paragraph>
				),
			},
			{
				title: 'Group',
				dataIndex: 'groupTag',
				key: 'groupTag',
				render: (text) => (text ? <Tag color="orange">{text}</Tag> : null),
			},
			{
				title: 'KeyName & Description',
				dataIndex: 'keyName',
				key: 'keyName',
				render: (text, record) => (
					<div>
						<Title level={5} style={{ whiteSpace: 'nowrap' }}>
							{record.displayName}{' '}
							{record.jsonType === 'boolean' ? (
								<Tag color="green">boolean</Tag>
							) : (
								<Tag color="blue">
									{record.uiInputType}
									{record.isArray ? '[]' : ''}
								</Tag>
							)}
						</Title>
						<Paragraph
							copyable
							style={{
								fontSize: '90%',
								margin: 0,
								padding: 0,
								color: '#999',
								fontWeight: 400,
								fontFamily: 'monospace',
							}}
						>
							{text}
						</Paragraph>
						{record.description && (
							<Paragraph ellipsis={{ rows: 2, expandable: true }}>{record.description}</Paragraph>
						)}
					</div>
				),
			},
			{
				title: 'Role',
				dataIndex: 'parentRole',
				key: 'parentRole',
				render: (text) => (text ? <Tag color="blue">{text.name}</Tag> : null),
			},
			{
				title: 'Default Value',
				dataIndex: 'defaultValue',
				key: 'defaultValue',
				render: (text) => (text ? <Tag color="geekblue">{text}</Tag> : null),
			},
			{
				title: 'Options',
				dataIndex: 'allowedOptions',
				key: 'allowedOptions',
				render: (text) =>
					text
						? text.map((item) => (
								<Tag color="orange" key={item}>
									{item}
								</Tag>
							))
						: null,
			},
		],
		[onEdit],
	);

	return (
		<div>
			<Sortable
				columns={columns}
				dataSource={data}
				loading={loading}
				rowKey="id"
				pagination={false}
				onOrderUpdate={onOrderUpdate}
			/>
		</div>
	);
}

import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useAuth } from 'react-oidc-context';

import DataService from '../../data.service';
import TranslationsTable from './Table';

interface Word {
	key: string;
	en: string;
	es: string;
}

const flatten = (data, prefix = '') => {
	const result = {};

	for (const [key, value] of Object.entries(data)) {
		if (typeof value === 'object') {
			Object.assign(result, flatten(value, `${prefix}${key}.`));
		} else {
			result[`${prefix}${key}`] = value;
		}
	}

	return result;
};

export function Translations() {
	const auth = useAuth();

	const [data, setData] = useState<Word[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		setIsLoading(true);
		DataService.translations
			.getAll({ token: auth.user?.access_token })
			.then((data) => {
				const en = flatten(data.en);
				const es = flatten(data.es);
				const keys = Object.keys(en);
				setData(keys.map((key: string) => ({ key, en: en[key], es: es[key] })));
				setIsLoading(false);
			})
			.catch((error) => {
				setError(error);
				setIsLoading(false);
			});
	}, [auth.user?.access_token]);

	if (error === 401 || error === 403) {
		toast('Your session has expired. Please sign in again.');
		auth.removeUser();
	}

	return <TranslationsTable data={data} loading={isLoading} />;
}

export function startCase(str: string): string {
	if (!str) {
		console.warn('startCase: empty string', str);
		return str;
	}
	return str.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
}

export function countElementAppearances(arr: string[]): [string, number][] {
	const appearanceMap: { [key: string]: number } = {};

	for (const element of arr) {
		if (appearanceMap[element]) {
			appearanceMap[element] += 1;
		} else {
			appearanceMap[element] = 1;
		}
	}

	// Convert the object to an array of arrays
	const appearanceArray: [string, number][] = Object.entries(appearanceMap);

	return appearanceArray;
}

import React from 'react';
import { createRoot } from 'react-dom/client';
import { AuthProvider } from 'react-oidc-context';

import '@ant-design/v5-patch-for-react-19';

import {
	BrowserRouter,
	Route,
	Routes,
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router';

import * as Sentry from '@sentry/react';
import App from './App';

Sentry.init({
	dsn: import.meta.env.VITE_APP_SENTRY_DSN_URL,
	integrations: [
		Sentry.reactRouterV6BrowserTracingIntegration({
			useEffect: React.useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes,
		}),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, // Capture 50% of the transactions
});

const rootElement = document.getElementById('root');

const host = `${document.location.protocol}//${document.location.host}`;
const oidcConfiguration = {
	onSignIn: () => {
		console.info('✅ Signed In');
	},
	client_id: 'sizeup-clientsetuptool',
	redirect_uri: `${host}/callback.html`,
	scope: 'openid profile email api_keys',
	loadUserInfo: true,
	authority: 'https://auth.beta.sizeup.com',
	// automaticSilentRenew: true,
};

const root = createRoot(rootElement); // createRoot(container!) if you use TypeScript
root.render(
	<AuthProvider {...oidcConfiguration}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</AuthProvider>,
);

import { Alert, Button, Card, Col, Row, Space, Statistic, Tooltip } from 'antd';
import type dayjs from 'dayjs';
import { FileSpreadsheet, Info } from 'lucide-react';
import React, { useCallback, useMemo } from 'react';
import { startCase } from '../../utils';

import analyticsService from '../../service/analytics.service';
import { BarChart, LineChart, PieChart } from './AnalyticsCharts';
import { downloadHelper, exportHandler, processRegular, processTimeSeriesData } from './analytics-helpers';
import useAnalytics from './useAnalytics';

function AnalyticsLBI({
	apiKeys,
	startDate,
	endDate,
	token = '',
	isSingleLocationMode = false,
}: {
	apiKeys: string[];
	startDate: dayjs.Dayjs;
	endDate: dayjs.Dayjs;
	token?: string;
	isSingleLocationMode?: boolean;
}) {
	const [visits, visitsLoading, visitsError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getVisits,
		token,
		(response) => {
			return processTimeSeriesData(response, startDate, endDate);
		},
	);

	const totalVisits = useMemo(() => visits.reduce((acc, x) => acc + x.value, 0), [visits]);

	const [calls, callsLoading, callsError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getAPICalls,
		token,
		(response) => {
			return processTimeSeriesData(response, startDate, endDate);
		},
	);

	const totalCalls = useMemo(() => calls.reduce((acc, x) => acc + x.value, 0), [calls]);

	const [lbiLoads, lbiLoadsLoading, lbiLoadsError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getInsightsPageViews,
		token,
		(response) => {
			return [
				{ label: 'Performance Benchmark', value: response.find((x) => x.id === 'my-business')?.value ?? 0 },
				{
					label: 'Customer Discovery and Supply Chain',
					value: response.find((x) => x.id === 'competition')?.value ?? 0,
				},
				{ label: 'Target Advertising', value: response.find((x) => x.id === 'advertising')?.value ?? 0 },
				{ label: 'Demographic Analysis', value: response.find((x) => x.id === 'demographics')?.value ?? 0 },
			];
		},
	);

	// const [hits, hitsLoading, hitsError] = useAnalytics(apiKeys, startDate, endDate, analyticsService.getReportHits, token, (response) =>
	// 	Object.entries(response || {}).map(([key, value]) => ({
	// 		label: startCase(key),
	// 		fullLabel: startCase(key),
	// 		value,
	// 	})),
	// );

	// const [premiumHits, premiumHitsLoading, premiumHitsError] = useAnalytics(apiKeys, startDate, endDate, analyticsService.getPremiumReportHits, token, (response) =>
	// 	Object.entries(response || {}).map(([key, value]) => ({
	// 		label: startCase(key),
	// 		fullLabel: startCase(key),
	// 		value,
	// 	})),
	// );

	const [reportHits, reportHitsLoading, reportHitsError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getInsightsReportHits,
		token,
		(response) =>
			Object.entries(response || {}).map(([key, value]) => ({
				label: startCase(key),
				fullLabel: startCase(key),
				value,
			})),
	);

	const [occupations, occupationsLoading, occupationsError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getOccupations,
		token,
		(response) => processRegular(response, (x) => x.id),
	);

	const topOccupations = useMemo(() => occupations.slice(0, 6), [occupations]);

	const [competition, competitionLoading, competitionError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getCompetition,
		token,
		(response) => processRegular(response, (x) => x.id),
	);
	const [advertising, advertisingLoading, advertisingError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getAdvertising,
		token,
		(response) => processRegular(response, (x) => x.id),
	);

	const [demographics, demographicsLoading, demographicsError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getDemographics,
		token,
		(response) => processRegular(response, (x) => x.id),
	);

	const [locations, locationsLoading, locationsError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getLocations,
		token,
		(response) => processRegular(response, (x) => x.displayName),
	);
	const [industries, industriesLoading, industriesError] = useAnalytics(
		apiKeys,
		startDate,
		endDate,
		analyticsService.getIndustries,
		token,
		(response) => processRegular(response, (x) => x.displayName),
	);

	const download = useCallback(
		(data, name) => {
			downloadHelper(name, startDate, endDate, data);
		},
		[startDate, endDate],
	);

	const onIndustriesDownload = useCallback(() => {
		exportHandler('insights_industries', 'export/industries', startDate, endDate, apiKeys, token);
	}, [apiKeys, startDate, endDate, token]);

	const onLocationsDownload = useCallback(() => {
		exportHandler('insights_locations', 'export/locations', startDate, endDate, apiKeys, token);
	}, [apiKeys, startDate, endDate, token]);

	if (
		visitsError ||
		callsError ||
		lbiLoadsError ||
		reportHitsError ||
		competitionError ||
		advertisingError ||
		demographicsError ||
		locationsError ||
		industriesError ||
		occupationsError
	) {
		console.log(
			'Error loading data',
			visitsError,
			callsError,
			reportHitsError,
			competitionError,
			advertisingError,
			demographicsError,
			locationsError,
			industriesError,
			occupationsError,
		);
		return <Alert message="Error loading data" type="error" />;
	}

	return (
		<Row gutter={[16, 16]}>
			<Col xs={24} sm={24} md={12} lg={12} xl={12}>
				<Card
					title={
						<Space>
							User Visits {totalVisits ? `(${totalVisits.toLocaleString()})` : ''}
							<Tooltip title="This reflects the number of times the widget loaded into a browser">
								<Info size={12} />
							</Tooltip>
						</Space>
					}
					extra={
						<Button icon={<FileSpreadsheet size={16} />} onClick={() => download(visits, 'User Visits')}>
							CSV
						</Button>
					}
					variant="borderless"
				>
					<LineChart data={visits as any} color="#ed6d85" loading={visitsLoading as boolean} />
				</Card>
			</Col>
			<Col xs={24} sm={24} md={12} lg={12} xl={12}>
				<Card
					title={
						<Space>
							Insights Reports and Sub-Reports
							{totalCalls ? `(${totalCalls.toLocaleString()})` : ''}
							<Tooltip title="This number reflects how many pieces of information was requested from the server based on inputs. For example if information on the My Business Module was requested then it would return approx. 14 calls which is what is needed to answer each of the KPIs. This is an indication of how deep users are going into the software">
								<Info size={12} />
							</Tooltip>
						</Space>
					}
					extra={
						<Button
							icon={<FileSpreadsheet size={16} />}
							onClick={() => download(calls, 'Insights Reports and Sub-Reports')}
						>
							CSV
						</Button>
					}
					variant="borderless"
				>
					<LineChart
						data={calls as any}
						// title="API Calls"
						color="#63c9ca"
						loading={callsLoading as boolean}
					/>
				</Card>
			</Col>

			<Col xs={24} sm={24} md={24} lg={24} xl={24}>
				<Card
					title={
						<Space>
							Top Report Types
							<Tooltip title="This reflects the count for each of tools when they are opened">
								<Info size={12} />
							</Tooltip>
						</Space>
					}
					extra={
						<Button
							icon={<FileSpreadsheet size={16} />}
							onClick={() => download(lbiLoads, 'Top Report Types')}
						>
							CSV
						</Button>
					}
					variant="borderless"
				>
					<Row gutter={[16, 16]}>
						{lbiLoads.map((item, index) => (
							<Col xs={24} sm={24} md={12} lg={6} xl={6} key={item.value}>
								<Statistic
									title={item.label}
									value={item.value || 0}
									valueStyle={{ color: '#3f8600' }}
									loading={lbiLoadsLoading}
								/>
							</Col>
						))}
					</Row>
				</Card>
			</Col>
			<Col xs={24} sm={24} md={24} lg={24} xl={isSingleLocationMode ? 24 : 16}>
				<Card
					title={
						<Space>
							Top Benchmarks
							<Tooltip title="This # of views reflects the number of inputs made on individual Key Performance Indicators (KPIs) located in the My Business Module. This does not reflect the number of views as the KPI for an industry can be viewed without an input">
								<Info size={12} />
							</Tooltip>
						</Space>
					}
					extra={
						<Button
							icon={<FileSpreadsheet size={16} />}
							onClick={() => download(reportHits, 'Top Benchmarks')}
						>
							CSV
						</Button>
					}
					variant="borderless"
				>
					<BarChart
						data={reportHits as any}
						title="# of hits"
						color="#3b5487"
						loading={reportHitsLoading as boolean}
					/>
				</Card>
			</Col>
			<Col xs={24} sm={24} md={8} lg={8} xl={8}>
				<Card
					title={
						<Space>
							Competition Modules
							<Tooltip title="This reflects the number of sub-tab(s) that were clicked on in the competition module. These include Competitors, Customers, and Suppliers. This does not reflect if a specific business was clicked on for more information">
								<Info size={12} />
							</Tooltip>
						</Space>
					}
					extra={
						<Button
							icon={<FileSpreadsheet size={16} />}
							onClick={() => download(competition, 'Competition Modules')}
						>
							CSV
						</Button>
					}
					variant="borderless"
				>
					<BarChart
						data={competition as any}
						title="# of views"
						color="#5d768a"
						loading={competitionLoading as boolean}
					/>
				</Card>
			</Col>
			<Col xs={24} sm={24} md={8} lg={8} xl={8}>
				<Card
					title={
						<Space>
							Advertising Modules
							<Tooltip title="This reflects the number of categories that were clicked on in the advertising module.">
								<Info size={12} />
							</Tooltip>
						</Space>
					}
					extra={
						<Button
							icon={<FileSpreadsheet size={16} />}
							onClick={() => download(advertising, 'Advertising Modules')}
						>
							CSV
						</Button>
					}
					variant="borderless"
				>
					<BarChart
						data={advertising as any}
						title="# of views"
						color="#5d768a"
						loading={advertisingLoading as boolean}
					/>
				</Card>
			</Col>
			<Col xs={24} sm={24} md={8} lg={8} xl={8}>
				<Card
					title={
						<Space>
							Demographics Modules
							<Tooltip title="This reflects the number of sub-tab(s) that were clicked on in the Demographics module. These include Place, Boundary and around an address. This does not reflect changes between search type (demographics, labor force, or consumer expenditures) or if certain criteria were toggled on/off">
								<Info size={12} />
							</Tooltip>
						</Space>
					}
					extra={
						<Button
							icon={<FileSpreadsheet size={16} />}
							onClick={() => download(demographics, 'Demographics Modules')}
						>
							CSV
						</Button>
					}
					variant="borderless"
				>
					<BarChart
						data={demographics as any}
						title="# of views"
						color="#5d768a"
						loading={demographicsLoading as boolean}
					/>
				</Card>
			</Col>
			<Col
				xs={24}
				sm={24}
				md={isSingleLocationMode ? 12 : 8}
				lg={isSingleLocationMode ? 12 : 8}
				xl={isSingleLocationMode ? 12 : 8}
			>
				<Card
					title={
						<Space>
							Top Occupations
							<Tooltip title="This reflects the number of sub-tab(s) that were clicked on in the occupation module. This does not reflect if a specific occupation was clicked on for more information">
								<Info size={12} />
							</Tooltip>
						</Space>
					}
					extra={
						<Button
							icon={<FileSpreadsheet size={16} />}
							onClick={() => download(occupations, 'occupations Modules')}
						>
							CSV
						</Button>
					}
					variant="borderless"
				>
					<BarChart
						data={topOccupations as any}
						title="# of views"
						color="#5a80ba"
						loading={occupationsLoading as boolean}
					/>
				</Card>
			</Col>
			<Col xs={24} sm={24} md={12} lg={12} xl={12}>
				<Card
					title={
						<Space>
							Top Industries
							<Tooltip title="The # of views reflects a user&#x27;s industry of interest while navigating the different modules. There may be more than the 10 industries reflected and they can be downloaded using the csv button  (with the portal)">
								<Info size={12} />
							</Tooltip>
						</Space>
					}
					extra={
						<Button icon={<FileSpreadsheet size={16} />} target="_blank" onClick={onIndustriesDownload}>
							CSV
						</Button>
					}
					variant="borderless"
				>
					<BarChart
						data={industries as any}
						title="# of views"
						color="#e8af48"
						loading={industriesLoading as boolean}
					/>
				</Card>
			</Col>
			{isSingleLocationMode ? null : (
				<Col xs={24} sm={24} md={12} lg={12} xl={12}>
					<Card
						title={
							<Space>
								Top Locations
								<Tooltip title="The # of views reflects a user&#x27;s geography of interest while navigating the different modules.  There may be more than the 10 geographies reflected and they can be downloaded using the csv button (with the portal)">
									<Info size={12} />
								</Tooltip>
							</Space>
						}
						extra={
							<Button icon={<FileSpreadsheet size={16} />} target="_blank" onClick={onLocationsDownload}>
								CSV
							</Button>
						}
						variant="borderless"
					>
						<BarChart
							data={locations as any}
							title="# of views"
							color="#448fa4"
							loading={locationsLoading as boolean}
						/>
					</Card>
				</Col>
			)}
		</Row>
	);
}

export default AnalyticsLBI;

import { Button, Popconfirm, Spin, Tag, Typography } from 'antd';
import { PencilLine, Trash } from 'lucide-react';
import prettyBytes from 'pretty-bytes';
import React, { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import SVG from 'react-inlinesvg';
import { useAuth } from 'react-oidc-context';

import type { IconResourceModel, PageModel, PartModel, TagModel, TopicModel } from '../../api';
import Sortable from '../../components/Sortable';
import DataService, { type TopicsModelSimplified } from '../../data.service';
import TopicCreate from './Topics-Create';
import TopicEdit from './Topics-Edit';

const { Paragraph } = Typography;

interface TopicsTableProps {
	data?: unknown[];
	onEdit(...args: unknown[]): unknown;
	onRemove(...args: unknown[]): unknown;
	onOrderUpdate?(...args: unknown[]): unknown;
	loading: boolean;
	icons: IconResourceModel[];
	parts: PartModel[];
	tags: TagModel[];
	rowKey: string;
}

function TopicsTable({ data, rowKey, onEdit, onRemove, onOrderUpdate, loading, icons, parts, tags }: TopicsTableProps) {
	const columns = useMemo(
		() => [
			{
				key: 'sort',
			},
			{
				title: 'Id',
				dataIndex: 'id',
				key: 'id',
			},
			{
				title: 'Icon',
				dataIndex: 'iconId',
				key: 'iconId',
				render: (text, record) => {
					const icon = icons.find((i) => i.id === record.iconId);
					if (icon?.svgContent) {
						return <SVG src={icon.svgContent} style={{ width: '40px', height: '40px' }} />;
					}
					if (icons?.length) {
						return null;
					}
					return <Spin />;
				},
			},
			{
				title: 'Title',
				dataIndex: 'title',
				key: 'title',
				render: (text, record) => (
					<div>
						{text}
						{record.hasProduct ? ' ★' : ''}
					</div>
				),
			},
			{
				title: 'Description Size',
				dataIndex: 'description',
				key: 'description',
				render: (text) => <Paragraph>{prettyBytes(text.length)}</Paragraph>,
			},
			{
				title: 'Part',
				dataIndex: 'partId',
				key: 'partId',
				render: (text, record) => {
					const part = parts.find((i) => i.id === record.partId);
					return part ? <Tag color="lime">{part.name}</Tag> : parts?.length ? null : <Spin />;
				},
			},
			{
				title: 'Pages',
				dataIndex: 'pages',
				key: 'pages',
				render: (text, record) => <Tag color="cyan">{record.pages?.length || 0}</Tag>,
			},
			{
				title: 'Tags',
				dataIndex: 'tags',
				key: 'tags',
				render: (text, record) => (
					<React.Fragment>
						{tags
							.filter((tag) => record.tags.includes(tag.id))
							.map((tag) => (
								<Tag color="volcano" key={tag.id}>
									{tag.name}
								</Tag>
							))}
					</React.Fragment>
				),
				// return <Tag color="volcano">{record.tags?.length || 0}</Tag>;
			},
			{
				title: 'Actions',
				dataIndex: 'id',
				key: 'actions',
				render: (text, record) => (
					<div>
						<Button onClick={() => onEdit(record)} icon={<PencilLine size={16} />} type="link" />
						<Popconfirm title="Sure to delete?" onConfirm={() => onRemove(record)}>
							<Button icon={<Trash size={16} />} type="link" danger />
						</Popconfirm>
					</div>
				),
			},
		],
		[parts, icons, tags, onEdit, onRemove],
	);

	return (
		<div>
			<Sortable
				columns={columns}
				dataSource={data}
				loading={loading}
				rowKey={rowKey}
				onOrderUpdate={onOrderUpdate}
				pagination={false}
			/>
		</div>
	);
}

interface TopicsProps {
	createMode: boolean;
	setCreateMode(...args: unknown[]): unknown;
	icons: IconResourceModel[];
	data?: TopicsModelSimplified[];
	setData: React.Dispatch<React.SetStateAction<TopicsModelSimplified[]>>;
	isLoading: boolean;
	setIsLoading(...args: unknown[]): unknown;
	parts: PartModel[];
	pages: PageModel[];
	tags: TagModel[];
}

// import tempbase from './tempbase.json';

export function Topics({
	data,
	setData,
	isLoading,
	setIsLoading,
	createMode,
	setCreateMode,
	icons,
	parts,
	pages,
	tags,
}: TopicsProps) {
	const auth = useAuth();

	const [editForm, setEditForm] = useState(false);

	const [selectedRow, setSelectedRow] = useState<TopicModel | null>(null);

	const onCreate = (values) => {
		console.info('Create Topic', values);
		const toastId = toast.loading('Creating Topic...');
		DataService.entrepreneur.topics
			.add(
				{
					...values,
					pages: values.pages.map((id) => ({ id })),
					tags: values.tags.map((id) => (Number.isNaN(id) ? { name: id } : { id })),
				},
				{
					token: auth.user?.access_token,
				},
			)
			.then((item) => {
				setIsLoading(false);
				setData((data) => [...data, item]);
				toast.success('Topic Created', { id: toastId });
			})
			.catch(() => {
				toast.error('Error Creating Topic', { id: toastId });
			});
		setCreateMode(false);
	};

	const onUpdate = (values) => {
		console.info('Update Topic', values);
		const toastId = toast.loading('Updating Topic...');
		DataService.entrepreneur.topics
			.update(
				{
					...values,
					pages: values.pages.map((id) => ({ id })),
					tags: values.tags.map((id) => (Number.isNaN(id) ? { name: id } : { id })),
				},
				{
					token: auth.user?.access_token,
				},
			)
			.then((item) => {
				setIsLoading(false);
				setData((data) => {
					const index = data.findIndex((x) => x.id === item.id);
					data[index] = { ...item };
					return [...data];
				});
				toast.success('Topic Updated', { id: toastId });
			})
			.catch(() => {
				toast.error('Error Updating Topic', { id: toastId });
			});
		setEditForm(false);
		setSelectedRow(null);
	};

	const onRemove = ({ id }) => {
		const toastId = toast.loading('Removing Topic...');
		DataService.entrepreneur.topics
			.remove(id, {
				token: auth.user?.access_token,
			})
			.then(() => {
				setData((data) => {
					const index = data.findIndex((x) => x.id === id);
					data.splice(index, 1);
					return [...data];
				});
				toast.success('Topic Removed', { id: toastId });
			})
			.catch((err) => {
				toast.error(err?.message, { id: toastId });
			});

		setCreateMode(false);
	};

	const onOrderChange = (newOrder) => {
		console.log('order change', newOrder);
		setIsLoading(true);
		DataService.entrepreneur.topics
			.updateOrder(newOrder, {
				token: auth.user?.access_token,
			})
			.then(() => {
				setIsLoading(false);
			});
	};

	return (
		<div>
			<TopicCreate
				open={createMode}
				onCreate={onCreate}
				onCancel={() => {
					setCreateMode(false);
				}}
				icons={icons}
				parts={parts}
				pages={pages}
				tags={tags}
			/>

			{selectedRow ? (
				<TopicEdit
					open={editForm}
					onUpdate={onUpdate}
					onCancel={() => {
						setEditForm(false);
						setSelectedRow(null);
					}}
					data={selectedRow}
					icons={icons}
					parts={parts}
					pages={pages}
					tags={tags}
				/>
			) : null}

			<TopicsTable
				data={data}
				onEdit={(row: TopicModel) => {
					setEditForm(true);
					setSelectedRow(row);
				}}
				onRemove={onRemove}
				loading={isLoading}
				icons={icons}
				parts={parts}
				tags={tags}
				onOrderUpdate={onOrderChange}
				rowKey="id"
			/>
		</div>
	);
}

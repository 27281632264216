import { Button, Col, Row, Space, Typography } from 'antd';
import { useAuth } from 'react-oidc-context';

import SmartSetting from './SmartSetting';

import { ExternalLink, Eye } from 'lucide-react';
import type { CustomerSettingsModelPlus } from '../../types/CustomerSettingsModelPlus';

const { Text } = Typography;

function getCode(apiKey) {
	return `<script src="https://demographics.sizeup.com/bundle.js" data-key="${apiKey}"></script>`;
}

export default function Demographics({
	data,
	isAdmin = false,
}: { data: CustomerSettingsModelPlus; isAdmin?: boolean }) {
	const auth = useAuth();

	return (
		<div>
			{isAdmin ? (
				<Row gutter={[8, 0]} wrap={false}>
					<Col flex="auto">
						<Text copyable={{ text: getCode(data.apiKey) }}>Integration Code: </Text>
					</Col>
					<Col>
						{data.apiKey ? (
							<Space.Compact>
								<Button
									type="link"
									icon={<Eye size={16} />}
									onClick={() =>
										window.Sizeup.initPopupWidget({
											product: 'demographics',
											key: data.apiKey || '',
										})
									}
								>
									Inline Preview
								</Button>
								<Button
									type="link"
									icon={<ExternalLink size={16} />}
									onClick={() =>
										window.open(
											`https://${data.apiKey}.instance.sizeup.com/demographics.html`,
											'_blank',
										)
									}
								>
									Hosted Instance
								</Button>
								<Button
									type="link"
									icon={<ExternalLink size={16} />}
									onClick={() =>
										window.open(
											`https://demographics.sizeup.com/preview.html?apiKey=${data.apiKey}`,
											'_blank',
										)
									}
								>
									External Preview
								</Button>
								<Button
									type="link"
									icon={<ExternalLink size={16} />}
									onClick={() =>
										window.open(
											`https://demographics-beta.sizeup.com/preview.html?apiKey=${data.apiKey}`,
											'_blank',
										)
									}
								>
									(Beta)
								</Button>
							</Space.Compact>
						) : null}
					</Col>
				</Row>
			) : null}
			{isAdmin ? (
				<pre style={{ background: 'white', padding: '1rem', fontSize: 'smaller' }}>{getCode(data.apiKey)}</pre>
			) : null}
			{data.settings?.map((setting, index) =>
				setting.groupTag === 'Demographics UI' ? (
					<SmartSetting setting={setting} index={index} key={setting.keyName} />
				) : null,
			)}
		</div>
	);
}

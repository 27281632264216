import {
	Checkbox,
	Col,
	Collapse,
	type CollapseProps,
	DatePicker,
	Flex,
	type GetProp,
	Row,
	Select,
	type SelectProps,
	type TimeRangePickerProps,
	Typography,
} from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import type { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { useAuth } from 'react-oidc-context';
import { useSearchParams } from 'react-router';
import AnalyticsDemographics from './AnalyticsDemographics';
import AnalyticsDevices from './AnalyticsDevices';
import AnalyticsLBI from './AnalyticsLBI';
import AnalyticsPulse from './AnalyticsPulse';
import AnalyticsSBA from './AnalyticsSBA';
import AnalyticsSurveys from './AnalyticsSurveys';

import type { Customer, CustomerFlatSettingsModel, CustomerSettingsModel } from '../../api';
import customersService from '../../service/customers.service';
import AnalyticsAuth from './AnalyticsAuth';
import AnalyticsCalculator from './AnalyticsCalculator';
import AnalyticsShopLocal from './AnalyticsShopLocal';

const { Title, Text } = Typography;

type CheckboxValueType = GetProp<typeof Checkbox.Group, 'value'>[number];
const CheckboxGroup = Checkbox.Group;

const { RangePicker } = DatePicker;

dayjs.extend(quarterOfYear);

const disabledDate: RangePickerProps['disabledDate'] = (current) =>
	// allow dates from 1/1/202 till today
	current && (current > dayjs().endOf('day') || current < dayjs('2022-01-01').startOf('day'));

const qDates = [
	// prev quearter
	dayjs().subtract(4, 'quarter'),
	dayjs().subtract(3, 'quarter'),
	dayjs().subtract(2, 'quarter'),
	dayjs().subtract(1, 'quarter'),
];

const rangePresets: TimeRangePickerProps['presets'] = [
	{ label: '1 Year', value: [dayjs().add(-1, 'y'), dayjs()] },
	{ label: 'Last 30 days', value: [dayjs().add(-30, 'd'), dayjs()] },
	{ label: 'This Week', value: [dayjs().startOf('week'), dayjs()] },
	{
		label: 'Last Week',
		value: [dayjs().add(-1, 'week').startOf('week'), dayjs().add(-1, 'week').endOf('week')],
	},
	{ label: 'This Month', value: [dayjs().startOf('month'), dayjs()] },
	{
		label: 'Last Month',
		value: [dayjs().add(-1, 'month').startOf('month'), dayjs().add(-1, 'month').endOf('month')],
	},
	{
		label: `Q${qDates[0].quarter()} ${qDates[0].year()}`,
		value: [qDates[0].startOf('quarter'), qDates[0].endOf('quarter')],
	},
	{
		label: `Q${qDates[1].quarter()} ${qDates[1].year()}`,
		value: [qDates[1].startOf('quarter'), qDates[1].endOf('quarter')],
	},
	{
		label: `Q${qDates[2].quarter()} ${qDates[2].year()}`,
		value: [qDates[2].startOf('quarter'), qDates[2].endOf('quarter')],
	},
	{
		label: `Q${qDates[3].quarter()} ${qDates[3].year()}`,
		value: [qDates[3].startOf('quarter'), qDates[3].endOf('quarter')],
	},
	{ label: 'This Year', value: [dayjs().startOf('year'), dayjs()] },
];

export default function Analytics({
	customer,
	withFilter = false,
}: { customer?: CustomerSettingsModel; withFilter?: boolean }) {
	const auth = useAuth();
	const token = auth.user?.access_token;

	const [searchParams, setSearchParams] = useSearchParams();
	const [activeKey, setActiveKey] = useState<string[]>(['insights']);

	const customerIdsParam = searchParams.get('customerIds');
	const startDateParam = searchParams.get('startDate');
	const endDateParam = searchParams.get('endDate');

	const isAdmin = useMemo(() => auth.user?.profile?.role === 'sizeup_admin', [auth.user]);

	const customerIds = customerIdsParam ? customerIdsParam.split('-').map((x) => Number.parseInt(x, 10)) : [];

	const [tempIds, setTempIds] = React.useState<number[]>(customerIds);
	const [apiKeys, setApiKeys] = React.useState<string[]>([]);

	const [open, setOpen] = useState(false);
	const [customersDB, setCustomersDB] = useState<CustomerFlatSettingsModel[]>([]);

	const [options, setOptions] = React.useState<SelectProps['options']>([]);
	const [isSingleInstance, setIsSingleInstance] = React.useState<boolean>(false);
	const [loadingOptions, setLoadingOptions] = React.useState<boolean>(false);

	const [startDate, setStartDate] = React.useState<dayjs.Dayjs>(
		startDateParam ? dayjs(startDateParam) : dayjs().add(-1, 'y'),
	);
	const [endDate, setEndDate] = React.useState<dayjs.Dayjs>(endDateParam ? dayjs(endDateParam) : dayjs());

	// keep tempIds in URL
	useEffect(() => {
		if (!withFilter) {
			return;
		}
		setSearchParams(
			(params: URLSearchParams) => {
				if (tempIds.length === 0) {
					params.delete('customerIds');
				} else {
					params.set('customerIds', tempIds.join('-'));
				}
				if (startDate) {
					params.set('startDate', startDate.format('YYYY-MM-DD'));
				}
				if (endDate) {
					params.set('endDate', endDate.format('YYYY-MM-DD'));
				}
				return params;
			},
			{ replace: true },
		);
	}, [startDate, endDate, tempIds, setSearchParams, withFilter]);

	useEffect(() => {
		if (customer?.id && customer?.apiKey) {
			setTempIds([customer.id]);
			setApiKeys([customer.apiKey]);
		}
	}, [customer]);

	useEffect(() => {
		if (withFilter) {
			setLoadingOptions(true);
			customersService
				.getAll({ token })
				.then((response) => {
					setCustomersDB(response);
					if (tempIds && tempIds.length > 0) {
						const apiKeys = tempIds.map((id) => response.find((x) => x.id === id)?.apiKey || '');
						setApiKeys(apiKeys);
					}
					setOptions(
						response
							.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))
							.map((x) => ({
								label: x.name,
								value: x.id,
							})),
					);
					setIsSingleInstance(response.length === 1);
					setLoadingOptions(false);
				})
				.catch((error) => {
					console.error('Error fetching customer list', error);
					setLoadingOptions(false);
				});
		}
	}, [withFilter, token]);

	const onBlur = useCallback(() => {
		const apiKeys = tempIds.map((id) => customersDB.find((x) => x.id === id)?.apiKey || '');
		setApiKeys(apiKeys);
	}, [tempIds, customersDB]);

	const handleApiKeysChange = (value: number[]) => {
		setTempIds(value);
		if (!open) {
			const apiKeys = value.map((id) => customersDB.find((x) => x.id === id)?.apiKey || '');
			setApiKeys(apiKeys);
		}
	};

	useEffect(() => {
		// trigger resize
		window.dispatchEvent(new Event('resize'));
	}, [activeKey]);

	const items: CollapseProps['items'] = useMemo(() => {
		const activeCustomers = customersDB.filter((x: CustomerFlatSettingsModel) => x.id && tempIds.includes(x.id));

		const activeItems: CollapseProps['items'] = [];
		const showAll = activeCustomers.length === 0;
		const isInsightsEnabled = activeCustomers.some((x) => x.roles?.Widget);
		const isSBAEnabled = activeCustomers.some((x) => x.roles?.Entrepreneur);
		const isShopLocalEnabled = activeCustomers.some((x) => x.roles?.ShopLocal);
		const isPulseEnabled = activeCustomers.some((x) => x.roles?.LIPD);
		const isDemographicsEnabled = activeCustomers.some((x) => x.roles?.DemographicsProduct);
		const isSurveysEnabled = activeCustomers.some((x) => x.roles?.Surveys);
		const isSingleLocationMode = !showAll && activeCustomers.every((x) => x.roles?.SingleLocationMode);

		if (isAdmin) {
			activeItems.push({
				key: 'calculator',
				label: <Text strong>ROI Calculator</Text>,
				children: (
					<AnalyticsCalculator apiKeys={apiKeys} startDate={startDate} endDate={endDate} token={token} />
				),
			});
		}

		if (showAll || isInsightsEnabled) {
			activeItems.push({
				key: 'insights',
				label: <Text strong>Insights</Text>,
				children: (
					<AnalyticsLBI
						apiKeys={apiKeys}
						startDate={startDate}
						endDate={endDate}
						token={token}
						isSingleLocationMode={isSingleLocationMode}
					/>
				),
			});
		}

		if (showAll || isSBAEnabled) {
			activeItems.push({
				key: 'sbadvisor',
				label: <Text strong>Small Business Advisor</Text>,
				children: (
					<AnalyticsSBA
						apiKeys={apiKeys}
						startDate={startDate}
						endDate={endDate}
						token={token}
						isSingleLocationMode={isSingleLocationMode}
					/>
				),
			});
		}

		if (showAll || isShopLocalEnabled) {
			activeItems.push({
				key: 'shoplocal',
				label: <Text strong>Shop Local</Text>,
				children: (
					<AnalyticsShopLocal
						apiKeys={apiKeys}
						startDate={startDate}
						endDate={endDate}
						token={token}
						isSingleLocationMode={isSingleLocationMode}
					/>
				),
			});
		}

		if (showAll || isPulseEnabled) {
			activeItems.push({
				key: 'pulse',
				label: <Text strong>Pulse</Text>,
				children: (
					<AnalyticsPulse
						apiKeys={apiKeys}
						startDate={startDate}
						endDate={endDate}
						token={token}
						isSingleLocationMode={isSingleLocationMode}
					/>
				),
			});
		}

		if (showAll || isDemographicsEnabled) {
			activeItems.push({
				key: 'demographics',
				label: <Text strong>Demographics</Text>,
				children: (
					<AnalyticsDemographics apiKeys={apiKeys} startDate={startDate} endDate={endDate} token={token} />
				),
			});
		}

		if (showAll || isSurveysEnabled) {
			activeItems.push({
				key: 'surveys',
				label: <Text strong>Surveys</Text>,
				children: <AnalyticsSurveys apiKeys={apiKeys} startDate={startDate} endDate={endDate} token={token} />,
			});
		}

		activeItems.push({
			key: 'devices',
			label: <Text strong>Device and Language</Text>,
			children: <AnalyticsDevices apiKeys={apiKeys} startDate={startDate} endDate={endDate} token={token} />,
		});

		activeItems.push({
			key: 'auth',
			label: <Text strong>Auth</Text>,
			children: <AnalyticsAuth apiKeys={apiKeys} startDate={startDate} endDate={endDate} token={token} />,
		});

		return activeItems;
	}, [customersDB, apiKeys, startDate, endDate, isAdmin, token]);

	return (
		<div>
			<Row gutter={[16, 16]} style={{ marginBottom: '1rem' }} justify="space-between">
				{withFilter ? (
					<Col xs={24} sm={24} md={12} lg={12} xl={12}>
						<Select
							loading={loadingOptions}
							disabled={isSingleInstance}
							mode="multiple"
							style={{ width: '100%' }}
							allowClear
							placeholder={
								loadingOptions
									? 'Loading...'
									: isSingleInstance
										? options?.[0].label
										: 'Filter instances'
							}
							defaultValue={[]}
							value={loadingOptions ? [] : tempIds}
							onChange={handleApiKeysChange}
							onBlur={onBlur}
							options={options}
							showSearch
							filterOption={(input, option) => {
								const label = option?.label || '';
								return label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
							}}
							onDropdownVisibleChange={(open) => {
								setOpen(open);
							}}
						/>
					</Col>
				) : null}
				<Col>
					<RangePicker
						disabledDate={disabledDate}
						presets={rangePresets}
						format="MM-DD-YYYY"
						allowEmpty={[false, true]}
						defaultValue={[startDate, endDate]}
						onChange={(values) => {
							if (values) {
								setStartDate(values[0] as dayjs.Dayjs);
								setEndDate(values[1] as dayjs.Dayjs);
							}
						}}
					/>
				</Col>
			</Row>
			<Collapse bordered={false} items={items} activeKey={activeKey} onChange={setActiveKey} />
		</div>
	);
}

import { Slider, Switch, Table, Typography } from 'antd';

import React, { useMemo } from 'react';

const { Paragraph } = Typography;

interface RoleTableProps {
	data: any[];
	onUpdate: (item: any) => void;
	onOrderUpdate: (item: any) => void;
	loading: boolean;
}

export default function RoleTable({ data, onUpdate, onOrderUpdate, loading }: RoleTableProps) {
	const columns = useMemo(
		() => [
			{
				title: 'Id',
				dataIndex: 'id',
				key: 'id',
			},
			{
				title: 'Name',
				dataIndex: 'name',
				key: 'name',
				render: (text, record) => (
					<React.Fragment>
						<Paragraph
							editable={{
								onChange: (newValue) => {
									record.displayName = newValue;
									onUpdate(record);
								},
							}}
						>
							{record.displayName}
						</Paragraph>
						<Paragraph
							copyable
							style={{
								fontSize: '90%',
								margin: 0,
								padding: 0,
								color: '#999',
								fontWeight: 400,
								fontFamily: 'monospace',
							}}
						>
							{record.name}
						</Paragraph>
					</React.Fragment>
				),
			},
			{
				title: 'Description',
				dataIndex: 'description',
				key: 'description',
				render: (text, record) => (
					<Paragraph
						editable={{
							onChange: (newValue) => {
								console.log('update description', newValue);
								record.description = newValue;
								onUpdate(record);
							},
						}}
					>
						{text}
					</Paragraph>
				),
			},
			{
				title: 'Default Value',
				dataIndex: 'defaultValue',
				key: 'defaultValue',
				render: (text, record) => (
					<Switch
						checked={record.defaultValue}
						onChange={(checked) => onUpdate({ ...record, defaultValue: checked })}
					/>
				),
			},
			{
				title: 'Usage Count',
				dataIndex: 'usageCount',
				key: 'usageCount',
			},
		],
		[onUpdate],
	);

	return (
		<div>
			<Table
				columns={columns}
				dataSource={data}
				loading={loading}
				rowKey="id"
				pagination={false}
				// onOrderUpdate={onOrderUpdate}
			/>
		</div>
	);
}
